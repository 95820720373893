import React from "react";
import {
  formatDateIntoDMYToDisplay,
  gethoursAndMins,
  getPlanManagedBy,
  textTruncate,
} from "Utils/utils";
import TableHeader from "components/common/Table/TableHeader";
import { DELIVERED_SERVICE_STATUS_OPTIONS } from "Constants/constant";

const ReportingTable = (props) => {
  const { caseNoteData } = props;

  return (
    <div className='bg-white mt-4 relative shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg overflow-x-auto'>
      <table className='w-full divide-y divide-gray-300 bg-white'>
        <thead className='bg-gray-50 capitalize'>
          <tr className=''>
            <TableHeader isSort={false} label={"Participant Name"} />
            <TableHeader isSort={false} label={"Delivery Serial No."} />
            <TableHeader isSort={false} label={"Support Item"} />
            <TableHeader isSort={false} label={"Created By"} />
            <TableHeader isSort={false} label={"Created Date"} />
            <TableHeader isSort={false} label={"Mgmt Style"} />
            <TableHeader isSort={false} label={"Delivered Date"} />
            <TableHeader isSort={false} label={"Hours"} />
            <TableHeader isSort={false} label={"Km"} />
            <TableHeader isSort={false} label={"Rate"} />
            <TableHeader isSort={false} label={"Total Delivered Amount"} />
            <TableHeader isSort={false} sortName='status' label={"Status"} />
            <TableHeader isSort={false} label={"Notes"} />
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-200 bg-white'>
          {caseNoteData &&
            caseNoteData.map((data, index) => (
              <tr
                key={index}
                className='even:bg-white odd:bg-gray-50 text-center'
              >
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate capitalize'>
                  {`${data.participant.first_name} ${data.participant.last_name}`}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                  {data.delivered_service_number}
                </td>
                <td
                  title={data?.support_item_name}
                  className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'
                >
                  {textTruncate(data?.support_item_name, 40, 39)}
                </td>
                <td
                  title={data?.created_by}
                  className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate capitalize'
                >
                  {textTruncate(data?.created_by, 30, 29)}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate capitalize'>
                  {formatDateIntoDMYToDisplay(data?.created_at)}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                  {getPlanManagedBy(data?.manged_by)}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                  {formatDateIntoDMYToDisplay(data?.delivered_date)}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                  {gethoursAndMins(data?.hours, data?.mints)}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                  {data?.km || 0}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                  {`$${data.original_rate}`}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate'>
                  {`$${data.total_amount}`}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate capitalize'>
                  {data?.status === null
                    ? DELIVERED_SERVICE_STATUS_OPTIONS.find((arr) => {
                        return data?.invoice_status === arr.value;
                      }).label
                    : DELIVERED_SERVICE_STATUS_OPTIONS.find((arr) => {
                        return data?.status === arr.value;
                      }).label}
                </td>
                <td
                  title={data?.notes}
                  className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate w-[20%]'
                >
                  {textTruncate(data?.notes, 40, 39)}
                </td>
              </tr>
            ))}
          {!caseNoteData ||
            (caseNoteData.length === 0 && (
              <tr>
                <td colSpan={10} className='py-6 text-center text-red-600'>
                  <div>No data found !</div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
export default ReportingTable;
