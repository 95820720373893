import React from "react";
import { PARENT_FORM_CATEGORY_SLUG, USER_ROLE } from "Constants/constant";
import { roleAccess } from "helpers/RolesPermission";
import { MdEmail, MdOutlineNotStarted } from "react-icons/md";
import { Link } from "react-router-dom";

const ActionsForSubmitPtForms = (props) => {
  const {
    view,
    slug,
    item,
    particpantUuid,
    role = "",
    setIsShowMultipleEmail,
    setSelectedFormForEmail,
    checkboxes,
  } = props;

  const permissionForSpStandardUser = () => {
    if (roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager])) return true;
    switch (slug) {
      case PARENT_FORM_CATEGORY_SLUG.HIGH_CARE.SLUG:
      case PARENT_FORM_CATEGORY_SLUG.MEDICATION.SLUG:
      case PARENT_FORM_CATEGORY_SLUG.INJURY_MANAGEMENT.SLUG:
        return true;
      default:
        return false;
    }
  };

  if (view === "table_header" && permissionForSpStandardUser()) {
    return (
      <th scope='col' className='relative py-3.5 pl-3 pr-4 text-center'>
        Action
      </th>
    );
  } else if (view === "table_data" && permissionForSpStandardUser()) {
    return (
      <td className='text-center'>
        {role !== "sil" && roleAccess([USER_ROLE.Sp_Admin, USER_ROLE.Sp_manager]) && (
          <div
            className={`${
              checkboxes?.length === 0
                ? "cursor-pointer"
                : "cursor-not-allowed opacity-50"
            } icon-stopPlay-wrapper inline-block mr-1`}
            title='Send this form via email'
            onClick={() => {
              if (checkboxes?.length === 0) {
                setIsShowMultipleEmail(true);
                setSelectedFormForEmail(item);
              }
            }}
          >
            <MdEmail className='icon-size' />
          </div>
        )}

        <Link
          to={
            role === "sil"
              ? `/view-template/sil/${particpantUuid}/${slug}/${item.slug}`
              : `/view-template/${particpantUuid}/${slug}/${item.slug}`
          }
        >
          <div
            className='cursor-pointer icon-stopPlay-wrapper inline-block'
            title='View details of this form'
          >
            <MdOutlineNotStarted className='icon-size' />
          </div>
        </Link>
      </td>
    );
  } else {
    return <></>;
  }
};

export default ActionsForSubmitPtForms;
