import React, { useEffect, useState } from "react";
import { ImBlocked } from "react-icons/im";
import {
  formOptions,
  LOADER_STATUS,
  SORT_TYPE,
} from "../../../Constants/constant";
import CircularLoader from "../../common/CircularLoader/CircularLoader";
import Search from "../../common/Search/Search";
import SelectOption from "../../common/Select/Select";
import TableHeader from "../../common/Table/TableHeader";
import FormsActionButtons from "./FormsActionButtons";
import { formatDateIntoDMYToDisplay, selectedTabState } from "Utils/utils";

const SubmittedFormViewTable = ({
  role,
  templateList,
  isLoading,
  queryParams,
  handleDownload,
  handleFetch,
  getSortBy,
  participantsUid,
  participantId,
  selectedPdfId,
  renderLoading,
  parentSlug,
  refreshFormList,
}) => {
  const [search, setSearch] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [sortBy, setSortBy] = useState(queryParams.sort_field);
  const [sortDescending, setSortDescending] = useState(
    queryParams.sort_order === SORT_TYPE.DESC ? true : false
  );

  useEffect(() => {
    getSortBy(sortBy);

    //to remove the selected tab that is selected while submiting the form 
    return ()=>{
      if(selectedTabState?.get()){
        selectedTabState?.save(null)
      }
    }
  }, [sortBy]);

  useEffect(() => {
    setSortBy(queryParams.sort_field ? queryParams.sort_field : null);
    if (queryParams.sort_order) {
      setSortDescending(
        queryParams.sort_order
          ? queryParams.sort_order === SORT_TYPE.DESC
            ? true
            : false
          : false
      );
    }
    if (queryParams.hasOwnProperty("filter")) {
      if (queryParams.filter !== null) {
        setSelectedStatus(
          formOptions.find((x) => x.id === queryParams.filter.is_complete[0])
        );
      }
    }
    if (queryParams.search) {
      setSearch(queryParams.search);
    }
  }, [queryParams]);

  useEffect(() => {
    if (
      ((queryParams.search === null || queryParams.search === undefined) &&
        !search) ||
      queryParams.search === search
    ) {
      return;
    }

    const timeOutId = setTimeout(
      () =>
        handleFetch(
          {
            search: search,
          },
          sortBy
        ),
      1000
    );
    return () => clearTimeout(timeOutId);
  }, [search]);

  const flipSort = (by) => {
    setSortBy(by);
    setSortDescending(sortDescending);
    handleFetch(
      {
        sort_field: by,
        sort_order: !sortDescending ? SORT_TYPE.DESC : SORT_TYPE.ASC,
      },
      by
    );
  };

  return (
    <div className='overflow-x-auto custom-scroll'>
      <div className='flex justify-between items-center'>
        <div className='w-1/3 md:w-3/12 lg:w-3/12 '>
          <SelectOption
            cssClass='z-[5]'
            isDisabled={isLoading || renderLoading !== LOADER_STATUS.COMPLATE}
            isSearch={false}
            selectedOption={selectedStatus}
            handleChange={(e) => {
              setSelectedStatus(e);
              handleFetch({ is_complete: e.id, page: 1 }, sortBy);
            }}
            data={formOptions}
            // placeHolder="Filter Status"
          />
        </div>
        <Search
          isDisabled={renderLoading !== LOADER_STATUS.COMPLATE}
          setSearch={setSearch}
          search={search}
        />
      </div>
      <div className='inline-block min-w-full py-4 align-middle'>
        <div className='overflow-hidden border md:rounded-lg'>
          <table className='min-w-full divide-y divide-gray-300'>
            <thead className='bg-gray-50  top-0  capitalize'>
              <tr>
                <TableHeader
                  sortName='form_name'
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  label={"Form Name"}
                />
                {templateList.some((item) =>
                  item.title.includes("Supervisory Meeting Record")
                ) && (
                  <TableHeader
                    isSort={false}
                    sortName='submitted_date'
                    flipSort={flipSort}
                    sortBy={sortBy}
                    sortDescending={sortDescending}
                    label={"Supervisory"}
                    cssClass='text-left'
                  />
                )}
                <TableHeader
                  isSort={false}
                  sortName='created_by'
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  label={"Created By"}
                />
                <TableHeader
                  isSort={false}
                  sortName='submitted_date'
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  label={"Submitted Date"}
                  cssClass='text-left'
                />
                <TableHeader isSort={false} label={"Last Updated By"} />
                <TableHeader
                  isSort={false}
                  label={"Last Updated date"}
                  cssClass='text-left'
                />
                <TableHeader
                  isSort={false}
                  sortName='expiry_date'
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  label={"Expiry Date"}
                />
                <TableHeader
                  isSort={false}
                  sortName='version'
                  flipSort={flipSort}
                  sortBy={sortBy}
                  sortDescending={sortDescending}
                  label={"Version"}
                />
                <th
                  scope='col'
                  className='relative py-3.5 pl-3 pr-4 text-sm font-semibold text-left bg-blue-50'
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white overflow-y-auto'>
              {isLoading ? (
                <tr>
                  <td
                    className='w-full text-center py-4 bg-gray-50'
                    colSpan='9'
                  >
                    <CircularLoader
                      classes='flex justify-center items-center'
                      classloader='loader-l'
                    />
                  </td>
                </tr>
              ) : (
                <>
                  {templateList && templateList?.length > 0 ? (
                    templateList?.map((item, index) => (
                      <tr key={index}>
                        <td className='capitalize break-words py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6'>
                          <div className='flex items-center space-x-2'>
                            <span>{item.title}</span>
                            {item.is_complete ? (
                              <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800'>
                                Complete
                              </span>
                            ) : (
                              <span className='inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800'>
                                Incomplete
                              </span>
                            )}
                          </div>
                        </td>
                        {templateList.some((item) =>
                          item.title.includes("Supervisory Meeting Record")
                        ) && (
                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize'>
                            {item.title.includes("Supervisory Meeting Record")
                              ? item.created_at
                              : null}
                          </td>
                        )}
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize'>
                          {item.created_by ?? ""}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {formatDateIntoDMYToDisplay(item.created_at)}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:pl-4 capitalize'>
                          {item?.updated_by ?? ""}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {formatDateIntoDMYToDisplay(item?.updated_at) ?? ""}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                          {formatDateIntoDMYToDisplay(item.expiry_date)}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                          {item.version}
                        </td>
                        <td className='whitespace-nowrap px-3 py-4 text-center'>
                          <div className='flex items-center justify-start space-x-2'>
                            <FormsActionButtons
                              role={role}
                              item={item}
                              parentSlug={parentSlug}
                              participantsUid={participantsUid}
                              participantId={participantId}
                              selectedPdfId={selectedPdfId}
                              handleDownload={handleDownload}
                              refreshFormList={refreshFormList}
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <>
                      {renderLoading === LOADER_STATUS.COMPLATE &&
                        templateList.length === 0 && (
                          <tr>
                            <td
                              colSpan='9'
                              className=' w-full text-center p-6 '
                            >
                              <div className='text-red-500 flex justify-center items-center space-x-2'>
                                <ImBlocked />
                                <span className='inline-block text-red-500'>
                                  No Data found!
                                </span>
                              </div>
                            </td>
                          </tr>
                        )}
                    </>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SubmittedFormViewTable;
