import React, { useContext, useEffect, useState } from "react";
import ModalSkeleton from "../../../../components/Modals/ModalSkeleton";
import { useForm } from "react-hook-form";
import SelectOption from "../../../../components/common/Select/Select";
import CustomButton from "../../../../components/common/Button/CustomButton";
import { LOADER_STATUS } from "../../../../Constants/constant";
import { NumericFormat } from "react-number-format";
import { LoginContext } from "helpers/LoginContext";

const PlanBudgetModal = (props) => {
  const {
    openModal,
    closeModal,
    PlanCatagories,
    funding,
    catalogState,
    createPlanBudget,
    storePlanBudgetLoading,
    participant,
    storePlanBudgetError,
    editplanBudget,
  } = props;

  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [catagory, setCatagory] = useState(null);
  const [subCatagory, setSubCatagory] = useState(null);
  const [storeRenderLoader, setStoreRenderLoader] = useState(LOADER_STATUS.NA);

  useEffect(() => {
    if (catagory !== null) {
      setValue("service_category_id", catagory.value);
      clearErrors("service_category_id");
    }
    const valdiate = () => {
      const index = PlanCatagories.subCatagories[
        catagory.label.replace(" ", "_")
      ].findIndex((x) => x.value === subCatagory.value);
      if (index === -1) {
        setValue("service_sub_category_id", null);
        setSubCatagory(null);
      }
    };
    if (
      editplanBudget &&
      catagory &&
      subCatagory &&
      catagory.value !== editplanBudget.service_category.id
    ) {
      valdiate();
    }
    if (editplanBudget === null && catagory && subCatagory) {
      valdiate();
    }
  }, [catagory]);

  useEffect(() => {
    if (subCatagory !== null) {
      setValue("service_sub_category_id", subCatagory.value);
      clearErrors("service_sub_category_id");
    }
  }, [subCatagory]);

  useEffect(() => {
    if (
      !storePlanBudgetLoading &&
      storeRenderLoader !== LOADER_STATUS.NA &&
      storePlanBudgetError.length <= 0
    ) {
      handleClose();
    }
  }, [storePlanBudgetLoading]);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const watchFormData = watch();

  // console.log("watch From dAte", watchFormData);

  useEffect(() => {
    if (editplanBudget) {
      setCatagory({
        label: editplanBudget.service_category.name,
        value: editplanBudget.service_category.id,
      });
      setSubCatagory({
        label: editplanBudget.service_sub_category.title,
        value: editplanBudget.service_sub_category.id,
      });
      reset({
        total_budget_amount: editplanBudget.total_budget_amount,
        funding_id: funding.contract_id,
      });
    }
  }, [editplanBudget]);

  useEffect(() => {
    if (catalogState) {
      setValue("state_id", catalogState.label);
    }
  }, [catalogState]);

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    if (editplanBudget) {
      Object.assign(data, {
        plan_budget_uuid: editplanBudget.uuid,
      });
    }
    Object.assign(data, {
      funding_id: funding.uuid,
      pt_id: participant ? participant.uuid : "1",
    });
    if (catalogState) {
      Object.assign(data, { state_id: catalogState.value });
    }
    setStoreRenderLoader(LOADER_STATUS.PENDING);
    createPlanBudget({ data, isUpdate: editplanBudget ? true : false });
  };

  const handleClose = () => {
    reset();
    setCatagory(null);
    setSubCatagory(null);
    closeModal();
  };

  const modalFooter = () => {
    return (
      <div>
        <CustomButton
          showLoading={storePlanBudgetLoading}
          isDisabled={storePlanBudgetLoading || isUserGracePeriodOver}
          label={editplanBudget ? "Update" : "Submit"}
          clickHandler={() => {
            if (isUserGracePeriodOver) {
              return;
            }
            const buttonRef = document.getElementById("planBudgetModalButton");
            if (buttonRef) {
              buttonRef.click();
            }
          }}
        />
      </div>
    );
  };

  return (
    <ModalSkeleton
      isOpen={openModal}
      closeModal={handleClose}
      modalFooter={modalFooter()}
      cssClass='w-[50%]'
      title={editplanBudget ? "Update Plan Budget" : "Create New Plan Budget"}
    >
      <div className='flex flex-col'>
        <p className='text-sm text-gray-500 mb-3'>
          Please enter following details to
          {editplanBudget ? " update" : " create"} Plan Budget
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className='space-y-3'>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                Funding ID
              </label>
              <input
                {...register("funding_id", {
                  required: true,
                  value: funding.contract_id,
                })}
                style={{
                  height: "38px",
                  backgroundColor: "#4b6bb710",
                }}
                value={funding.contract_id}
                disabled
                readOnly
                name='funding_id'
                id=''
                className='did-floating-input mt-1 bg-[#4b6bb710]'
              />
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='total_budget_amount'
                className='block text-sm font-medium text-gray-700'
              >
                Budget Amount
              </label>
              <div className='mt-1'>
                <input
                  type='hidden'
                  {...register("total_budget_amount", {
                    required: true,
                    // value: parseInt(watchFormData.total_budget_amount, 10),
                  })}
                />
                <NumericFormat
                  min={1}
                  type='text'
                  maxLength={"13"}
                  className='focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md w-full'
                  value={watchFormData.total_budget_amount || ""}
                  thousandsGroupStyle='thousand'
                  thousandSeparator=','
                  decimalScale={2} // Allow decimal places
                  prefix={"$"}
                  allowLeadingZeros={false}
                  onValueChange={(values) => {
                    const { value } = values;
                    setValue("total_budget_amount", value || ""); // Set empty string if backspaced completely
                    clearErrors("total_budget_amount");
                  }}
                />
                {errors?.total_budget_amount?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  {...register("service_category_id", {
                    required: true,
                  })}
                  name='service_category_id'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Plan Category
              </label>
              <SelectOption
                cssClass='mt-1'
                // isSearch={false}
                isDisabled={editplanBudget ? true : false}
                data={PlanCatagories?.catagory ? PlanCatagories.catagory : []}
                selectedOption={catagory}
                handleChange={setCatagory}
                // placeHolder="Select Plan Category"
              />
              {errors?.service_category_id?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  {...register("service_sub_category_id", {
                    required: true,
                  })}
                  name='service_sub_category_id'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Plan Subcategory
              </label>
              <SelectOption
                cssClass='mt-1'
                isSearch={false}
                isDisabled={editplanBudget ? true : false}
                data={
                  catagory && catagory?.label
                    ? PlanCatagories.subCatagories[
                        catagory.label.replace(" ", "_")
                      ]
                    : []
                }
                selectedOption={subCatagory}
                handleChange={setSubCatagory}
                // placeHolder={`${
                //   catagory === null
                //     ? "Select Plan Category First!"
                //     : "Select Plan Sub Category"
                // }`}
              />
              {errors?.service_sub_category_id?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
          </div>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label
                htmlFor='total_budget_amount'
                className='block text-sm font-medium text-gray-700'
              >
                State
              </label>
              <div className='mt-1'>
                <input
                  {...register("state_id", {
                    // required: true,
                    maxLength: 254,
                  })}
                  placeholder={
                    watchFormData.state_id === undefined
                      ? "Please select state from setting..."
                      : null
                  }
                  readOnly
                  disabled
                  type='text'
                  name='state_id'
                  id='state_id'
                  className='px-4 py-2 border shadow-sm block w-full sm:text-sm border-gray-300 rounded-md capitalize bg-[#4b6bb710]'
                />
                {errors?.state_id?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            </div>
            {subCatagory?.label?.toLowerCase() === "support coordination" && (
              <div className='col-span-1 mt-1'>
                <div className='flex flex-row did-floating-label-content mt-6 space-x-4'>
                  <div>
                    <label
                      htmlFor=''
                      className=' text-start did-floating-label'
                    >
                      Active
                    </label>
                  </div>
                  <div>
                    <input
                      // ref={register({ required: "This is required" })}
                      {...register("is_cos", {
                        // required: true,
                      })}
                      name='is_cos'
                      value={true}
                      checked
                      type='checkbox'
                    />
                    {errors?.is_cos?.type === "required" && (
                      <span className='pl-3 text-sm text-red-500'>
                        This field is required!
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <button
            type='submit'
            className='hidden sr-only'
            id='planBudgetModalButton'
          />
        </form>
      </div>
    </ModalSkeleton>
  );
};

export default PlanBudgetModal;
