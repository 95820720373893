import { useState, useEffect } from "react";
import RosteringAPI from "store/services/rostering.services";
import { parseBooleanVal } from "Utils/utils";
import { useSelector } from "react-redux";
import { getIsAuthenticateForPayroll } from "store/selector/rostering.selector";
import { PAYROLL_PLATFORMS } from "store/constants";

const usePayrollAuthentication = (isForCOSModule=true) => {
  const isAuthenticateForPayrollSelector = useSelector(
    getIsAuthenticateForPayroll
  );
  const [isAuthenticateForPayrolls, setIsAuthenticateForPayrolls] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCallBackResp, setIsCallBackResp] = useState(null);

  useEffect(() => {
    if(isForCOSModule){
      checkPayrollAuthentication();
      setIsCallBackResp(null);
    }
  }, []);

  useEffect(() => {
    setIsAuthenticateForPayrolls(isAuthenticateForPayrollSelector);
  }, [isAuthenticateForPayrollSelector]);

  const checkPayrollAuthentication = async (isCheckCallBack = null) => {
    try {
      setIsLoading(true);
      const response = await RosteringAPI.checkPayrollAuthentication();
      if (response && response.data) {
        const authenticated_payrolls = {};
        Object.values(PAYROLL_PLATFORMS).forEach((p) => {
          if (isCheckCallBack && p === isCheckCallBack) {
            setIsCallBackResp({
              platform: isCheckCallBack,
              status:
                response?.data[`${p}`] &&
                parseBooleanVal(response?.data[`${p}`]) &&
                parseBooleanVal(response?.data[`${isCheckCallBack}`]),
            });
          }
          Object.assign(authenticated_payrolls, {
            [`${p}`]:
              response?.data[`${p}`] && parseBooleanVal(response?.data[`${p}`]),
          });
        });
        setIsAuthenticateForPayrolls(authenticated_payrolls);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
    }
  };

  const isAllPayrollUnAuthenticate = () => {
    if (
      isAuthenticateForPayrolls !== null ||
      isAuthenticateForPayrolls !== false
    ) {
      return Object.values(isAuthenticateForPayrolls || {}).every(
        (i) => !parseBooleanVal(i)
      );
    }
    return true;
  };

  const clearIsCallBackResp = () => {
    setIsCallBackResp(null);
  };

  return {
    isAuthenticateForPayrolls,
    isAllPayrollUnAuthenticate,
    checkPayrollAuthentication,
    isCheckPayrollAuthLoading: isLoading,
    isCallBackResp,
    clearIsCallBackResp,
  };
};

export default usePayrollAuthentication;
