import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import { capitalizeFirstLetter, classNames, getNameProfile } from "Utils/utils";
import "./Select.css";
import { isArray, isEmpty } from "lodash";
import Loader from "../Loader/Loader";

const SelectOption = (props) => {
  const {
    data,
    selectedOption,
    handleChange,
    placeHolder,
    isSearch,
    name,
    cssClass,
    isDisabled,
    isMulti = false,
    defaultValue,
    isClearable = false,
    loading = false,
    showIcon = false,
    profileFieldMargin,
    register = null,
    isRequired = false,
    errorMessage = null,
    setValue = null,
    clearErrors,
    validate,
    noOptionsMessage = "No Options",
    setIsTimeChanged,
    setActualDate,
    isValueChanges,
    isClearCall,
    onFocusHandler,
    isApiLoading = false,
    isManagerModule = false,
    altName = null,
    isForRostering=false
  } = props;

  const [inComponentSelectedOpt, setInComponentSelectedOpt] = useState({});

  useEffect(() => {
    if (
      selectedOption !== null &&
      selectedOption !== undefined &&
      selectedOption &&
      setValue &&
      register
    ) {
      if (isMulti && isArray(selectedOption) && selectedOption.length > 0) {
        setInComponentSelectedOpt(selectedOption);
        return;
      } else if (
        isMulti === false &&
        inComponentSelectedOpt &&
        inComponentSelectedOpt.value === selectedOption.value
      ) {
      } else if (isMulti === false && isEmpty(inComponentSelectedOpt)) {
        setInComponentSelectedOpt(selectedOption);
      } else {
        if (
          name === "invoice_service_type_id" ||
          altName === "google_state_id"
        ) {
          setValue(name, selectedOption?.value ? selectedOption?.value : null);
          setInComponentSelectedOpt(selectedOption);
        } else {
          setValue(name, selectedOption?.value ? selectedOption?.value : null);
          setInComponentSelectedOpt(null);
        }
      }
    } else if (
      (selectedOption == null || selectedOption === undefined) &&
      inComponentSelectedOpt !== null &&
      setValue &&
      register
    ) {
      if (name === "invoice_service_type_id") {
        if (inComponentSelectedOpt?.value) {
          setValue(name, null);
          setInComponentSelectedOpt(null);
        }
      } else {
        setValue(name, null);
        setInComponentSelectedOpt(null);
      }
    }

    if (register === null && setValue === null) {
      setInComponentSelectedOpt(selectedOption);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (
      setValue &&
      setValue !== null &&
      register &&
      register !== null &&
      inComponentSelectedOpt &&
      inComponentSelectedOpt.hasOwnProperty("value")
    ) {
      if (
        isValueChanges &&
        inComponentSelectedOpt.value !== selectedOption?.value
      ) {
        isValueChanges(true, inComponentSelectedOpt.uuid);
      }

      setValue(name, inComponentSelectedOpt.value);

      if (clearErrors && errorMessage && name) {
        clearErrors(name, "");
      }
    }
    // else if (
    //   setValue &&
    //   setValue !== null &&
    //   register &&
    //   register !== null &&
    //   inComponentSelectedOpt === null &&
    //   !loading &&
    //   !isDisabled
    // ) {
    //   setValue(name, null);
    //   // if (clearErrors && name) {
    //   //   clearErrors(name, "");
    //   // }
    // }
  }, [inComponentSelectedOpt]);

  const getIcon = (type) => {
   
    return type;
  };

  const handleImageError = (e, fname, lname) => {
    // color=fff
    return (e.target.src = getNameProfile(fname, lname, "0D8ABC"));
  };

  const addRegisterComponent = () => {
    if (register && register !== null) {
      return {
        ...register(name, {
          value:
            selectedOption && inComponentSelectedOpt
              ? inComponentSelectedOpt
              : null,
          // disabled: isDisabled,
          required: isRequired,
          validate: () => {
            return validate;
          },
        }),
      };
    } else {
      return;
    }
  };

  // const ValueContainer = (containerProps) => {
  //   const { children } = containerProps;
  //   if (children[0]) {
  //     const { length } = children[0];
  //     const tmpChildren = [...children];
  //     if (length >= 2) {
  //       tmpChildren[0] = `${length} selected`;
  //     }
  //     return (
  //       <components.ValueContainer {...containerProps}>
  //         {tmpChildren}
  //       </components.ValueContainer>
  //     );
  //   }
  //   return (
  //     <components.ValueContainer {...containerProps}>
  //       {children}
  //     </components.ValueContainer>
  //   );
  // };

  const CustomMultiValue = (multiProps) => {
    return (
      <components.MultiValue {...multiProps}>
        <div title={multiProps.data.label}>{multiProps.children}</div>
      </components.MultiValue>
    );
  };

  const CustomValueContainer = ({ children, ...containerProps }) => {
    return (
      <components.ValueContainer {...containerProps}>
        <div
          className='selected-options-container'
          style={{
            display: "flex",
            overflowX: "auto",
            whiteSpace: "nowrap",
            // maxWidth: "230px",
          }}
        >
          {children}
        </div>
      </components.ValueContainer>
    );
  };
  if (showIcon) {
    const CustomSelectValue = (csprops) => {
      return (
        <components.SingleValue {...csprops}>
          <div className='flex items-center capitalize'>
            {csprops.data.label && csprops.data.icon && (
              <img
                onError={(e) => handleImageError(e, csprops.data.label, " ")}
                className='w-7 h-7 bg-gray-300 rounded-full flex-shrink-0 object-cover mr-2'
                src={getIcon(csprops.data.icon || csprops.data.value)}
                alt=''
              />
            )}
            {csprops.data.label}
          </div>
        </components.SingleValue>
      );
    };

    const CustomOption = (props) => {
      const { data } = props;
      return (
        <components.Option {...props}>
          <div className='flex items-center capitalize'>
            {data.label && data.icon && (
              <img
                className='w-7 h-7 bg-gray-300 rounded-full flex-shrink-0 object-cover mr-2'
                src={getIcon(data.icon || data.value)}
                alt=''
              />
            )}
            {data.label}
          </div>
        </components.Option>
      );
    };

    return (
      <>
        <Select
          {...addRegisterComponent()}
          value={
            selectedOption && inComponentSelectedOpt
              ? inComponentSelectedOpt
              : null
          }
          defaultValue={
            selectedOption && inComponentSelectedOpt
              ? inComponentSelectedOpt
              : null
          }
          onChange={(e) => {
            if (e === null && isClearCall) {
              isClearCall();
            }

            if (handleChange) handleChange(e);
            else {
              setInComponentSelectedOpt(e);
            }
          }}
          options={data}
          components={{
            Option: CustomOption,
            SingleValue: CustomSelectValue,
          }}
          isSearchable={isSearch}
          isLoading={loading}
          isClearable={isClearable}
          isDisabled={isDisabled}
          placeholder={placeHolder}
          className={classNames(
            `w-100 roleSelector scrollOptions capitalize`,
            cssClass,
            errorMessage?.length > 0 && "errorBorderForSelect"
          )}
          noOptionsMessage={() => noOptionsMessage}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menuList: (base) => ({
              ...base,
              maxHeight:isForRostering ? "150px" : "", 
              overflowY: isForRostering ?  "auto" : "",  
            }),
          }}
        />
        {errorMessage && (
          <span style={{ fontSize: "13px", color: "red", marginLeft: "5px" }}>
            {errorMessage}
          </span>
        )}
      </>
    );
  }

  return (
    <>
      {isMulti ? (
        <Select
          {...addRegisterComponent()}
          defaultValue={
            selectedOption && inComponentSelectedOpt
              ? inComponentSelectedOpt
              : null
          }
          value={
            selectedOption && inComponentSelectedOpt
              ? inComponentSelectedOpt
              : null
          }
          // menuPlacement='auto'
          closeMenuOnSelect={false}
          components={
            isManagerModule
              ? {
                  MultiValue: CustomMultiValue,
                  ValueContainer: CustomValueContainer,
                }
              : { MultiValue: CustomMultiValue }
          }
          // components={animatedComponents}
          isMulti
          options={data}
          onChange={(e) => {
            if (handleChange) handleChange(e);
            else {
              setInComponentSelectedOpt(e);
            }
          }}
         
          isDisabled={isDisabled}
          name={name}
          isClearable={isClearable}
          isLoading={loading}
          isSearchable={isSearch}
          placeholder={placeHolder}
          // isClearable={isClearable}
          className={classNames(
            `w-100 roleSelector scrollOptions capitalize ${cssClass}`,
            errorMessage?.length > 0 && "errorBorderForSelect"
          )}
          // hideSelectedOptions
          // components={{ ValueContainer }}
          noOptionsMessage={() =>
            isApiLoading ? (
              <div className='flex justify-center'>
                {" "}
                <Loader
                  isSmallView={true}
                  cssClass={"text-black font-bo  mr-0 p-0 flex"}
                />
              </div>
            ) : (
              noOptionsMessage
            )
          }
          onFocus={onFocusHandler}
          styles={{
            valueContainer: (base) => ({
              ...base,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start",
              maxHeight: "100%",
              overflowY: "auto",
            }),
            multiValue: (base) => ({
              ...base,
              margin: "2px",
            }),
            control: (base) => ({
              ...base,
              minHeight: "40px",
              alignItems: "flex-start",
            }),
          }}
        />
      ) : (
        <Select
          {...addRegisterComponent()}
          isLoading={loading}
          isClearable={isClearable}
          isDisabled={isDisabled}
          name={name}
          getOptionLabel={(option) => capitalizeFirstLetter(option.label)}
          isSearchable={isSearch}
          options={data}
          blurInputOnSelect={true}
          menuPlacement='auto'
          placeholder={placeHolder}
          value={
            selectedOption && inComponentSelectedOpt
              ? inComponentSelectedOpt
              : null
          }
          onChange={(e) => {
            if (handleChange) {
              if (setIsTimeChanged) {
                setIsTimeChanged(true);
              }
              if (setActualDate) {
                setActualDate(true);
              }
              handleChange(e);
            } else {
              setInComponentSelectedOpt(e);
              if (clearErrors) clearErrors(name, "");
            }
          }}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            input: (provided) => ({
              ...provided,
              width: "100%",
              minWidth: "2px",
            }),
          }}
          menuPortalTarget={document.body}
          className={classNames(
            `select-basic-single w-100 roleSelector scrollOptions`,
            cssClass,
            errorMessage ? "errorBorderForSelect" : ""
          )}
          noOptionsMessage={() => noOptionsMessage}
        />
      )}

      {errorMessage ? (
        <span
          style={{
            fontSize: "13px",
            color: "red",
            // marginLeft: "5px"
          }}
          // className='capitalize'
        >
          {errorMessage}
        </span>
      ) : (
        ""
      )}
    </>
  );
};

export default SelectOption;
