import SideBar from "../../../../components/common/SideBar/SideBarNew";
import Header from "../../../../components/common/Header/Header";
import Breadcrumb from "../../../../components/common/Breadcrumb";
import {SETTINGS_BREADCRUMB_PAGES, SP_SETTING_BREADCRUMB} from "../../../../Constants/BreadcrumbConstant";
import CircularLoader from "../../../../components/common/CircularLoader/CircularLoader";
import {baseURLImage} from "../../../../Utils/utils";
import React, {useContext, useEffect, useState} from "react";
import {LoginContext} from "../../../../helpers/LoginContext";
import {apiDeleteRequest, apiGetRequest, apiPostRequest} from "../../../../helpers/Requests";
import defaultLogo from "../../../../assets/logo/colorNWhite.svg";
import {toast} from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import {ExclamationIcon} from "@heroicons/react/outline";
import LoaderButton from "../../../../components/common/LoaderButton/LoaderButton";

const Branding = () => {
    const { sideBarPadding, setRefreshNavigations } = useContext(LoginContext);

    const [brandLogo, setBrandLogo] = useState(defaultLogo);
    const [invoiceLogo, setInvoiceLogo] = useState(defaultLogo);
    const [logoToRemove,setLogoToRemove] = useState(null);
    const [loading, setLoading] = useState({
        "primary-log" : false,
        "invoice-log" : false,
    });
    const [error, setError] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        const fetchLogos = async () => {
            setLoading((prev) => ({ ...prev, "primary-logo": true, "invoice-logo": true }));
            setError(null);

            try {
                const response = await apiGetRequest("sp/sp-logo");
                if (response.status === 200) {
                    if (response.data?.brand_logo_url) {
                        setBrandLogo(baseURLImage(response.data.brand_logo_url));
                    }
                    if (response.data?.invoice_logo_url) {
                        setInvoiceLogo(baseURLImage(response.data.invoice_logo_url));
                    }
                }
            } catch (error) {
                toast.error(error?.message ?? "Error fetching logos");
            } finally {
                setLoading((prev) => ({ ...prev, "primary-logo": false, "invoice-logo": false }));
            }
        };

        fetchLogos();
    }, []);

    const handleImageChange = async (event, logoType) => {
        const logo = event.target.files[0];
        if (!logo) return;

        if (!logo.type.startsWith("image/")) {
            toast.error("Only image files are allowed");
            event.target.value = null;
            return;
        }

        if (logo.size > 3 * 1024 * 1024) {
            toast.error("Image size exceeds the allowed limit. Please ensure it is no more than 3MB");
            event.target.value = null;
            return;
        }

        const img = new Image();
        img.src = URL.createObjectURL(logo);

        img.onload = async () => {
            const minWidth = 300;
            const maxWidth = 500;
            const maxHeight = 256;

            const isSVG = logo.type === "image/svg+xml";

            if (!isSVG) {
                if (img.width < minWidth) {
                    toast.error(`The logo width is too small. It must be at least ${minWidth}px.`);
                    event.target.value = null;
                    return;
                } else if (img.width > maxWidth) {
                    toast.error(`The logo width should be between ${minWidth}px and ${maxWidth}px.`);
                    event.target.value = null;
                    return;
                } else if (img.height > maxHeight) {
                    toast.error(`Logo height exceeds the allowed limit. Please ensure it is no more than ${maxHeight}px.`);
                    event.target.value = null;
                    return;
                }
            }
            if (isSVG && logo.size > 3 * 1024 * 1024) {
                toast.error("SVG file size should be less than 3MB");
                event.target.value = null;
                return;
            }
            const formData = new FormData();
            formData.append(logoType, logo);
            setLoading((prev) => ({ ...prev, [logoType]: true }));
            try {
                const response = await apiPostRequest("sp/sp-logo", formData);
                if (response.status === 200 && response.data?.logo_path) {
                    setLoading(true);
                    if (logoType === "primary-logo") {
                        setBrandLogo(baseURLImage(response.data.logo_path));
                        setRefreshNavigations((prev) => {
                            const newValue = prev + 1;
                            return newValue >= 2 ? 0 : newValue;
                        });
                        event.target.value = null;
                    } else if (logoType === "invoice-logo") {
                        setInvoiceLogo(baseURLImage(response.data.logo_path));
                        event.target.value = null;
                    }
                    toast.success("Logo Updated Successfully");
                } else {
                    toast.error("Failed to upload logo.");
                }
            } catch (error) {
                toast.error("Something went wrong.");
            } finally {
                setLoading((prev) => ({ ...prev, [logoType]: false }));
            }
        };
    };

    const handleCancelLogo = (logoType) => {
        setLogoToRemove(logoType);
        setIsDialogOpen(true);
    }
    const removeLogo = async () => {
        setLoading((prev) => ({ ...prev, [logoToRemove]: true }));
        try {
            const endpoint = logoToRemove === "primary-logo" ? "sp/remove-sp-logo" : "sp/remove-sp-logo";
            const payload = { logo_type: logoToRemove };
            const response = await apiPostRequest(endpoint, payload);
            if (response.status === 200) {
                if (logoToRemove === "primary-logo") {
                    setBrandLogo(defaultLogo);
                    setRefreshNavigations((prev) => {
                        const newValue = prev + 1;
                        return newValue >= 2 ? 0 : newValue;
                    });
                    setIsDialogOpen(false);
                } else if (logoToRemove === "invoice-logo") {
                    setInvoiceLogo(defaultLogo);
                    setIsDialogOpen(false);
                }
                toast.success("Logo removed successfully");
            } else {
                toast.error( "Failed to remove logo");
            }
        } catch (e) {
            setIsDialogOpen(false);
            toast.error( e ? e.message : 'Error removing logo');
        }
        finally {
            setLoading((prev) => ({ ...prev, [logoToRemove]: false }));
        }
    }

    const closeDialog = () => {
        setIsDialogOpen(false);
    }

    return (
        <div>
            <div className='min-h-full'>
                <SideBar />
                <div className={`${ sideBarPadding
                            ? "lg:pl-[4rem] duration-300"
                            : "lg:pl-64 duration-300" }  flex flex-col`} >
                    <Header
                        title='Branding'
                        breadcrumb={
                            <Breadcrumb
                                pages={SP_SETTING_BREADCRUMB(
                                    SETTINGS_BREADCRUMB_PAGES.BRANDING
                                )}
                            />
                        }
                    />
                    <section className='container mx-auto sm:px-6 lg:px-8 mt-3'>
                        <divc className='m-0'>
                            <div className='grid grid-cols-2 gap-y-4 gap-x-5'>
                                <div className='col-span-1'>
                                    <div className='flex items-center justify-between mb-4'>
                                        <div>
                                            <h2 className='text-2xl font-extrabold text-gray-900 tracking-tight'>
                                                Brand Logo
                                            </h2>
                                            <p className='text-xs text-gray-600 mt-1'>
                                                Define your identity. Recommended: 300 (width) x 256 (height)px, max 3MB (SVG, PNG, JPG).
                                            </p>
                                        </div>
                                    </div>
                                    <div className='relative bg-gradient-to-br from-gray-50 to-indigo-50 rounded-2xl shadow-2xl overflow-hidden border border-indigo-200 transition-all duration-500 hover:shadow-3xl'>
                                        <div className='flex h-64'>
                                            <div className='w-1/2 p-4 flex flex-col items-center justify-center bg-white/80 backdrop-blur-sm border-r border-indigo-100 transition-opacity duration-300 hover:opacity-100'>
                                                {loading['primary-logo'] ? (
                                                    <div className='flex items-center justify-center w-full h-full'>
                                                        <div className='relative'>
                                                            <CircularLoader
                                                                classes='flex justify-center items-center'
                                                                classloader='loader-l'
                                                            />
                                                            <div className='absolute inset-0 bg-indigo-500/10 rounded-full transition-opacity duration-1000 opacity-50 hover:opacity-100' />
                                                        </div>
                                                    </div>
                                                ) : brandLogo ? (
                                                    <div className='w-full h-full flex items-center justify-center overflow-hidden relative'>
                                                        <div
                                                            className='relative w-full h-full flex items-center justify-center rounded-lg'
                                                            style={{
                                                                background: 'linear-gradient(135deg, #3A558A, #5A7AC4)',
                                                                borderRadius: '0.5rem',
                                                            }}
                                                        >
                                                            <img
                                                                src={brandLogo}
                                                                alt='Brand Logo'
                                                                className='max-w-[90%] max-h-[90%] object-contain p-2 transition-transform duration-500 hover:scale-105'
                                                            />

                                                            <button
                                                                title={brandLogo === defaultLogo ? "Cannot remove default logo" : ""}
                                                                disabled={brandLogo === defaultLogo}
                                                                onClick={brandLogo !== defaultLogo ? () => handleCancelLogo('primary-logo') : undefined}
                                                                className={`absolute top-2 right-2 rounded-full w-6 h-6 flex items-center justify-center transition-colors duration-200 focus:outline-none 
    ${brandLogo === defaultLogo ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#4A6AB4] hover:bg-[#3A558A] text-white'}`}
                                                            >
                                                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                </svg>
                                                            </button>

                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className='text-center transition-all duration-300 hover:scale-105'>
                                                        <svg
                                                            className='mx-auto h-20 w-20 text-indigo-400 transition-opacity duration-1000 opacity-50 hover:opacity-100'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            viewBox='0 0 24 24'
                                                        >
                                                        <path
                                                                strokeLinecap='round'
                                                                strokeLinejoin='round'
                                                                strokeWidth='2'
                                                                d='M12 6v6m0 0v6m0-6h6m-6 0H6'
                                                            />
                                                        </svg>
                                                        <p className='mt-2 text-sm font-medium text-gray-700'>
                                                            Your logo awaits
                                                        </p>
                                                        <p className='mt-1 text-xs text-gray-500'>Click to begin</p>
                                                    </div>
                                                )}
                                            </div>

                                            <div className='w-1/2 p-4 flex flex-col bg-indigo-50/80 backdrop-blur-sm transition-all duration-300 hover:bg-indigo-100 relative'>
                                                <label
                                                    htmlFor='brand-logo-upload'
                                                    className='w-full h-full flex flex-col items-center justify-center cursor-pointer group'
                                                    style={{ pointerEvents: loading['primary-logo'] ? 'none' : 'auto' }}
                                                >
                                                    <div className='text-center'>
                                                        <p className='text-sm text-gray-600 group-hover:text-indigo-700 transition-colors'>
                                                            Click here to upload your image
                                                        </p>
                                                        <div className='flex justify-center space-x-2 mt-1'>
                                                            <span className='text-xs text-gray-500'>SVG,PNG,JPEG,JPG etc.  </span>
                                                        </div>
                                                    </div>
                                                    <input
                                                        id='brand-logo-upload'
                                                        type='file'
                                                        className='hidden'
                                                        accept='image/*'
                                                        onChange={(e) => handleImageChange(e, 'primary-logo')}
                                                        disabled={loading['primary-logo']}
                                                    />
                                                    {error && (
                                                        <p className='mt-2 text-sm font-medium text-red-600 transition-opacity duration-300'>
                                                            {error}
                                                        </p>
                                                    )}
                                                    {!brandLogo && !loading['primary-logo'] && (
                                                        <div className='absolute bottom-3 right-3 bg-white/90 backdrop-blur-sm p-2 rounded-lg shadow-md text-xs text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                                                            Upload to personalize your brand!
                                                        </div>
                                                    )}
                                                </label>
                                            </div>
                                        </div>

                                        <div className='absolute -top-4 -left-4 w-24 h-24 bg-indigo-300/20 rounded-full blur-xl transition-opacity duration-1000 opacity-50 hover:opacity-100' />
                                        <div className='absolute -bottom-4 -right-4 w-32 h-32 bg-purple-300/20 rounded-full blur-xl transition-opacity duration-1000 opacity-50 hover:opacity-100' />
                                    </div>
                                </div>
                                <div className='col-span-1'>

                                    <div className='flex items-center justify-between mb-4'>
                                        <div>
                                            <h2 className='text-2xl font-extrabold text-gray-900 tracking-tight'>
                                                Invoice Logo
                                            </h2>
                                            <p className='text-xs text-gray-600 mt-1'>
                                                Customize your invoices. Recommended: 300 (width) x 256 (height)px, max 3MB (SVG, PNG, JPG).
                                            </p>
                                        </div>

                                    </div>


                                    <div className='relative bg-gradient-to-br from-gray-50 to-indigo-50 rounded-2xl shadow-2xl overflow-hidden border border-indigo-200 transition-all duration-500 hover:shadow-3xl'>
                                        <div className='flex h-64'>

                                            <div className='w-1/2 p-4 flex flex-col items-center justify-center bg-white/80 backdrop-blur-sm border-r border-indigo-100 transition-opacity duration-300 hover:opacity-100'>
                                                {loading['invoice-logo'] ? (
                                                    <div className='flex items-center justify-center w-full h-full'>
                                                        <div className='relative'>
                                                            <CircularLoader
                                                                classes='flex justify-center items-center'
                                                                classloader='loader-l'
                                                            />
                                                            <div className='absolute inset-0 bg-indigo-500/10 rounded-full transition-opacity duration-1000 opacity-50 hover:opacity-100' />
                                                        </div>
                                                    </div>
                                                ) : invoiceLogo ? (
                                                    <div className='w-full h-full flex items-center justify-center overflow-hidden'>
                                                        <div
                                                            className='relative w-full h-full flex items-center justify-center rounded-lg'
                                                            style={{
                                                                background: 'linear-gradient(135deg, #3A558A, #5A7AC4)',
                                                                borderRadius: '0.5rem',
                                                            }}
                                                        >
                                                            <img
                                                                src={invoiceLogo}
                                                                alt='Invoice Logo'
                                                                className='max-w-[90%] max-h-[90%] object-contain p-2 transition-transform duration-500 hover:scale-105'
                                                            />

                                                            <button
                                                                title={invoiceLogo === defaultLogo ? "Cannot remove default logo" : ""}
                                                                disabled={invoiceLogo === defaultLogo}
                                                                onClick={invoiceLogo !== defaultLogo ? () => handleCancelLogo('invoice-logo') : undefined }
                                                                className={`absolute top-2 right-2 rounded-full w-6 h-6 flex items-center justify-center transition-colors duration-200 focus:outline-none 
    ${invoiceLogo === defaultLogo ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#4A6AB4] hover:bg-[#3A558A] text-white'}`}
                                                            >
                                                                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='text-center transition-all duration-300 hover:scale-105'>
                                                        <svg
                                                            className='mx-auto h-20 w-20 text-indigo-400 transition-opacity duration-1000 opacity-50 hover:opacity-100'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            viewBox='0 0 24 24'
                                                        >
                                                            <path
                                                                strokeLinecap='round'
                                                                strokeLinejoin='round'
                                                                strokeWidth='2'
                                                                d='M12 6v6m0 0v6m0-6h6m-6 0H6'
                                                            />
                                                        </svg>
                                                        <p className='mt-2 text-sm font-medium text-gray-700'>
                                                            Your logo awaits
                                                        </p>
                                                        <p className='mt-1 text-xs text-gray-500'>Click to begin</p>
                                                    </div>
                                                )}
                                            </div>


                                            <div className='w-1/2 p-4 flex flex-col bg-indigo-50/80 backdrop-blur-sm transition-all duration-300 hover:bg-indigo-100'>
                                                <div className='flex flex-col h-full '>
                                                    <label
                                                        htmlFor='invoice-logo-upload'
                                                        className='flex flex-col h-full cursor-pointer'
                                                        style={{ pointerEvents: loading['invoice-logo'] ? 'none' : 'auto' }}
                                                    >
                                                        <div className='flex justify-center space-x-3'>
                                                            <div className='w-full text-center'>
                                                                <p className='text-sm font-medium text-gray-900 mb-2'>
                                                                    Live Preview: Invoice Header
                                                                </p>

                                                                <div className='w-full h-24 bg-gradient-to-br from-[#3A558A] to-[#5A7AC4] border border-gray-200 rounded-lg flex items-center justify-center'>
                                                                    <div className='flex w-full h-full items-center justify-between px-4'>
                                                                        <div className='w-1/2 text-left'>
                                                                            <p className='text-sm font-medium text-gray-100'>Invoice From</p>
                                                                            <p className='text-xs text-gray-300'>ABN: 1234-1234-1234</p>
                                                                        </div>

                                                                        <div className='w-1/2 flex justify-end items-center'>
                                                                            {invoiceLogo ? (
                                                                                <img
                                                                                    src={invoiceLogo}
                                                                                    alt='Invoice Logo Preview'
                                                                                    className='max-h-16 max-w-[80%] object-contain'
                                                                                />
                                                                            ) : (
                                                                                <span className='text-gray-500 text-sm'>No logo uploaded</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='text-center mt-5'>
                                                                    <div className="group">
                                                                        <p className="text-sm text-gray-600 group-hover:text-indigo-700 transition-colors">
                                                                            Click here to upload your image
                                                                        </p>
                                                                    </div>

                                                                    <div className='flex justify-center space-x-2 mt-1'>
                                                                        <span className='text-xs text-gray-500'>SVG,PNG,JPEG,JPG etc. </span>
                                                                    </div>
                                                                </div>
                                                                <input
                                                                    id='invoice-logo-upload'
                                                                    type='file'
                                                                    className='hidden'
                                                                    accept='image/*'
                                                                    onChange={(e) => handleImageChange(e, 'invoice-logo')}
                                                                    disabled={loading['invoice-logo']}
                                                                />
                                                            </div>
                                                        </div>
                                                    </label>

                                                    {error && (
                                                        <p className='mt-2 text-sm font-medium text-red-600 transition-opacity duration-300'>
                                                            {error}
                                                        </p>
                                                    )}
                                                    {!invoiceLogo && !loading['invoice-logo'] && (
                                                        <div className='absolute bottom-3 right-3 bg-white/90 backdrop-blur-sm p-2 rounded-lg shadow-md text-xs text-gray-700 opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                                                            Upload your invoice logo!
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='absolute -top-4 -left-4 w-24 h-24 bg-indigo-300/20 rounded-full blur-xl transition-opacity duration-1000 opacity-50 hover:opacity-100' />
                                        <div className='absolute -bottom-4 -right-4 w-32 h-32 bg-purple-300/20 rounded-full blur-xl transition-opacity duration-1000 opacity-50 hover:opacity-100' />
                                    </div>
                                </div>
                            </div>
                        </divc>
                    </section>
                </div>
            </div>

            <Transition.Root show={isDialogOpen} as={Fragment}>
                <Dialog as="div"
                        onClose={closeDialog}
                        className='fixed inset-0 z-50 top-50 overflow-y-auto'
                >
                    <Transition.Child
                        as={Fragment}
                        enter='ease-out duration-300'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in duration-200'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
                    </Transition.Child>

                    <div className='fixed z-10 inset-0 overflow-y-auto'>
                        <div className='flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0'>
                            <Transition.Child
                                as={Fragment}
                                enter='ease-out duration-300'
                                enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                                enterTo='opacity-100 translate-y-0 sm:scale-100'
                                leave='ease-in duration-200'
                                leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                                leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                            >
                                <Dialog.Panel className='relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6'>
                                    <div className='sm:flex sm:items-start'>
                                        <div className='mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10'>
                                            <ExclamationIcon
                                                className='h-6 w-6 text-red-600'
                                                aria-hidden='true'
                                            />
                                        </div>
                                        <div className='mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left'>
                                            <Dialog.Title
                                                as='h3'
                                                className='text-lg leading-6 font-medium text-gray-900'
                                            >
                                                You're about to Remove Logo
                                            </Dialog.Title>
                                            <div className='mt-2'>
                                                <p className='text-sm text-gray-500'>
                                                    Are you sure you want to remove the logo? This cannot be undone.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-5 sm:mt-4 sm:flex'>
                                        {loading[logoToRemove] ? (
                                            <LoaderButton
                                                classes="inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-white bg-red-500 border border-red-500 hover:border-red-400 hover:bg-red-400 rounded-md focus:outline-none cursor-block"
                                                svgClasses="inline mr-3 w-4 h-4 text-white animate-spin"
                                                textbutton="Loading..."
                                            />
                                        ) : (
                                            <button
                                                type="button"
                                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                onClick={removeLogo}
                                            >
                                                Yes, please
                                            </button>
                                        )}
                                        <button
                                            type='button'
                                            className='ml-2 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm'
                                            onClick={closeDialog}
                                        >
                                            Ah, nevermind!
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>

    );
}

export default Branding;