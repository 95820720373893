import React, { useContext, useEffect } from "react";
import SideBar from "../../../components/common/SideBar/SideBarNew";
import { LoginContext } from "helpers/LoginContext";
import NotificationSettingTable from "./NotificationSettingTable";
import { apiGetRequest } from "../../../helpers/Requests";
import { CapitalizeLabel } from "Utils/utils";

const NotificationSettings = () => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  const [selectedStatus, setSelectedStatus] = React.useState(null);

  const [userRoles, setUserRoles] = React.useState([]);
  const [getSettings, setGetSettings] = React.useState([]);
  const [Loading, setLoading] = React.useState(false);
  const [showTable, setShowTable] = React.useState(false);

  //for roles dropdown
  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = () => {
    apiGetRequest("/sp/permission/get-roles")
      .then((res) => {
        if (res.status === 200) {
          const rolesData = res.data?.data;

          const roles = rolesData
              .map((item) => {
                const obj = {
                  label: CapitalizeLabel(item.name),
                  value: item.id,
                };
                return obj;
            })
            .sort((a, b) =>
              a.label.toLowerCase().localeCompare(b.label.toLowerCase())
            );
          setUserRoles(roles);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetSettingsData = async () => {
    setLoading(true);

    await apiGetRequest(
      `/sp/notification/get-settings?role_id=${selectedStatus?.value}`
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setGetSettings(res.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (selectedStatus) {
      GetSettingsData();
    }
  }, [selectedStatus]);

  return (
    <div className='min-h-full'>
      <SideBar />
      <div
        className={`${
          sideBarPadding ? "lg:pl-[4rem] duration-300" : "lg:pl-64 duration-300"
        }  flex flex-col`}
      >
        <main className='h-screen flex-1 bg-white'>
          <NotificationSettingTable
            isUserGracePeriodOver={isUserGracePeriodOver}
            Loading={Loading}
            userRoles={userRoles}
            setSelectedStatus={setSelectedStatus}
            getSettings={getSettings}
            selectedStatus={selectedStatus}
            showTable={showTable}
            setShowTable={setShowTable}
            setGetSettings={setGetSettings}
            GetSettingsData={GetSettingsData}
            setLoading={setLoading}
          />
        </main>
      </div>
    </div>
  );
};

export default NotificationSettings;
