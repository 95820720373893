import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import { apiDeleteRequest } from "helpers/Requests";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const DeleteCaseNote = (props) => {
  const {
    isUserGracePeriodOver,

    openDeleteModal,
    closeModal,

    selectedUUID,
    setRefreshTable,
    refreshTableAfterDelete,
  } = props;
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [request, setRequest] = useState({
    isRequested: false,
    isSuccess: false,
    isFailed: false,
  });

  const navigate = useNavigate();

  const handleDelete = async () => {
    if (selectedUUID) {
      setDeleteLoader(true);
      setRequest({ isRequested: true, isSuccess: false, isFailed: false });
      try {
        const response = await apiDeleteRequest(
          `/cos/case-notes/delete/${selectedUUID}`
        );

        if (response.status === 200) {
          toast.success("Case Note deleted successfully!");
          // setRefreshTable(!refreshTableAfterDelete);
          setRequest({ isRequested: false, isSuccess: true, isFailed: false });
          closeModal();
          window.location.reload();
        }
      } catch (error) {
        toast.error(error?.message ?? "Something Went Wrong!");
        setRequest({ isRequested: false, isSuccess: false, isFailed: true });
        console.error("Failed to delete Case Note", error);
      }
    }
  };
  const modalFooter = () => (
    <>
      <CustomButton
        showLoading={request.isRequested}
        isDisabled={request.isRequested || isUserGracePeriodOver}
        label='Yes, delete Case Note'
        clickHandler={handleDelete}
        variant='danger'
      />
      <CustomButton label='No, cancel changes' onClick={closeModal} />
    </>
  );
  return (
    <>
      <ModalSkeleton
        modalFooter={modalFooter()}
        isOpen={openDeleteModal}
        closeModal={closeModal}
        cssClass='w-[40%]'
        title={"Delete Case Note"}
      >
        <div>
          <p className='text-gray-700 flex justify-center'>
            Are you sure you want to delete this Case Note?
          </p>
        </div>
      </ModalSkeleton>
    </>
  );
};

export default DeleteCaseNote;
