import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IoClose, IoWarningOutline } from "react-icons/io5";
import CustomButton from "components/common/Button/CustomButton";
import SelectOption from "components/common/Select/Select";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import MultipleSelection from "components/common/MultipleSelect";
import moment from "moment";
import { FaCheck, FaQuestionCircle } from "react-icons/fa";
import { apiPostRequest } from "helpers/Requests";
import {
  URL_ROSTERING_COPY_FORTHNIGHTLY_OVERLAPPING_CHECK,
  URL_ROSTERING_FORTNIGHTLY_COPY_SHIFT,
} from "store/services/URL";
import { toast } from "react-toastify";
import Loader from "components/common/Loader/Loader";
import { debounce } from "lodash";
import SwitchWithIcon from "components/common/SwitchWithIcon";
import { Tooltip } from "react-tooltip";
import { AiOutlineSafetyCertificate } from "react-icons/ai";

const CopyShift = ({
  isOpen,
  setCopyShifts,
  startDate,
  endDate,
  setIsMessage,
  setForthnightlyCopyShiftsMessage,
  recurringMessageModal,
  isUserGracePeriodOver,
  selectedDateTypes,
}) => {
  const [activeButton, setActiveButton] = useState(null);
  const [selectedToShift, setToShift] = useState([]);
  const [selectedFromShift, setFromShift] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [dateUptoOptions, setDateUptoOptions] = useState([]);
  const [nextForthnights, setNextForthnights] = useState([]);
  const [forSingleNextFortnight, setSingleForthnight] = useState([]);
  const [currentStart, setCurrentStart] = useState(null);
  const [currentEnd, setCurrentEnd] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingForOverlapping, setLoadingForOverlapping] = useState(false);
  const [overlappingData, setOverlappingData] = useState(null);
  const [checked, setCheck] = useState(false);

  const toastId = "forSubmitId";
  const DEBOUNCE_DELAY = 1000;
  useEffect(() => {
    if (selectedDateTypes?.startDate && activeButton === "advanced") {
      const periodLength = selectedDateTypes.view === "weekly" ? 7 : 14;
      const startDate = moment(selectedDateTypes.startDate);
      const options = Array.from({ length: 15 }, (_, index) => {
        const start = startDate.clone().add(index * periodLength, "days");
        const end = start.clone().add(periodLength - 1, "days");
        return {
          value: `${start.format("YYYY-MM-DD")} - ${end.format("YYYY-MM-DD")}`,
          label: `${start.format("Do MMM YYYY")} - ${end.format(
            "Do MMM YYYY"
          )}`,
        };
      });
      const updatedOptions = options.slice(1);

      setDateUptoOptions(updatedOptions);
    }
  }, [selectedDateTypes, activeButton]);
  useEffect(() => {
    if (selectedDateTypes?.startDate && activeButton === "advanced") {
      const periodLength = selectedDateTypes.view === "weekly" ? 7 : 14;
      const startDate = moment(selectedDateTypes.startDate);
      const options = Array.from({ length: 14 }, (_, index) => {
        const start = startDate.clone().subtract(index * periodLength, "days");
        const end = start.clone().add(periodLength - 1, "days");
        return {
          value: `${start.format("YYYY-MM-DD")} - ${end.format("YYYY-MM-DD")}`,
          label: `${start.format("Do MMM YYYY")} - ${end.format(
            "Do MMM YYYY"
          )}`,
        };
      });

      setDateOptions(options);
    }
  }, [selectedDateTypes, activeButton]);

  const generateDateObjects = (dateRanges) => {
    return dateRanges.map((dateRange) => {
      const [startDate, endDate] = dateRange.split(" - ");
      return {
        start_date: moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        end_date: moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
      };
    });
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    if (buttonName !== "advanced") {
      setFromShift([]);
      setToShift([]);
    }
  };

  useEffect(() => {
    if (selectedFromShift && selectedFromShift.value) {
      const dates = selectedFromShift.value.split(" ");
      const startDate = moment(dates[0]);
      const endDate = moment(dates[2]);
      setCurrentDates(startDate, endDate);
    }
    if (selectedToShift) {
      setNextForthnights(generateDateObjects(selectedToShift));
    }

    if (selectedFromShift?.value && selectedToShift?.length > 0) {
      debouncedHandleCheckOverlapping(
        currentStart,
        currentEnd,
        generateDateObjects(selectedToShift)
      );
    }
  }, [selectedFromShift, selectedToShift]);

  const setCurrentDates = (startDate, endDate) => {
    setCurrentStart(startDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
    setCurrentEnd(endDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
  };

  const handleDatesNextForthnightly = () => {
    if (selectedDateTypes) {
      const selectedStartDate = moment(selectedDateTypes?.startDate);
      const periodLength = selectedDateTypes.view === "weekly" ? 6 : 13;

      const endDateCurrent = selectedStartDate
        .clone()
        .add(periodLength, "days");
      const nextStartDate = endDateCurrent.clone().add(1, "day");
      const endDateNext = nextStartDate.clone().add(periodLength, "days");

      setCurrentDates(selectedStartDate, endDateCurrent);
      setSingleForthnight([
        {
          start_date: nextStartDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          end_date: endDateNext.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        },
      ]);
      handleCheckOverlapping(
        selectedStartDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        endDateCurrent.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
        [
          {
            start_date: nextStartDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            end_date: endDateNext.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
          },
        ]
      );
    }
  };

  const handleCheckOverlapping = (currentStart, currentEnd, till_dates) => {
    setLoadingForOverlapping(true);
    const payload = {
      start_date: currentStart,
      end_date: currentEnd,
      till_dates,
    };

    apiPostRequest(URL_ROSTERING_COPY_FORTHNIGHTLY_OVERLAPPING_CHECK, payload)
      .then((response) => {
        if (response && response.data) {
          setLoadingForOverlapping(false);
          setOverlappingData(response?.data);
        }
      })
      .catch((error) => {
        setLoadingForOverlapping(false);
        console.error("Error occurred:", error);
      });
  };

  const handleSubmit = async () => {
    if (isUserGracePeriodOver) {
      return;
    }
    setLoading(true);

    const payload = {
      start_date: currentStart,
      end_date: currentEnd,
      allow_overlap: checked,
      till_dates:
        activeButton !== "advanced" ? forSingleNextFortnight : nextForthnights,
    };

    try {
      const response = await apiPostRequest(
        URL_ROSTERING_FORTNIGHTLY_COPY_SHIFT,
        payload
      );
      if (response && response.data) {
        setForthnightlyCopyShiftsMessage(response?.data);
        setCopyShifts(false);
        setIsMessage(true);
        setLoading(false);
        recurringMessageModal(true);
      }
    } catch (error) {
      setLoading(false);

      if (!toast.isActive(toastId)) {
        toast.error("No Shift(s) found", { toastId });
      }
      console.error("Error occurred:", error);
    }
  };

  const debouncedHandleCheckOverlapping = useCallback(
    debounce((start, end, dates) => {
      handleCheckOverlapping(start, end, dates);
    }, DEBOUNCE_DELAY),
    []
  );

  const getToolTipContent = () => {
    return (
      <div className='flex flex-col gap-4 p-2'>
        If you enable this option, duplicate shifts will be created.
      </div>
    );
  };
 
  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <Dialog as='div' className='relative z-[999]' onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel
                style={{
                  minWidth: "450px",
                  maxWidth: "450px",
                  overflowY: "auto",
                }}
                className={`relative py-2 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all `}
              >
                <div className='p-2 bg-white'>
                  <div className='w-full flex justify-end'>
                    <IoClose
                      className='rounded-full  w-6 h-6 text-[#545454] bg-[#F3F3F3] cursor-pointer'
                      onClick={() => setCopyShifts(false)}
                    />
                  </div>
                  <div className='py-4 px-4 flex flex-col gap-y-4'>
                    <label className='text-[#000] font-bold text-xl'>
                      Copy Shift
                    </label>
                    <p className='text-md font-bold text-[#000] '>Dates</p>
                    <div>
                      <CustomButton
                        variant={null}
                        isDisabled={loadingForOverlapping}
                        cssClass={`cursor-pointer w-full border relative ${
                          activeButton === "nextWeek"
                            ? "bg-[#516CB8] text-white "
                            : "bg-[#ffffff] text-[#7D7D7D] border-[#E4E4E4]"
                        }  gap-3  flex items-center justify-center p-2 text-base  rounded-lg`}
                        label={
                          <>
                            Copy to next{" "}
                            {selectedDateTypes?.view === "weekly"
                              ? "week"
                              : "fortnight"}
                            <div
                              className={`mx-2 h-6 w-6 rounded-full flex items-center cursor-pointer justify-center border ${
                                activeButton === "nextWeek" ? "bg-white" : ""
                              } `}
                            >
                              {activeButton === "nextWeek" && (
                                <FaCheck className='bg-white text-[#516cb8]' />
                              )}
                            </div>
                          </>
                        }
                        clickHandler={() => {
                          handleButtonClick("nextWeek");
                          handleDatesNextForthnightly();
                        }}
                      />
                    </div>
                    <div>
                      <CustomButton
                        variant={null}
                        isDisabled={loadingForOverlapping}
                        cssClass={`cursor-pointer w-full border ${
                          activeButton === "advanced"
                            ? "bg-[#516CB8] text-white "
                            : "bg-[#ffffff] text-[#7D7D7D] border-[#E4E4E4]"
                        }  gap-3  flex items-center justify-center p-2 text-base  rounded-lg`}
                        label={
                          <>
                            More Options
                            {activeButton === "advanced" ? (
                              <IoIosArrowDown className='ml-2 font-bold' />
                            ) : (
                              <IoIosArrowForward className='ml-2 font-bold' />
                            )}
                          </>
                        }
                        clickHandler={() => handleButtonClick("advanced")}
                      />
                    </div>
                  </div>
                </div>

                {activeButton === "advanced" && (
                  <div className='p-6 bg-[#F5F5F5]'>
                    <div>
                      <p className='text-md font-bold text-[#000] '>
                        Copy shifts from
                      </p>

                      <div className='mt-2'>
                        <SelectOption
                          cssClass=''
                          isSearch={false}
                          data={dateOptions}
                          selectedOption={selectedFromShift}
                          handleChange={(e) => setFromShift(e)}
                        />
                      </div>
                    </div>
                    <div className='mt-4'>
                      <p className='text-md font-bold text-[#000] '>
                        Copy shifts to
                      </p>

                      <div className='mt-2'>
                        <MultipleSelection
                          isSearch={false}
                          data={dateUptoOptions}
                          isMulti
                          selectedOption={selectedToShift}
                          handleChange={(e) => setToShift(e)}
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className='space-y-4'>
                  {loadingForOverlapping ? (
                    <div className='w-full flex justify-center py-8'>
                      <Loader
                        cssClass='ml-2'
                        isScheduleScreen
                        isSmallView
                        aria-label='Checking overlaps...'
                      />
                    </div>
                  ) : overlappingData &&
                    Object.entries(overlappingData)?.length === 0 ? (
                    <div className='flex justify-center px-4 py-3  bg-gray-50 rounded-lg transition-colors hover:bg-gray-100'>
                      <p className='text-sm font-medium text-gray-700'>
                        Currently, there are no shifts available for copying
                      </p>
                    </div>
                  ) : overlappingData?.error ? (
                    <div className='space-y-4'>
                      {/* Warning Card */}
                      <div
                        className={`flex items-start mx-6  p-4 ${
                          overlappingData?.error?.count > 0
                            ? "bg-amber-50 border-x-4 border-amber-400"
                            : "bg-green-50 border-x-4 border-green-400"
                        }  rounded-lg shadow-sm`}
                      >
                        {overlappingData?.error?.count > 0 ? (
                          <IoWarningOutline className='flex-shrink-0 text-amber-500 mt-1 mr-3 h-6 w-6' />
                        ) : (
                          <AiOutlineSafetyCertificate className='flex-shrink-0 text-green-500 mt-1 mr-3 h-6 w-6' />
                        )}

                        <div className='space-y-2'>
                          <h3 className='text-sm font-semibold text-gray-900'>
                            Total copying {overlappingData?.total_shifts} shifts
                          </h3>
                          <p className='text-sm text-red-500 leading-snug'>
                            {overlappingData?.error?.count > 0 ? (
                              overlappingData?.error?.messages[0]
                            ) : (
                              <span className='text-green-500 text-sm'>
                                No duplicating shifts found
                              </span>
                            )}
                          </p>
                        </div>
                      </div>

                      {overlappingData?.error?.count > 0 && (
                        <div className='px-4 py-3 bg-gray-50 rounded-lg transition-colors hover:bg-gray-100'>
                          <div className='flex items-center justify-between'>
                            <div className='flex items-center space-x-2'>
                              <span className='text-sm font-medium text-gray-700'>
                                Do you want to copy duplicate shifts?
                              </span>
                              <FaQuestionCircle
                                className='h-4 w-4 text-gray-400 hover:text-gray-500 cursor-pointer transition-colors'
                                data-tip
                                data-tooltip-id='manualAcceptHelp'
                                aria-label='Overlapping shifts help'
                              />
                            </div>

                            <Tooltip
                              id='manualAcceptHelp'
                              place='top'
                              content={getToolTipContent()}
                              className='max-w-xs text-sm shadow-lg'
                              delayShow={300}
                            />

                            <SwitchWithIcon
                              checked={checked}
                              onChange={(e) => setCheck(e)}
                              className='ml-2'
                              aria-label='Toggle overlapping shifts permission'
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                </div>

                <div className='w-full px-6 mt-2 flex justify-end'>
                  <CustomButton
                    variant={null}
                    cssClass={`cursor-pointer  border  gap-3  flex items-center justify-center p-2 text-base  rounded-lg bg-[#516CB8] text-white`}
                    label={loading ? "loading..." : "Apply"}
                    clickHandler={() => handleSubmit()}
                    isDisabled={
                      (activeButton === "nextWeek"
                        ? forSingleNextFortnight.length === 0
                        : nextForthnights.length === 0) ||
                      isUserGracePeriodOver ||
                      loadingForOverlapping ||
                      loading
                    }
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CopyShift;
