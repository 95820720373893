import React, { useState } from "react";
import { BiPencil } from "react-icons/bi";
import {
  formatDateIntoDMYToDisplay,
  gethoursAndMins,
  textTruncate,
} from "Utils/utils";
import { DELIVERED_SERVICE_STATUS_OPTIONS, USER_ROLE } from "../../../../../Constants/constant";
import { HiOutlineTrash } from "react-icons/hi";
import { apiDeleteRequest } from "helpers/Requests";
import DeleteCaseNote from "./DeleteCaseNote";
import { roleAccess } from "helpers/RolesPermission";

const CaseNotess = (props) => {
  const {
    selectedFunding,
    caseNotesList,
    getCaseNoteDetailRequest,
    setOpenUpdateCaseNoteModal,
    
    cosParticipantsProfile,
    setCaseNotesList,
    isUserGracePeriodOver,
    uuid,
    setIsRefreshCaseNotes,
    setRefreshTable,
    refreshTableAfterDelete
  } = props;
const [openDeleteModal, setOpenDeleteModal] = useState(false);
const [selectedUUID,setSelectedUUID] = useState(null)
  return (
    <>
    <table className='min-w-full table-fixed divide-y divide-gray-300 bg-white px-8'>
      <thead>
        <tr className='bg-blue-50 capitalize'>
          <th
            scope='col'
            className='min-w-[4rem] pl-5 px-3 py-3.5 text-left text-xs text-gray-500'
          >
            Subject
          </th>
          {/* <th
            scope="col"
            className="min-w-[4rem] pl-5 px-3 py-3.5 text-left text-xs text-gray-500 w-[25%]"
          >
            Support Item
          </th> */}
          <th
            scope='col'
            className='px-3 py-3.5 text-left text-xs text-gray-500'
          >
            Billable
          </th>
          <th
            scope='col'
            className='px-3 py-3.5 text-left text-xs text-gray-500'
          >
            Contact Type
          </th>
          <th
            scope='col'
            className='px-3 py-3.5 text-left text-xs text-gray-500'
          >
            Status
          </th>
          <th
            scope='col'
            className='px-3 py-3.5 text-left text-xs text-gray-500'
          >
            Duration
          </th>
          <th
            scope='col'
            className='px-3 py-3.5 text-left text-xs text-gray-500'
          >
            Km
          </th>
          <th
            scope='col'
            className='px-3 py-3.5 text-left text-xs text-gray-500'
          >
            Created Date
          </th>
          <th
            scope='col'
            className='px-3 py-3.5 text-left text-xs text-gray-500'
          >
            Delivered Date
          </th>
          <th
            scope='col'
            className='px-3 py-3.5 text-left text-xs text-gray-500'
          >
            Service Provider
          </th>
          <th
            scope='col'
            className='px-3 py-3.5 text-left text-xs text-gray-500 '
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody className='divide-y divide-gray-300'>
        {caseNotesList &&
          caseNotesList.map((item, index) => {
            return (
              <tr key={index} className='even:bg-white odd:bg-gray-50 '>
                <td
                  title={item?.subject}
                  className='min-w-[4rem] pl-5 px-3 py-3.5 text-left text-[#101828] font-medium'
                >
                  {textTruncate(item?.subject, 35, 34)}
                </td>

                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                  {item?.bill_types === "billable_hours" || item?.bill_types === "true"
                    ? "Billable Hours"
                    : "Non Billable Hours"}
                </td>
                <td
                  title={item?.cos_contact_type?.title}
                  className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize'
                >
                  {textTruncate(item?.cos_contact_type?.title, 16, 15)}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                  {item?.status === null
                    ? DELIVERED_SERVICE_STATUS_OPTIONS.find((arr) => {
                        return item?.invoice_status === arr.value;
                      }).label
                    : DELIVERED_SERVICE_STATUS_OPTIONS.find((arr) => {
                        return item?.status === arr.value;
                      }).label}
                </td>

                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                  {gethoursAndMins(item?.hours, item?.mints)}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                  {item?.km || 0}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                  {formatDateIntoDMYToDisplay(item?.created_at) || "-"}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                  {formatDateIntoDMYToDisplay(item?.delivered_date) || "-"}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize'>
                  {item?.external_sp?.name}
                </td>
                <td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium'>
                  <div className='flex items-center justify-start gap-x-3'>
                    <div
                      className='icon-pencil-wrapper cursor-pointer'
                      title='Edit'
                      onClick={() => {
                        setOpenUpdateCaseNoteModal(true);
                        if (cosParticipantsProfile) {
                          getCaseNoteDetailRequest({
                            uuid: item.uuid,
                            pt_id: cosParticipantsProfile?.uuid,
                          });
                        }
                      }}
                    >
                      <BiPencil className='icon-size' />
                      <span className='sr-only'>{item.uuid}</span>
                    </div>
                    {
                       roleAccess([USER_ROLE.Sp_Admin]) &&  <div
                      className='icon-delete-wrapper cursor-pointer'
                      title='Delete'
                      onClick={() => {
                        setOpenDeleteModal(true);
                        setSelectedUUID(item?.uuid)
                        // if (cosParticipantsProfile) {
                        //   getCaseNoteDetailRequest({
                        //     uuid: item.uuid,
                        //     pt_id: cosParticipantsProfile?.uuid,
                        //   });
                        // }
                      }}
                    >
                      <HiOutlineTrash className='icon-size' />
                      <span className='sr-only'>{item.uuid}</span>
                    </div>
                    }
                   
                  </div>
                </td>
              </tr>
            );
          })}
        {caseNotesList && caseNotesList.length === 0 && (
          <tr>
            <td colSpan={7} className='py-6 text-center text-red-600'>
              No data found !
            </td>
          </tr>
        )}
        {/* {openDeleteModal && (
          <DeleteCaseNote
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            isUserGracePeriodOver={isUserGracePeriodOver}
            uuid={uuid}
            //selectedServiceProvider={selectedServiceProvider}
            handleClose={() => setOpenDeleteModal(false)}
            // refreshServiceProviderList={refreshServiceProviderList}
          />
        )} */}
      </tbody>
    </table>

  {openDeleteModal && (
        <DeleteCaseNote
        selectedUUID={selectedUUID}
        closeModal={() => setOpenDeleteModal(false)}
        openDeleteModal={openDeleteModal}
        setRefreshTable={setRefreshTable}
        refreshTableAfterDelete={refreshTableAfterDelete}
       
        />
      )}
    </>
  );
};
export default CaseNotess;
