import React from "react";
import {
  formatDateIntoDMYToDisplay,
  gethoursAndMins,
  textTruncate,
} from "Utils/utils";
import CheckBox from "../../../../components/common/checkBox/checkbox";

const NdisClaimNewTableItem = (props) => {
  const { item, checkboxes, handleCheckboxChange, isUserGracePeriodOver } =
    props;
  return (
    <tr className='text-center even:bg-white odd:bg-gray-50'>
      <td className=' pl-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          <CheckBox
            isDisabled={isUserGracePeriodOver}
            handleChange={handleCheckboxChange}
            checked={checkboxes.indexOf(item.id) !== -1}
          />
        </div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{item.delivered_service_number}</div>
      </td>
      <td className='text-left  px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{item.support_item_name}</div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{item.support_item_number}</div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500 capitalize'>
          {textTruncate(item.created_by, 30, 29)}
        </div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500'>
        {formatDateIntoDMYToDisplay(item.created_at)}
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500 capitalize'>{item?.participant}</div>
      </td>
      <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{"NDIA Managed"}</div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {item.delivered_from && item.delivered_to_date
            ? `${formatDateIntoDMYToDisplay(
                item.delivered_from
              )} - ${formatDateIntoDMYToDisplay(item.delivered_to_date)}`
            : item.delivered_date
            ? formatDateIntoDMYToDisplay(item.delivered_date)
            : "-"}
        </div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {gethoursAndMins(item?.hours, item?.mints)}
        </div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500'>
      <div className='text-gray-500'>{item.km || 0}</div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {`$${
            item.alternative_rate && parseInt(item.alternative_rate) > 0
              ? item.alternative_rate
              : item.original_rate
          }`}
        </div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>{`$${item.total_amount || 0}`}</div>
      </td>
      <td className=' px-3 py-4 text-sm text-gray-500'>
        <div className='text-gray-500'>
          {item.status === "ready_for_extraction" && "Ready For Extraction"}
        </div>
      </td>
    </tr>
  );
};

export default NdisClaimNewTableItem;
