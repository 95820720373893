import React, { useContext, useEffect, useState } from "react";
import CustomButton from "components/common/Button/CustomButton";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import { BiDownload } from "react-icons/bi";
import { LoginContext } from "helpers/LoginContext";

const CaseNoteReportModal = ({
  selectedFunding,
  reportLoading,
  openReportModal,
  fundingId,
  getCatogeryListRequest,
  caseNotesCreateLoading,
  closeModal,
  getReportRequest,
  uuid,
}) => {
  const { isUserGracePeriodOver } = useContext(LoginContext);

  useEffect(() => {
    getCatogeryListRequest();
  }, []);

  const [billTypes, setBillTypes] = useState(null);
  const [filtType, setFileType] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const onSubmit = (date) => {
    if (isUserGracePeriodOver) return;

    if (filtType && fromDate && toDate && billTypes) {
     
      getReportRequest({
        uuid: selectedFunding.uuid,
        from_date: fromDate,
        to_date: toDate,
        pt_id: uuid,
        file_type: filtType ,
        bill_types: billTypes,
      });
    }
  };

  const modalFooter = () => (
    <>
      <CustomButton
        isDisabled={
          !billTypes ||
          !filtType ||
          !fromDate ||
          !toDate ||
          reportLoading ||
          isUserGracePeriodOver
        }
        type='submit'
        showLoading={reportLoading}
        label='Download Report'
        Icon={<BiDownload />}
        clickHandler={onSubmit}
      />
    </>
  );

  const handleCancelButton = () => {
    setFromDate(null);
    setToDate(null);
  };

  return (
    <ModalSkeleton
      cssClass='w-[50%]'
      title='Generate Report'
      modalFooter={modalFooter()}
      isOpen={openReportModal}
      closeModal={closeModal}
    >
      <div className='grid grid-cols-2 px-4 space-x-4'>
        <div className='col-span-1'>
          <label
            htmlFor='from-date-to-to-date'
            className='block text-sm font-medium text-gray-700'
          >
            From Date - To Date
          </label>
          <DateRangePickerNew
            showCalendar={true}
            handleCancel={handleCancelButton}
            handleChange={(obj) => {
              if (obj && obj.fromDate && obj.toDate) {
                setFromDate(obj.fromDate);
                setToDate(obj.toDate);
              }
            }}
            fromDate={fromDate}
            toDate={toDate}
          />
          <span
            className='bg-blue-200 rounded text-sm p-1'
            role='button'
            tabIndex={0}
            onClick={handleCancelButton}
          >
            Clear Date
          </span>
        </div>
        <div className='col-span-1'>
          <div>
            <label htmlFor='' className='did-floating-label text-start'>
              Select Hours Type
            </label>
            <div className='flex items-center space-x-2'>
              <input
                required={true}
                onClick={() => setBillTypes("all")}
                type='radio'
                id='bill_types_all_hours'
                name='bill_types'
                value='all'
              />
              <label htmlFor='bill_types_all_hours'>All Hours</label>
            </div>
            <div className='flex items-center space-x-2'>
              <input
                required={true}
                type='radio'
                id='bill_types_billable_hours'
                onClick={() => setBillTypes("billable_hours")}
                name='bill_types'
                value='billable_hours'
              />
              <label htmlFor='bill_types_billable_hours'>Billable Hours</label>
            </div>
            <div className='flex items-center space-x-2'>
              <input
                required={true}
                type='radio'
                onClick={() => setBillTypes("non_billable_hour")}
                id='bill_types_non_billable_hour'
                name='bill_types'
                value='non_billable_hour'
              />
              <label htmlFor='bill_types_non_billable_hour'>
                Non-Billable Hours
              </label>
            </div>
          </div>
          <div>
            <label htmlFor='' className='did-floating-label text-start'>
              Select File Type
            </label>
            <div className='flex items-center space-x-2'>
              <input
                required={true}
                onClick={() => setFileType("pdf")}
                type='radio'
                id='fileTypepdf'
                name='fileType'
                value='pdf'
              />
              <label htmlFor='fileTypepdf'>PDF</label>
            </div>
            <div className='flex items-center space-x-2'>
              <input
                required={true}
                type='radio'
                id='fileTypeexcel'
                onClick={() => setFileType("xlsx")}
                name='fileType'
                value='excel'
              />
              <label htmlFor='fileTypeexcel'>Excel</label>
            </div>
          </div>
        </div>
      </div>
    </ModalSkeleton>
  );
};
export default CaseNoteReportModal;
