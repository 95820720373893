/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import CustomButton from "components/common/Button/CustomButton";
import {
  calculateDurationStartAndEndTime,
  calculateTotalDuration,
  classNames,
  convertTimeToDatetimeLocal,
  convertTimeToDatetimeLocalWithoutMoment,
  convertTo12HourFormat,
  customFormatDate,
  embedDateWithTime,
  formatDateForApprovedTimesheet,
  getHrAndMinsFormatting,
  getNameProfile,
  getShiftLength,
  isCheckNextDayShift,
  parseBooleanVal,
  validateCurrentDate,
} from "Utils/utils";
import InputField from "components/common/Input/Input";
import SelectOption from "components/common/Select/Select";
import {
  CATEGORY_ITEM_NAME_AND_NOTES,
  SHIFT_TIME_OPTIONS,
  TIMESHEET_STATUS,
  USER_ROLE,
} from "Constants/constant";
import clockSvg from "assets/svg/clock-black.svg";
import deleteSvg from "assets/svg/delete-red.svg";
import { roleAccess } from "helpers/RolesPermission";
import Loader from "components/common/Loader/Loader";
import MapComponent from "../MapComponent";
import { useSelector } from "react-redux";
import { getUser } from "store/selector/auth.selector";
import AvatarGroup from "components/common/Avatar/AvatarGroup";
import { LoginContext } from "helpers/LoginContext";
import { apiGetRequest, apiPostRequest } from "helpers/Requests";
import {
  URL_ROSTERING_INVOICING_GET_ITEM_NUMBER_ON_TIMESHEET,
  URL_ROSTERING_INVOICING_GET_TRAVEL_DETAILS,
  URL_ROSTERING_TIMESHEET_CHECK_OVERLAPPING,
  URL_ROSTER_INVOICING_TIMESHEET_BREAKDOWN,
} from "store/services/URL";
import SwitchWithIcon from "components/common/SwitchWithIcon";
import TimesheetBreakdown from "./TimesheetBreakdown/TimesheetBreakdown";
import swal from "sweetalert";
import { FaExclamationCircle } from "react-icons/fa";
import { useNavigate } from "react-router";
import { IoWarningOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import TimeInput from "react-time-picker-input";
import { useScrollToTop } from "hooks/useScrollTop";

const TimesheetContent = (props) => {
  const {
    currentSpUser,
    selectedTimeSheet,
    selectedParticipant,
    timeSheetDataUpdateRequest,
    updateTimesheetLoader,
    timeSheetStatusChangeRequest,
    timesheetStatusChangeLoader,
    catalogStates,
    serviceTypes,
    isBreakdownTimesheet = false,
    spRosteringSetting,
    handleScrollTop,
  } = props;
  const { isUserGracePeriodOver } = useContext(LoginContext);
  const scrollToTop = useScrollToTop();

  const mainDivRef = useRef(null);

  useEffect(() => {
    if (scrollToTop) {
      scrollToTop();
    }
    if (mainDivRef.current) {
      mainDivRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [selectedTimeSheet]);

  const [itemNumber, setItemNumber] = useState([]);

  const [suggestedItemNumber, setSuggestedItemNumber] = useState(null);
  const [timesheetBreakdown, setTimesheetBreakdown] = useState(null);
  const [startTimeValue, setStartTime] = useState(null);
  const [endTimeValue, setEndTime] = useState(null);
  const [workerStartTimeValue, setWorkerStartTime] = useState(null);
  const [workerEndTimeValue, setWorkerEndTime] = useState(null);
  const [catalogState, setCatalogState] = useState(null);
  const [serviceCategory, setServiceCategory] = useState(null);
  const [startTimeChanged, setStartTimeChanged] = useState(false);
  const [endTimeChanged, setEndTimeChanged] = useState(false);
  const [ndisItemName, setNDISItemName] = useState(null);
  const [categoryItemName, setCategoryItemName] = useState(null);
  const [actualDate, setActualDate] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
    control,
    clearErrors,
    setError,
  } = useForm();
  const watchFormData = watch();

  const currentUser = useSelector(getUser);
  const [loading, setLoading] = useState(false);
  const [traveldata, setTravelData] = useState(null);
  const [travelNdisNum, setTravelNdisNum] = useState(null);
  const [notes, setNotes] = useState(selectedTimeSheet?.shift_notes);
  const [selectedShiftNotes, setSelectedShiftNotes] = useState([]);
  const navigate = useNavigate();

  const schedule_time =
    selectedTimeSheet?.time_display_preference === "scheduled" ? true : false;

  useEffect(() => {
    if (selectedTimeSheet.state_id && catalogStates) {
      const stateObj = catalogStates.find(
        (x) => x.value === selectedTimeSheet?.state_id
      );

      setCatalogState({ ...stateObj });
    } else if (
      selectedTimeSheet &&
      !selectedTimeSheet?.state_id &&
      selectedTimeSheet.participants?.length > 0
    ) {
      const firstParticipantStateId = selectedTimeSheet?.participants?.find(
        (item) => item?.state_id
      )?.state_id;

      const firstMatchingState = catalogStates?.find(
        (state) => state?.value === firstParticipantStateId
      );

      setCatalogState({ ...firstMatchingState });
    }
    let categoryItemNameObj;

    if (selectedTimeSheet?.invoice_category_name) {
      categoryItemNameObj = CATEGORY_ITEM_NAME_AND_NOTES?.find(
        (x) => x.value === selectedTimeSheet?.invoice_category_name
      );

      setCategoryItemName(categoryItemNameObj);
    } else if (
      selectedTimeSheet &&
      !selectedTimeSheet?.invoice_category_name &&
      selectedTimeSheet.participants?.length > 0
    ) {
      const categoryItemNameObj = CATEGORY_ITEM_NAME_AND_NOTES?.find(
        (categoryItem) =>
          selectedTimeSheet?.participants?.some(
            (participant) =>
              participant.roster_invoicing_item === categoryItem.value
          )
      );
      setCategoryItemName(categoryItemNameObj);
    }
    if (selectedTimeSheet && selectedTimeSheet?.invoice_service_type_id) {
      const value = serviceTypes?.find(
        (service) =>
          service?.value === selectedTimeSheet?.invoice_service_type_id
      );
      setServiceCategory(value);
    }
    if (
      selectedTimeSheet?.invoice_category_name &&
      CATEGORY_ITEM_NAME_AND_NOTES
    ) {
      const Obj = CATEGORY_ITEM_NAME_AND_NOTES?.find(
        (x) => x.value === selectedTimeSheet?.invoice_category_name
      );
      setCategoryItemName({ ...Obj });
    } else if (
      selectedTimeSheet &&
      !selectedTimeSheet?.invoice_category_name &&
      selectedTimeSheet.participants?.length > 0
    ) {
      const firstParticipantStateId = selectedTimeSheet?.participants?.find(
        (item) => item?.roster_invoicing_item
      )?.roster_invoicing_item;

      const firstMatchingState = CATEGORY_ITEM_NAME_AND_NOTES?.find(
        (state) => state?.value === firstParticipantStateId
      );
      setCategoryItemName({ ...firstMatchingState });
    }
  }, [selectedTimeSheet, catalogStates, serviceTypes]);

  useEffect(() => {
    if (startTimeValue && startTimeValue !== null && startTimeValue?.value) {
      setValue("start_time", startTimeValue?.value);
      clearErrors("start_time");
    }
  }, [startTimeValue?.value]);

  useEffect(() => {
    if (endTimeValue && endTimeValue !== null && endTimeValue?.value) {
      setValue("end_time", endTimeValue?.value);
      clearErrors("end_time");
    }
  }, [endTimeValue?.value]);

  useEffect(() => {
    if (catalogState && catalogState !== null && catalogState.value) {
      setValue("state_id", catalogState.value);
      clearErrors("state_id");
    }
  }, [catalogState]);

  useEffect(() => {
    if (ndisItemName && ndisItemName !== null && ndisItemName.value) {
      setValue("invoice_item_id", ndisItemName.value);
      if (parseBooleanVal(spRosteringSetting?.apply_alternate_rate)) {
        setValue("invoice_ndis_rate", "alternate");
        setValue(
          "rate_amount",
          spRosteringSetting?.default_alternate_rate || 0
        );
      } else {
        if (ndisItemName?.price) {
          setValue("invoice_ndis_rate", ndisItemName.price);
        }
      }
      clearErrors("invoice_item_id");
    }
  }, [ndisItemName]);

  useEffect(() => {
    if (
      categoryItemName &&
      categoryItemName !== null &&
      categoryItemName?.value
    ) {
      setValue("roster_invoicing_item", categoryItemName?.value);
      clearErrors("roster_invoicing_item");
    }
  }, [categoryItemName]);

  const handleNoteChange = (index, value) => {
    const updatedNotes = [...notes];
    updatedNotes[index].notes = value;
    setNotes(updatedNotes);
  };

  const preLoadTimesheetData = (selectedTimeSheet) => {
    setValue(
      "time_sheet_date",
      moment(selectedTimeSheet.time_sheet_date, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      )
    );

    setValue("incident", selectedTimeSheet.incident);
    setValue("comment", selectedTimeSheet.comment);
    setValue("km", selectedTimeSheet.km);
    setValue("applydistance", false);
    setValue("applykm", selectedTimeSheet?.apply_km);
    setValue("attachments", selectedTimeSheet.attachments);
    setValue("cancelled", selectedTimeSheet?.invoice_cancelled);
    setValue("non_billable", selectedTimeSheet?.invoice_non_billable);
    setValue("sleepover", selectedTimeSheet?.sleepover);
    setValue("invoice_breakdown",selectedTimeSheet?.default_timesheet_breakdown)

    if (selectedTimeSheet?.start_time) {
      const value = SHIFT_TIME_OPTIONS?.find(
        (x) =>
          x.value.toLowerCase() ===
          moment(selectedTimeSheet?.start_time, "YYYY-MM-DD HH:mm:ss").format(
            "HH:mm"
          )
      );

      if (value) {
        setStartTime({ ...value });
        setValue("start_time", value?.value);
      }
    }
    if (selectedTimeSheet?.worker_start_time) {
      const value = SHIFT_TIME_OPTIONS?.find(
        (x) =>
          x.value.toLowerCase() ===
          moment(
            selectedTimeSheet?.worker_start_time,
            "YYYY-MM-DD HH:mm:ss"
          ).format("HH:mm")
      );

      if (value) {
        setWorkerStartTime({ ...value });
        setValue("start_time_worker", value?.value);
      }
    }
    if (selectedTimeSheet?.end_time) {
      const value = SHIFT_TIME_OPTIONS?.find(
        (x) =>
          x.value.toLowerCase() ===
          moment(selectedTimeSheet?.end_time, "YYYY-MM-DD HH:mm:ss").format(
            "HH:mm"
          )
      );

      if (value) {
        setEndTime(value);
        setValue("end_time", value?.value);
      }
    }
    if (selectedTimeSheet?.worker_end_time) {
      const value = SHIFT_TIME_OPTIONS?.find(
        (x) =>
          x.value.toLowerCase() ===
          moment(
            selectedTimeSheet?.worker_end_time,
            "YYYY-MM-DD HH:mm:ss"
          ).format("HH:mm")
      );

      if (value) {
        setWorkerEndTime(value);
        setValue("end_time_worker", value?.value);
      }
    }

    if (
      selectedTimeSheet &&
      selectedTimeSheet.shift_notes &&
      selectedTimeSheet.shift_notes.length > 0
    ) {
      setNotes(selectedTimeSheet.shift_notes);
    } else {
      setNotes([]);
    }

    setActualDate(false);
  };

  useEffect(() => {
    if (
      selectedTimeSheet &&
      selectedTimeSheet.status === TIMESHEET_STATUS.PENDING
    ) {
      preLoadTimesheetData(selectedTimeSheet);
    } else {
      reset();
    }
  }, [selectedTimeSheet, reset, setValue]);

  useEffect(() => {
    if (
      selectedTimeSheet.status === TIMESHEET_STATUS.PENDING &&
      selectedTimeSheet?.apply_km
    ) {
      fetchApplyKmDetails();
    }
  }, [selectedTimeSheet?.apply_km, selectedTimeSheet?.status]);

  useEffect(() => {
    if (!parseBooleanVal(watchFormData?.applykm)) {
      setTravelNdisNum(null);
      setTravelData([]);
      setValue("km_item_id", null);
    }
  }, [watchFormData?.applykm]);

  const fetchApplyKmDetails = async () => {
    setLoading(true);
    const url = `${URL_ROSTERING_INVOICING_GET_TRAVEL_DETAILS}/?invoice_service_type_id=${
      serviceCategory?.value || ""
    }&state_id=${watchFormData?.state_id || ""}&uuid=${
      selectedTimeSheet?.uuid || ""
    }`;

    try {
      const res = await apiGetRequest(url);
      if (res.status === 200) {
        const responseData = Array.isArray(res?.data?.data?.items)
          ? res?.data?.data?.items
          : [res?.data?.data?.items];

        setTravelData(
          responseData?.map((x) => ({
            ...x,
            label: x.support_item_number,
            value: x.id,
          }))
        );
        if (responseData?.length > 0) {
          const data = responseData?.find(
            (x) => x.support_item_number === selectedTimeSheet?.default_km_code
          );

          setTravelNdisNum({
            ...data,
            value: data?.id,
            label: data?.support_item_number,
          });
          setValue("km_item_id", data?.id);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching travel detail:", error);
      toast.error("Error Fetching Travel Detail");
    }
  };

  const handleImageError = (e, fname, lname) => {
    return (e.target.src = getNameProfile(fname, lname));
  };

  useEffect(() => {
    const pattern = /^\d*[0-9][.]?\d*$/;
    if (pattern.test(watchFormData?.km)) {
      clearErrors("km");
      setValue(
        "applykm",
        parseBooleanVal(spRosteringSetting?.apply_km_to_invoice)
      );

      if (parseBooleanVal(spRosteringSetting?.apply_km_to_invoice)) {
        fetchApplyKmDetails();
      }
    }
  }, [watchFormData?.km]);

  const onSubmit = (data, event = "") => {
    if (isUserGracePeriodOver) return;
    const invoiceBreakdownData = [];

    if (!startTimeValue?.value || !endTimeValue?.value) {
      toast.error("Start time or End time of shift is missing");
      return;
    }

    const invoicePrice =
      data?.invoice_ndis_rate !== "alternate"
        ? data?.invoice_ndis_rate
        : data?.rate_amount;

    if (watchFormData.invoice_breakdown) {
      for (let i = 0; i < timesheetBreakdown?.length; i++) {
        const invoiceItemId = watchFormData[`invoice_item_id_${i}`];
        const invoiceNdisRate = watchFormData[`invoice_ndis_rate_${i}`];
        const shiftType = timesheetBreakdown[i]?.shift_type;
        const duration = timesheetBreakdown[i]?.duration;
        const start_time = timesheetBreakdown[i]?.start_time;
        const end_time = timesheetBreakdown[i]?.end_time;
        const TimesheetId = timesheetBreakdown[i]?.timesheet_id;
        const sleepover = watchFormData[`sleepover_${i}`];
        const isTimesheetSleepover = watchFormData[`sleepover`];

        const activeHoursDuration = watchFormData[`active_hours_${i}`];

        const activeHoursNDISID =
          watchFormData[`invoice_item_id_active_hours${i}`];

        const activeHoursInvoiceNdisRate =
          watchFormData[`invoice_ndis_rate_active_hours${i}`];

        if (invoiceNdisRate === "alternate") {
          const rateAmount = watchFormData[`rate_amount_${i}`];
          invoiceBreakdownData.push({
            invoice_item_id: invoiceItemId,
            ndis_rate: parseFloat(rateAmount),
            invoice_price: parseFloat(rateAmount),
            shift_type: shiftType,
            duration: isTimesheetSleepover
              ? calculateDurationStartAndEndTime(
                  watchFormData[`start_time_${i}`],
                  watchFormData[`end_time_${i}`]
                )
              : duration,
            start_time: isTimesheetSleepover
              ? embedDateWithTime(watchFormData[`start_time_${i}`], start_time)
              : start_time,
            end_time: isTimesheetSleepover
              ? embedDateWithTime(watchFormData[`end_time_${i}`], end_time)
              : end_time,
            timesheet_id: TimesheetId,
            alternate_rate: true,
            sleepover,
          });
        } else {
          invoiceBreakdownData.push({
            invoice_item_id: invoiceItemId,
            ndis_rate: parseFloat(invoiceNdisRate),
            invoice_price: parseFloat(invoiceNdisRate),
            shift_type: shiftType,
            duration: isTimesheetSleepover
              ? calculateDurationStartAndEndTime(
                  watchFormData[`start_time_${i}`],
                  watchFormData[`end_time_${i}`]
                )
              : duration,

            start_time: isTimesheetSleepover
              ? embedDateWithTime(watchFormData[`start_time_${i}`], start_time)
              : start_time,
            end_time: isTimesheetSleepover
              ? embedDateWithTime(watchFormData[`end_time_${i}`], end_time)
              : end_time,
            timesheet_id: TimesheetId,
            alternate_rate: false,
            sleepover,
          });
        }
        if (
          sleepover &&
          activeHoursNDISID &&
          activeHoursDuration !== "" &&
          activeHoursDuration !== undefined
        ) {
          if (activeHoursInvoiceNdisRate === "alternate") {
            const rateAmountActiveHours =
              watchFormData[`rate_amount_active_hours${i}`];

            invoiceBreakdownData.push({
              invoice_item_id: activeHoursNDISID,
              ndis_rate: parseFloat(rateAmountActiveHours),
              invoice_price: parseFloat(rateAmountActiveHours),
              shift_type: shiftType,
              duration: activeHoursDuration,
              start_time: embedDateWithTime(
                watchFormData[`start_time_${i}`],
                start_time
              ),
              end_time: embedDateWithTime(
                watchFormData[`end_time_${i}`],
                end_time
              ),
              timesheet_id: TimesheetId,
              alternate_rate: true,
            });
          } else {
            invoiceBreakdownData.push({
              invoice_item_id: activeHoursNDISID,
              ndis_rate: parseFloat(activeHoursInvoiceNdisRate),
              invoice_price: parseFloat(activeHoursInvoiceNdisRate),
              shift_type: shiftType,
              duration: activeHoursDuration,
              start_time: embedDateWithTime(
                watchFormData[`start_time_${i}`],
                start_time
              ),
              end_time: embedDateWithTime(
                watchFormData[`end_time_${i}`],
                end_time
              ),
              timesheet_id: TimesheetId,
              alternate_rate: false,
            });
          }
        }
      }
    } else {
      invoiceBreakdownData.push({
        invoice_item_id: data?.invoice_item_id,
        ndis_rate: data?.invoice_ndis_rate,
        invoice_price: invoicePrice,
        // duration: getShiftLength(
        //   workerStartTimeValue?.value,
        //   workerEndTimeValue?.value,
        //   true
        // ),
       duration: schedule_time ?
       getShiftLength(
        startTimeValue?.value,
        endTimeValue?.value,
        true
      ):getShiftLength(
        workerStartTimeValue?.value,
        workerEndTimeValue?.value,
        true
      ),
        start_time: convertTimeToDatetimeLocal(
          moment(watchFormData.time_sheet_date),
          startTimeValue?.value
        ),
        end_time: convertTimeToDatetimeLocal(
          moment(watchFormData.time_sheet_date),
          endTimeValue?.value
        ),
        worker_start_time: convertTimeToDatetimeLocal(
          moment(watchFormData.time_sheet_date),
          workerStartTimeValue?.value
        ),
        worker_end_time: convertTimeToDatetimeLocal(
          moment(watchFormData.time_sheet_date),
          workerEndTimeValue?.value
        ),
        sleepover: data?.sleepover,
      });
    }
    const filteredNotes = notes.filter((noteObj) => noteObj.note !== "");

    let valueOfShiftNotes;
    if (selectedShiftNotes) {
      {
        /*I keep the `id` as `null` for updates to create new shift notes on the backend demand*/
      }
      valueOfShiftNotes = Object.values(selectedShiftNotes)
        .filter(({ id, note }) => id && note)
        .map(({ id, note }) => ({ pt_id: id, notes: note, id: null }));
    }
    const obj = {
      km: data.km,
      apply_km: data?.applykm,
      attachments: data.attachments,
      incident: data.incident,
      notes: filteredNotes?.length > 0 ? filteredNotes : valueOfShiftNotes,
      comment: data.comment,
      invoice_item_id: data?.invoice_item_id,
      invoice_non_billable: data?.non_billable,
      invoice_cancelled: data?.cancelled,
      invoice_ndis_rate: data?.invoice_ndis_rate,
      state_id: data?.state_id,
      sleepover: data?.sleepover,
      invoice_service_type_id: serviceCategory?.value,
      invoice_category_name: categoryItemName?.value,
      // timesheet_length: getShiftLength(
      //   startTimeValue?.value,
      //   endTimeValue?.value,
      //   true
      // ),
      shift_date: moment(new Date(watchFormData.time_sheet_date)).toISOString(),
      start_time: convertTimeToDatetimeLocal(
        moment(watchFormData.time_sheet_date),
        startTimeValue?.value
      ),
      end_time:
        isCheckNextDayShift(
          watchFormData?.time_sheet_date,
          startTimeValue?.value,
          endTimeValue?.value
        ) !== false
          ? isCheckNextDayShift(
              watchFormData?.time_sheet_date,
              startTimeValue?.value,
              endTimeValue?.value
            )
          : convertTimeToDatetimeLocal(
              moment(watchFormData.time_sheet_date),
              endTimeValue?.value
            ),
      worker_start_time: convertTimeToDatetimeLocal(
        moment(watchFormData.time_sheet_date),
        workerStartTimeValue?.value
      ),
      worker_end_time:
        isCheckNextDayShift(
          watchFormData?.time_sheet_date,
          workerStartTimeValue?.value,
          workerEndTimeValue?.value
        ) !== false
          ? isCheckNextDayShift(
              watchFormData?.time_sheet_date,
              workerStartTimeValue?.value,
              workerEndTimeValue?.value
            )
          : convertTimeToDatetimeLocal(
              moment(watchFormData.time_sheet_date),
              workerEndTimeValue?.value
            ),
      timesheet_length: schedule_time
        ? getShiftLength(startTimeValue?.value, endTimeValue?.value, true)
        : getShiftLength(
            workerStartTimeValue?.value,
            workerEndTimeValue?.value,
            true
          ),
    };

    if (parseBooleanVal(spRosteringSetting?.timesheet_invoices)) {
      Object.assign(obj, {
        invoice_timesheets: invoiceBreakdownData,
      });
      if (watchFormData?.invoice_breakdown) {
        obj.invoice_timesheets = invoiceBreakdownData;
        obj.invoice_breakdown = watchFormData?.invoice_breakdown;
      }
      Object.assign(obj, {
        invoice_price: invoicePrice,
      });
    }

    if (
      roleAccess([USER_ROLE.Sp_Admin]) &&
      currentSpUser &&
      currentSpUser?.id
    ) {
      Object.assign(obj, {
        updated_by_user: currentSpUser.id,
      });
    }

    if (roleAccess([USER_ROLE.Sp_manager]) && currentUser) {
      Object.assign(obj, {
        updated_by_user:
          currentUser?.sp_id || currentUser?.service_provider?.id,
      });
    }

    if (selectedParticipant && selectedParticipant?.id) {
      Object.assign(obj, {
        pt_id: selectedParticipant.id,
      });
    }
    if (selectedTimeSheet && selectedTimeSheet?.worker_id) {
      Object.assign(obj, {
        worker_id: selectedTimeSheet.worker_id,
      });
    }

    if (watchFormData?.km_item_id) {
      Object.assign(obj, {
        km_item_id: watchFormData?.km_item_id,
      });
    }

    timeSheetDataUpdateRequest(
      {
        data: obj,
        uuids: {
          timeSheetUuid: selectedTimeSheet.uuid,
          parent_uuid: selectedTimeSheet.parent_uuid,
        },
      },
      event === "approve-and-next",
      (isNext = false) => {
        handleScrollTop();
        if (isNext) {
          reset();
          // preLoadTimesheetData(selectedTimeSheet);
        }
      }
    );
  };

  const isEndTimeNextDay = (date, startTime, endTime, end_date = "") => {
    if (!date || !startTime || !endTime) return;

    if (
      date !== "" &&
      end_date !== "" &&
      !moment(date).isSame(moment(end_date), "day")
    ) {
      return true;
    }

    const modifiedDate = date?.split(" ");

    const startDateTime = moment(
      `${modifiedDate[0]} ${startTime}`,
      "YYYY-MM-DD HH:mm"
    );
    const endDateTime = moment(
      `${modifiedDate[0]} ${endTime}`,
      "YYYY-MM-DD HH:mm"
    );
    if (
      endDateTime.isBefore(startDateTime) ||
      (endDateTime.isSame(startDateTime, "day") &&
        endDateTime.isBefore(startDateTime, "hour"))
    ) {
      return true;
    }

    return false;
  };

  const isWorkerEndTimeNextDay = (startTime, endTime) => {
    if (!startTime || !endTime) return null;

    const startDateTime = moment(startTime, "YYYY-MM-DD HH:mm:ss");
    const endDateTime = moment(endTime, "YYYY-MM-DD HH:mm:ss");

    return endDateTime.isBefore(startDateTime);
  };

  const startTime = useMemo(() => {
    const start = schedule_time
      ? selectedTimeSheet?.start_time
      : selectedTimeSheet?.worker_start_time;
    const startValue = schedule_time
      ? startTimeValue?.value
      : workerStartTimeValue?.value;

    return convertTimeToDatetimeLocalWithoutMoment(start, startValue);
  }, [
    schedule_time,
    selectedTimeSheet?.start_time,
    selectedTimeSheet?.worker_start_time,
    startTimeValue?.value,
    workerStartTimeValue?.value,
  ]);

  const endTime = useMemo(() => {
    const start = schedule_time
      ? selectedTimeSheet?.start_time
      : selectedTimeSheet?.worker_start_time;
    const end = schedule_time
      ? selectedTimeSheet?.end_time
      : selectedTimeSheet?.worker_end_time;
    const endValue = schedule_time
      ? endTimeValue?.value
      : workerEndTimeValue?.value;
    const TimeValue = schedule_time
      ? startTimeValue?.value
      : workerStartTimeValue?.value;
    if (isEndTimeNextDay(start, TimeValue, endValue, end)) {
      return convertTimeToDatetimeLocalWithoutMoment(start, endValue, true);
    } else {
      return convertTimeToDatetimeLocalWithoutMoment(start, endValue);
    }
  }, [
    schedule_time,
    selectedTimeSheet?.start_time,
    selectedTimeSheet?.worker_start_time,
    startTimeValue?.value,
    endTimeValue?.value,
    workerStartTimeValue?.value,
    workerEndTimeValue?.value,
  ]);

  //   const startTime = useMemo(() => {

  //     return convertTimeToDatetimeLocalWithoutMoment(
  //       selectedTimeSheet?.worker_start_time,
  //       workerStartTimeValue?.value
  //     );
  //   }, [selectedTimeSheet?.worker_start_time, workerStartTimeValue?.value]);

  //   const endTime = useMemo(() => {

  //     if(isEndTimeNextDay(
  //       selectedTimeSheet?.worker_start_time,
  //       workerStartTimeValue?.value,
  //       workerEndTimeValue?.value
  //     )){

  //     return  convertTimeToDatetimeLocalWithoutMoment(
  //           selectedTimeSheet?.worker_start_time,
  //           workerEndTimeValue?.value,
  //           true)
  //     }else {

  // return convertTimeToDatetimeLocalWithoutMoment(
  //     selectedTimeSheet?.worker_start_time,
  //     workerEndTimeValue?.value

  // )
  //     }},[selectedTimeSheet?.worker_start_time,workerStartTimeValue?.value,workerEndTimeValue?.value,selectedTimeSheet?.worker_end_time])

  useEffect(() => {
    const fetchTimesheetBreakdown = async () => {
      if (
        serviceCategory ||
        watchFormData?.state_id ||
        watchFormData?.sleepover ||
        categoryItemName?.value
      ) {
        if (startTime && endTime) {
          setLoading(true);

          const url = `${URL_ROSTER_INVOICING_TIMESHEET_BREAKDOWN}/?invoice_service_type_id=${
            serviceCategory?.value || ""
          }&state_id=${watchFormData?.state_id || ""}&uuid=${
            selectedTimeSheet?.uuid || ""
          }&sleepover=${watchFormData?.sleepover}&start_time=${
            startTime || ""
          }&end_time=${endTime || ""}&invoice_category_name=${
            categoryItemName?.value
          }`;

          try {
            const res = await apiGetRequest(url);

            if (res.status === 200) {
              setLoading(false);
              setTimesheetBreakdown(res?.data?.data);
            }
          } catch (error) {
            setLoading(false);
            console.error("Error fetching timesheet breakdown:", error);
            setTimesheetBreakdown(null);
          }
        }
      }
    };
    if (selectedTimeSheet.status === TIMESHEET_STATUS.PENDING) {
      fetchTimesheetBreakdown();
    }
  }, [
    serviceCategory?.value,
    watchFormData?.state_id,
    watchFormData?.sleepover,
    categoryItemName?.value,
    selectedTimeSheet?.uuid,
    selectedTimeSheet?.status,
    startTime,
    endTime,
  ]);

  useEffect(() => {
    const fetchItemNumber = async () => {
      if (
        serviceCategory?.value ||
        watchFormData?.state_id ||
        watchFormData?.sleepover ||
        categoryItemName?.value
      ) {
        if (startTime && endTime) {
          setLoading(true);
          const url = `${URL_ROSTERING_INVOICING_GET_ITEM_NUMBER_ON_TIMESHEET}/?invoice_service_type_id=${
            serviceCategory?.value || ""
          }&state_id=${watchFormData?.state_id || ""}&uuid=${
            selectedTimeSheet?.uuid || ""
          }&sleepover=${
            watchFormData?.sleepover || false
          }&invoice_category_name=${categoryItemName?.value}&start_time=${
            startTime || ""
          }&end_time=${endTime || ""}`;

          try {
            const res = await apiGetRequest(url);
            if (res.status === 200) {
              setLoading(false);
              const responseDate = res?.data?.data?.items;
              const selectedItem = res?.data?.data?.selected;

              const itemNumberOptions = responseDate?.map((item) => ({
                label: item.support_item_name,
                value: item.id,
                price: item.price,
                number: item?.support_item_number,
              }));
              setItemNumber(itemNumberOptions);
              setSuggestedItemNumber(res?.data?.data?.selected);

              const obj = {
                label: selectedItem?.support_item_name,
                number: selectedItem?.support_item_number,
                value: selectedItem?.id,
                price: selectedItem?.price,
              };
              setNDISItemName({ ...obj });

              if (parseBooleanVal(spRosteringSetting?.apply_alternate_rate)) {
                setValue("invoice_ndis_rate", "alternate");
                setValue(
                  "rate_amount",
                  spRosteringSetting?.default_alternate_rate
                );
              } else if (obj.price) {
                setValue("invoice_ndis_rate", obj.price);
                setValue("rate_amount", obj.price);
              }
            }
          } catch (error) {
            setLoading(false);
            console.error("Error fetching item number:", error);
          }
        }
      }
    };

    if (selectedTimeSheet.status === TIMESHEET_STATUS.PENDING) {
      fetchItemNumber();
    }
  }, [
    serviceCategory?.value,
    watchFormData?.state_id,
    categoryItemName?.value,
    watchFormData?.sleepover,
    startTime,
    endTime,
  ]);

  const NDIS_RATE = [
    {
      label: "Apply Alternative Rate",
      value: "alternate",
    },
    {
      label: ndisItemName && ndisItemName?.price,
      value: ndisItemName && ndisItemName?.price,
    },
  ];
  const getAppliedAmount = () => {
    if (
      watchFormData &&
      watchFormData?.rate_amount &&
      selectedTimeSheet &&
      selectedTimeSheet?.participants
    ) {
      const result =
        watchFormData?.rate_amount / selectedTimeSheet?.participants.length;
      return result.toFixed(2);
    }
  };

  const handleChangeTimesheetStatus = ({ status, isNext = false }) => {
    if (selectedTimeSheet && selectedTimeSheet?.uuid) {
      timeSheetStatusChangeRequest({
        data: { uuid: [selectedTimeSheet.uuid], status },
        isNext,
      });
    }
  };

  useEffect(() => {
    if (ndisItemName?.number) {
      setValue("invoice_item_name", ndisItemName.number);
    }
  }, [ndisItemName?.number]);

  useEffect(() => {
    if (selectedTimeSheet && !watchFormData.rate_amount) {
      if (watchFormData.invoice_ndis_rate !== "alternate") {
        if (watchFormData.invoice_ndis_rate) {
          setValue("rate_amount", watchFormData?.invoice_ndis_rate);
        }
      } else if (watchFormData.invoice_ndis_rate === "alternate") {
        setValue(
          "rate_amount",
          spRosteringSetting?.default_alternate_rate || ""
        );
      }
    } else if (watchFormData?.rate_amount) {
      setValue("rate_amount", watchFormData?.invoice_ndis_rate);
    }
  }, [selectedTimeSheet, watchFormData?.invoice_ndis_rate]);

  useEffect(() => {
    if (itemNumber && itemNumber.length > 0) {
      const itemName = itemNumber?.filter(
        (item) => item?.value === watchFormData?.invoice_item_id
      );
      setValue("invoice_item_name", itemName[0]?.number);
    } else {
      setValue("invoice_item_name", null);
    }

    if (spRosteringSetting?.apply_alternate_rate) {
      setValue("rate_amount", spRosteringSetting?.default_alternate_rate || 0);
    } else {
      setValue("rate_amount", null);
    }
  }, [
    watchFormData?.invoice_item_id,
    watchFormData?.invoice_breakdown,
    suggestedItemNumber,
    itemNumber,
  ]);

  useEffect(() => {
    if (watchFormData && watchFormData?.non_billable) {
      setValue("rate_amount", 0);
      setValue("invoice_ndis_rate", 0);
    }
  }, [watchFormData?.non_billable]);

  useEffect(() => {
    if (startTimeChanged || endTimeChanged) {
      setValue(
        "start_time_worker",
        convertTo12HourFormat(startTimeValue?.value)
      );
    }
    if (startTimeChanged || endTimeChanged) {
      setValue("end_time_worker", convertTo12HourFormat(endTimeValue?.value));
    }
    return () => {
      setStartTimeChanged(false);
      setEndTimeChanged(false);
    };
  }, [
    startTimeValue?.value,
    endTimeValue?.value,
    startTimeChanged,
    endTimeChanged,
    setValue,
  ]);

  const handleUnpproved = () => {
    swal({
      title: "Confirm your action?",
      text: `Are you sure to unapprove the timesheet?`,
      icon: "",
      buttons: ["No, take me back!", "Yes, go ahead!"],
      className: "",
      cancel: {
        text: "No, take me back!",
        value: null,
        visible: false,
        className: "",
        closeModal: true,
      },
      confirm: {
        text: "Yes, go ahead!",
        value: true,
        visible: true,

        closeModal: true,
      },
    }).then((isUnpprove) => {
      if (isUnpprove) {
        handleChangeTimesheetStatus({
          status: TIMESHEET_STATUS.PENDING,
        });
      }
    });
  };

  const [shiftOverlap, setShiftOverlap] = useState(null);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      if (startTime && endTime) {
        const payloadData = {
          start_time: startTime,
          end_time: endTime,
          uuid: selectedTimeSheet?.uuid,
        };

        apiPostRequest(URL_ROSTERING_TIMESHEET_CHECK_OVERLAPPING, payloadData)
          .then((res) => {
            setShiftOverlap(null);
          })
          .catch((error) => {
            setShiftOverlap(error?.message);
          });
      }
    }
  }, [startTime, endTime]);

  const formatDateWithDay = (date) => {
    const options = {
      weekday: "long",
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  const isTimesheetServiceCatNonSTA = useMemo(() => {
    return isEmpty(serviceCategory) || serviceCategory?.read_only === false;
  }, [serviceCategory]);

  const handleNewShiftNotes = (id, value) => {
    setSelectedShiftNotes((prevNotes) => {
      const updatedNotes = { ...prevNotes };

      if (value.trim()) {
        updatedNotes[id] = { id, note: value };
      } else {
        delete updatedNotes[id];
      }

      return updatedNotes;
    });
  };

  const totalItemsDuration =
    watchFormData?.invoice_breakdown &&
    Array.isArray(timesheetBreakdown) &&
    timesheetBreakdown?.length > 0 &&
    calculateTotalDuration(timesheetBreakdown);

  return (
    <div ref={mainDivRef}>
      {selectedTimeSheet &&
      selectedTimeSheet.status !== TIMESHEET_STATUS.PENDING ? (
        <div className='border px-2 rounded-md'>
          {selectedTimeSheet?.is_group_shift && (
            <div className='flex items-center w-full border-gray-200 border-b py-5'>
              <label className='text-purple-700 bg-purple-200 font-semibold border border-solid border-gray-200 rounded-full 	 shadow-sm  p-2'>
                Group Shift
              </label>
            </div>
          )}
          <div className='flex items-start border-gray-200 border-b py-5'>
            <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
              Date
            </label>
            <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
              {formatDateWithDay(selectedTimeSheet?.time_sheet_date)}
            </p>
          </div>
          {selectedParticipant && (
            <div className='flex items-center border-gray-200 border-b py-5'>
              <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                {selectedTimeSheet?.is_group_shift
                  ? `participants`
                  : `participant`}
              </label>
              <div className='mb-0 text-gray-600 font-normal text-base pl-3'>
                {selectedTimeSheet?.is_group_shift ? (
                  <AvatarGroup
                    type='worker'
                    arrayData={selectedTimeSheet?.participants || []}
                    cssClass='mt-0 flex'
                    maxLength={10}
                    isForRostering
                  />
                ) : (
                  <AvatarGroup
                    type='worker'
                    arrayData={selectedTimeSheet?.participants}
                    cssClass='mt-0 mr-1'
                    maxLength={5}
                    isForRostering
                  />
                )}
              </div>
            </div>
          )}
          <div className='flex items-start border-gray-200 border-b py-5'>
            <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
              Start/End
            </label>
            {/* <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
              {`${formatDateForApprovedTimesheet(
                selectedTimeSheet?.start_time
              )} , ${formatDateForApprovedTimesheet(
                selectedTimeSheet?.end_time
              )}`}
            </p> */}
            <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
              {schedule_time
                ? `${formatDateForApprovedTimesheet(
                    selectedTimeSheet?.start_time
                  )} , ${formatDateForApprovedTimesheet(
                    selectedTimeSheet?.end_time
                  )}`
                : `${formatDateForApprovedTimesheet(
                    selectedTimeSheet?.worker_start_time
                  )} , ${formatDateForApprovedTimesheet(
                    selectedTimeSheet?.worker_end_time
                  )}`}
            </p>
          </div>
          <div className='flex items-start border-gray-200 border-b py-5'>
            <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
              Timesheet Length
            </label>
            <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
              {`${getHrAndMinsFormatting(selectedTimeSheet?.timesheet_length)}`}
            </p>
          </div>

          {selectedTimeSheet &&
            selectedTimeSheet?.invoiceTimesheets &&
            selectedTimeSheet?.invoiceTimesheets?.length > 0 &&
            selectedTimeSheet?.invoiceTimesheets?.every(
              (el) => el?.invoice_item_id
            ) &&
            selectedTimeSheet?.invoiceTimesheets?.map((singleItem, index) => (
              <div className='border border-gray-200  py-5 px-6 rounded-lg mt-4'>
                <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                  Timesheet Breakdown #{index + 1}
                </label>
                <div className='flex items-start border-gray-200 border-b py-2'>
                  <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                    Item Name
                  </label>
                  <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
                    {singleItem?.ndis_support_item_name || "-"}
                  </p>
                </div>
                <div className='flex items-start border-gray-200 border-b py-2'>
                  <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                    Item Number
                  </label>
                  <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
                    {singleItem?.ndis_support_item_number || "-"}
                  </p>
                </div>
                <div className='flex items-start border-gray-200 border-b py-2'>
                  <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                    NDIS Rate
                  </label>
                  <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
                    {singleItem?.invoice_price}
                  </p>
                </div>
              </div>
            ))}

          <div className='flex items-start border-gray-200 border-b py-5'>
            <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
              Non-Billable
            </label>
            <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
              {selectedTimeSheet?.invoice_non_billable ? "yes" : "No"}
            </p>
          </div>
          <div className='flex items-start border-gray-200 border-b py-5'>
            <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
              Cancelled
            </label>
            <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
              {selectedTimeSheet?.invoice_cancelled ? "Yes" : "NO"}
            </p>
          </div>
          <div className='flex items-start border-gray-200 border-b py-5'>
            <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
              Sleepover
            </label>
            <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
              {selectedTimeSheet?.sleepover ? "Yes" : "NO"}
            </p>
          </div>

          {selectedTimeSheet?.shift_notes &&
            selectedTimeSheet?.shift_notes?.length > 0 &&
            selectedTimeSheet?.shift_notes?.map((el, index) => (
              <div className='flex items-start border-gray-200 border-b py-5'>
                <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                  Shift Notes for {el?.first_name} {el?.last_name}
                </label>
                <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
                  {el?.notes || "-"}
                </p>
              </div>
            ))}

          {/* <div className='flex items-start border-gray-200 border-b py-5'>
            <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
              Shift Notes
            </label>
            <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
              {selectedTimeSheet?.note || "-"}
            </p>
          </div> */}

          <div className='flex items-start border-gray-200 border-b py-5'>
            <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
              Incident Details <span className='text-red-600'>*</span>
            </label>
            <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
              {selectedTimeSheet?.incident || "-"}
            </p>
          </div>
          <div className='flex items-start border-gray-200 border-b py-5'>
            <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
              Shift associated cost $
            </label>
            <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
              {selectedTimeSheet?.comment || "-"}
            </p>
          </div>
          <div className='flex items-start border-gray-200 border-b py-5'>
            <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
              Km
            </label>
            <p className='mb-0 text-gray-600 font-normal text-base pl-3'>
              {selectedTimeSheet?.km || "0"}
            </p>
          </div>
          {selectedTimeSheet && selectedTimeSheet?.attachments && (
            <div className='flex items-start border-gray-200 border-b py-5'>
              <label className='text-gray-900 text-base whitespace-nowrap font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                Participant's Attachments
              </label>
              <div className='w-full m-6 grid grid-cols-5 gap-4'>
                {selectedTimeSheet.attachments
                  .slice(0, 5)
                  .map((attachment, index) => (
                    <a
                      key={index}
                      href={`https://docs.vertex360.io/${attachment.file_url}`}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img
                        src={`https://docs.vertex360.io/${attachment.file_url}`}
                        alt={`Attachment ${index + 1}`}
                        className='max-w-full h-auto'
                      />
                    </a>
                  ))}
              </div>
            </div>
          )}

          {selectedTimeSheet && selectedTimeSheet?.pt_sign && (
            <div className='flex items-start border-gray-200 border-b py-5'>
              <label className='text-gray-900 whitespace-nowrap text-base  font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                Participant's Signature
              </label>
              <div className='w-full m-6'>
                <img src={selectedTimeSheet?.pt_sign} alt='participant' />
              </div>
            </div>
          )}

          {selectedTimeSheet?.shift_location_tracking &&
            selectedTimeSheet?.shift_start_lat &&
            selectedTimeSheet?.shift_start_lng && (
              <>
                <div className='f border-gray-200 border-b py-5'>
                  <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                    Location Tracking
                  </label>
                  <div className='w-full mt-4'>
                    <MapComponent selectedTimeSheet={selectedTimeSheet} />
                  </div>
                </div>
              </>
            )}
          <div>
            {selectedTimeSheet?.roster_invoice_generated &&
              !isBreakdownTimesheet && (
                <div className='flex items-center bg-red-100 border border-red-400 text-red-700 p-2 rounded'>
                  <FaExclamationCircle className='text-red-500 mr-2' />
                  <p className='text-sm normal-case'>
                    To unapprove this timesheet, you must first void invoice{" "}
                    <span className='font-bold'>
                      {selectedTimeSheet?.invoice_number}{" "}
                    </span>
                    <span
                      onClick={() => navigate(`/rostering/invoicing`)}
                      className=' text-blue-500 text-sm hover:text-blue-700 cursor-pointer underline'
                    >
                      {"  "} Go to invoices
                    </span>
                  </p>
                </div>
              )}
          </div>
          {!isBreakdownTimesheet && (
            <div className='my-4 text-start'>
              <CustomButton
                isDisabled={
                  timesheetStatusChangeLoader ||
                  updateTimesheetLoader ||
                  isUserGracePeriodOver ||
                  selectedTimeSheet?.roster_invoice_generated
                }
                variant='tertiary'
                label='Unapprove'
                // showLoading={
                //   timesheetStatusChangeLoader &&
                //   timesheetStatusChangeLoader.status ===
                //     TIMESHEET_STATUS.REJECTED
                // }
                // loaderCssClass={
                //   timesheetStatusChangeLoader ? "text-black" : ""
                // }
                clickHandler={() => {
                  if (isUserGracePeriodOver) return;
                  handleUnpproved();
                }}
                cssClass='hover:bg-red-100 text-red-700 text-base font-semibold bg-red-50 border border-solid border-gray-200 py-3 px-5 rounded-lg shadow-sm'
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {selectedTimeSheet?.is_group_shift && (
              <div className='flex items-center w-full border-gray-200 border-b py-5'>
                <label className='text-purple-700 bg-purple-200 font-semibold border border-solid border-gray-200 rounded-full 	 shadow-sm  p-2'>
                  Group Shift
                </label>
              </div>
            )}
            <div className='flex items-start border-gray-200 border-b py-5'>
              <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                Shift Date
              </label>
              <InputField
                isDisabled={true}
                readOnly={true}
                cssClass='mt-0 h-40-important'
                inputWidth='w-full'
                value={
                  watchFormData?.time_sheet_date
                    ? moment(watchFormData?.time_sheet_date).format(
                        "dddd, DD-MM-YYYY"
                      ) // Full day name
                    : null
                }
                name='time_sheet_date'
                isRequired={true}
                ErrorMessage={
                  errors?.time_sheet_date?.type === "required"
                    ? "This field is required!"
                    : errors?.time_sheet_date?.type === "pattern"
                    ? validateCurrentDate(
                        moment(watchFormData?.time_sheet_date ?? null),
                        moment(new Date()),
                        ["present", "future", "past"],
                        false
                      )
                    : null
                }
              />
            </div>

            {selectedParticipant && (
              <div className='flex items-center border-gray-200 border-b py-5'>
                <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                  {selectedTimeSheet?.is_group_shift
                    ? `participants`
                    : `participant`}
                </label>
                <div className='mb-0 text-gray-600 font-normal text-base pl-3'>
                  {selectedTimeSheet?.is_group_shift ? (
                    <AvatarGroup
                      type='worker'
                      arrayData={selectedTimeSheet?.participants || []}
                      cssClass='mt-0'
                      maxLength={10}
                      isForRostering
                    />
                  ) : (
                    <AvatarGroup
                      type='worker'
                      arrayData={selectedTimeSheet?.participants}
                      cssClass='mt-0 mr-1'
                      maxLength={5}
                      isForRostering
                    />
                  )}
                </div>
              </div>
            )}

            <div className='flex flex-col items-start border-gray-200 border-b py-5'>
              <div className='flex w-full'>
                <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                  Scheduled Start/Finish.
                </label>
                <div className='flex items-end w-full space-x-2'>
                  <div className='w-[45%]'>
                    <p>
                      {selectedTimeSheet?.start_time && startTimeValue?.value
                        ? customFormatDate(
                            convertTimeToDatetimeLocalWithoutMoment(
                              selectedTimeSheet?.start_time,
                              startTimeValue?.value
                            )
                          )
                        : " "}
                    </p>
                    {/* <div className='hidden'>
                      <select
                        {...register("start_time", {
                          // required: true,
                        })}
                        name='start_time'
                        id=''
                        className='hidden did-floating-input'
                      />
                    </div>
                    <SelectOption
                      // isMulti={true}
                      isDisabled={loading}
                      isSearch={false}
                      data={SHIFT_TIME_OPTIONS}
                      selectedOption={startTimeValue}
                      handleChange={setStartTime}
                      setIsTimeChanged={setStartTimeChanged}
                      loading={loading}
                      setActualDate={setActualDate}
                    />
                    {errors?.start_time && !startTimeValue?.value && (
                      <span className='text-red-500'>
                        This field is required
                      </span>
                    )} */}

                    <div className='w-full'>
                      <TimeInput
                        {...register("start_time", { required: true })}
                        hour12Format={true}
                        eachInputDropdown={true}
                        manuallyDisplayDropdown={true}
                        onChange={(e) => {
                          setStartTimeChanged(true);
                          setActualDate(true);
                          setStartTime({ label: e, value: e });
                        }}
                        value={startTimeValue?.value || ""}
                      />
                    </div>
                    {errors.start_time && (
                      <span
                        style={{ color: "red", fontSize: "13px" }}
                        // className='capitalize'
                      >
                        {errors.start_time.message}
                      </span>
                    )}
                  </div>
                  <div className='w-[10%] text-center'>To</div>
                  <div className='w-[45%]'>
                    {selectedTimeSheet?.start_time && endTimeValue?.value ? (
                      <p>
                        {
                          isEndTimeNextDay(
                            selectedTimeSheet?.start_time,
                            startTimeValue?.value,
                            endTimeValue?.value,
                            selectedTimeSheet?.end_time
                          )
                            ? customFormatDate(
                                convertTimeToDatetimeLocalWithoutMoment(
                                  selectedTimeSheet?.start_time,
                                  endTimeValue?.value,
                                  true
                                )
                              )
                            : customFormatDate(
                                convertTimeToDatetimeLocalWithoutMoment(
                                  selectedTimeSheet?.start_time,
                                  endTimeValue?.value
                                )
                              )

                          // .utc()
                        }
                      </p>
                    ) : (
                      <p> </p>
                    )}
                    {/* <div className='hidden'>
                      <select
                        {...register("end_time", {
                          // required: true,
                        })}
                        name='end_time'
                        id=''
                        className='hidden did-floating-input'
                      />
                    </div>
                    <SelectOption
                      // isMulti={true}
                      isDisabled={loading}
                      isSearch={false}
                      data={SHIFT_TIME_OPTIONS}
                      selectedOption={endTimeValue}
                      handleChange={setEndTime}
                      setIsTimeChanged={setEndTimeChanged}
                      setActualDate={setActualDate}
                      loading={loading}
                    />

                    {errors?.end_time && !endTimeValue?.value && (
                      <span className='text-red-500'>
                        This field is required
                      </span>
                    )} */}
                    <div className='w-full'>
                      <TimeInput
                        {...register("end_time", { required: true })}
                        hour12Format={true}
                        eachInputDropdown={true}
                        manuallyDisplayDropdown={true}
                        onChange={(e) => {
                          setEndTimeChanged(true);
                          setActualDate(true);
                          setEndTime({ label: e, value: e });
                        }}
                        value={endTimeValue?.value || ""}
                      />
                    </div>
                    {errors.end_time && (
                      <span
                        style={{ color: "red", fontSize: "13px" }}
                        // className='capitalize'
                      >
                        {errors.end_time.message}
                      </span>
                    )}
                  </div>
                </div>
                {/* <div className='w-full'>
                  <label className='flex items-center align-start'>
                    <p>
                      {selectedTimeSheet?.start_time && startTimeValue
                        ? customFormatDate(
                            convertTimeToDatetimeLocalWithoutMoment(
                              selectedTimeSheet?.start_time,
                              startTimeValue?.value
                            )
                          )
                        : " "}
                    </p>
                    {selectedTimeSheet?.start_time && endTimeValue && (
                      <span className='px-2 flex'>
                        <p className='mr-2'>To</p>
                        <p>
                          {isEndTimeNextDay(
                            selectedTimeSheet?.start_time,
                            startTimeValue,
                            endTimeValue
                          )
                            ? customFormatDate(
                                convertTimeToDatetimeLocalWithoutMoment(
                                  selectedTimeSheet?.start_time,
                                  endTimeValue,
                                  true
                                )
                              )
                            : customFormatDate(
                                convertTimeToDatetimeLocalWithoutMoment(
                                  selectedTimeSheet?.start_time,
                                  endTimeValue
                                )
                              )}
                        </p>
                      </span>
                    )}
                  </label>

                  <Controller
                    name='timeRange'
                    control={control}
                    rules={{
                      validate: {
                        required: (value) => {
                          const [start, end] = value || [];
                          return (
                            (!!start && !!end) ||
                            "Both start and end times are required"
                          );
                        },
                      },
                    }}
                    render={({
                      field: { onChange, value },
                      // fieldState: { error },
                    }) => (
                      <TimeInputRange
                        // clearable
                        disabled={loading}
                        seperator='To'
                        invalid={errors.timeRange}
                        format='12'
                        amLabel='AM'
                        pmLabel='PM'
                        value={value || [null, null]}
                        onChange={(e) => {
                          onChange(e); // update the form field value
                          if (e?.[0]) {
                            setStartTime(moment(e?.[0]).format("HH:mm"));
                            setStartTimeChanged(true);
                            setActualDate(true);
                          }
                          if (e?.[1]) {
                            setEndTime(moment(e?.[1]).format("HH:mm"));
                            setEndTimeChanged(true);
                            setActualDate(true);
                          }
                          setValue("start_time", e?.[0]);
                          setValue("end_time", e?.[1]);
                        }}
                      />
                    )}
                  />
                  {errors.timeRange && (
                    <span
                      style={{ color: "red", fontSize: "13px" }}
                      className='capitalize'
                    >
                      {errors?.timeRange?.message}
                    </span>
                  )}
                </div> */}
              </div>
            </div>
            {shiftOverlap && (
              <div className='flex items-center bg-yellow-100 border border-yellow-500 text-yellow-700 p-2 rounded'>
                <IoWarningOutline className='text-yellow-500 mr-2 h-8 w-8' />
                <p className='text-sm font-semibold'>Warning: {shiftOverlap}</p>
              </div>
            )}
            <div className='flex items-start border-gray-200 border-b py-5'>
              <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                Actual Start/Finish.
              </label>
              <div className='flex items-end w-full space-x-2'>
                <div className='w-[45%]'>
                  <p>
                    {/* {selectedTimeSheet?.worker_start_time && !actualDate
                      ? formatDateForWorkerStartTime(
                          selectedTimeSheet?.worker_start_time
                        )
                      : // customFormatDate(
                        //     convertTimeToDatetimeLocalWithoutMoment(
                        //       selectedTimeSheet?.worker_start_time,
                        //       startTimeValue?.value
                        //     )
                        //   )
                        // moment(
                        //     convertTimeToDatetimeLocal(
                        //       moment(selectedTimeSheet?.start_time),
                        //       startTimeValue?.value
                        //     )
                        //   )
                        //     // .utc()
                        //     .format("DD-MM-YYYY")
                        selectedTimeSheet?.start_time &&
                        startTimeValue?.value &&
                        customFormatDate(
                          convertTimeToDatetimeLocalWithoutMoment(
                            selectedTimeSheet?.start_time,
                            startTimeValue?.value
                          )
                        )} */}
                    <p>
                      {selectedTimeSheet?.worker_start_time &&
                      workerStartTimeValue?.value
                        ? customFormatDate(
                            convertTimeToDatetimeLocalWithoutMoment(
                              selectedTimeSheet?.worker_start_time,
                              workerStartTimeValue?.value
                            )
                          )
                        : " "}
                    </p>
                  </p>
                  <div className='w-full'>
                    <TimeInput
                      {...register("start_time_worker", { required: false })}
                      hour12Format={true}
                      eachInputDropdown={true}
                      manuallyDisplayDropdown={true}
                      onChange={(e) => {
                        setWorkerStartTime({ label: e, value: e });
                      }}
                      value={workerStartTimeValue?.value || ""}
                    />
                  </div>
                  {errors.start_time_worker && (
                    <span
                      style={{ color: "red", fontSize: "13px" }}
                      // className='capitalize'
                    >
                      {errors.start_time_worker.message}
                    </span>
                  )}
                  {/* <InputField
                    readOnly={true}
                    register={register}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    name='start_time_worker'
                    cssClass='w-full'
                  /> */}
                </div>
                <div className='w-[10%] text-center'>To</div>
                <div className='w-[45%]'>
                  {selectedTimeSheet?.worker_start_time &&
                  workerEndTimeValue ? (
                    <p>
                      {
                        isEndTimeNextDay(
                          selectedTimeSheet?.worker_start_time,
                          workerStartTimeValue?.value,
                          workerEndTimeValue?.value,
                          selectedTimeSheet?.worker_end_time
                        )
                          ? customFormatDate(
                              convertTimeToDatetimeLocalWithoutMoment(
                                selectedTimeSheet?.worker_start_time,
                                workerEndTimeValue?.value,
                                true
                              )
                            )
                          : customFormatDate(
                              convertTimeToDatetimeLocalWithoutMoment(
                                selectedTimeSheet?.worker_start_time,
                                workerEndTimeValue?.value
                              )
                            )

                        // .utc()
                      }
                    </p>
                  ) : (
                    <p> </p>
                  )}
                  {/* {selectedTimeSheet?.worker_end_time && !actualDate ? (
                    <p>
                      {
                        formatDateForWorkerStartTime(
                          selectedTimeSheet?.worker_end_time
                        )
                        // isWorkerEndTimeNextDay(
                        //   selectedTimeSheet?.worker_start_time,
                        //   selectedTimeSheet?.worker_end_time
                        // )
                        //   ? customFormatDate(
                        //       convertTimeToDatetimeLocalWithoutMoment(
                        //         selectedTimeSheet?.worker_start_time,
                        //         endTimeValue?.value,
                        //         true
                        //       )
                        //     )
                        //   : customFormatDate(
                        //       convertTimeToDatetimeLocalWithoutMoment(
                        //         selectedTimeSheet?.worker_start_time,
                        //         endTimeValue?.value
                        //       )
                        //     )
                      }
                    </p>
                  ) : (
                    selectedTimeSheet?.start_time &&
                    startTimeValue?.value &&
                    endTimeValue?.value && (
                      <p>
                        {
                          isEndTimeNextDay(
                            selectedTimeSheet?.start_time,
                            startTimeValue?.value,
                            endTimeValue?.value
                          )
                            ? customFormatDate(
                                convertTimeToDatetimeLocalWithoutMoment(
                                  selectedTimeSheet?.start_time,
                                  endTimeValue?.value,
                                  true
                                )
                              )
                            : customFormatDate(
                                convertTimeToDatetimeLocalWithoutMoment(
                                  selectedTimeSheet?.start_time,
                                  endTimeValue?.value
                                )
                              )

                          // .utc()
                        }
                      </p>
                    )
                  )} */}

                  {/* <InputField
                    readOnly={true}
                    register={register}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    name='end_time_worker'
                    cssClass='w-full'
                  /> */}
                  <div className='w-full'>
                    <TimeInput
                      {...register("end_time_worker", { required: false })}
                      hour12Format={true}
                      eachInputDropdown={true}
                      manuallyDisplayDropdown={true}
                      onChange={(e) => {
                        setWorkerEndTime({ label: e, value: e });
                      }}
                      value={workerEndTimeValue?.value || ""}
                    />
                  </div>
                  {errors.end_time_worker && (
                    <span
                      style={{ color: "red", fontSize: "13px" }}
                      // className='capitalize'
                    >
                      {errors.end_time_worker.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            {notes?.length > 0
              ? notes?.map((noteObj, index) => (
                  <div className='flex items-start border-gray-200 border-b py-5'>
                    <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                      Shift Notes for {noteObj?.first_name} {noteObj?.last_name}
                    </label>
                    <p className='mb-0 text-gray-600 font-normal text-base'></p>
                    <textarea
                      value={noteObj.notes}
                      onChange={(e) => handleNoteChange(index, e.target.value)}
                      rows='3'
                      className='mb-0 w-full text-gray-900 border border-gray-300 font-normal text-base rounded-md'
                      spellCheck='false'
                    />
                  </div>
                ))
              : selectedTimeSheet?.participants?.length > 0 &&
                selectedTimeSheet?.participants?.map((item, index) => {
                  const key = item.id;
                  return (
                    <div
                      key={key}
                      className='flex items-start border-gray-200 border-b py-5'
                    >
                      <label
                        htmlFor={key}
                        className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'
                      >
                        Shift Notes for ({item?.first_name} {item?.last_name})
                      </label>
                      <textarea
                        id={key}
                        name={`ShiftNotes_${key}`}
                        value={selectedShiftNotes[key]?.note || ""}
                        onChange={(e) =>
                          handleNewShiftNotes(key, e.target.value)
                        }
                        rows='3'
                        className='mb-0 w-full text-gray-900 border border-gray-300 font-normal text-base rounded-md'
                        spellCheck='false'
                      />
                    </div>
                  );
                })}

            <div className='flex items-start border-gray-200 border-b py-5'>
              <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                Incident Details <span className='text-red-600'>*</span>
              </label>
              <textarea
                rows={3}
                type='text'
                className='mb-0 w-full text-gray-900 border border-gray-300 font-normal text-base rounded-md'
                setValue={setValue}
                {...register("incident", { required: true })}
                value={watchFormData?.incident}
                name='incident'
                defaultValue={""}
                isRequired={true}
                ErrorMessage={
                  errors?.incident?.type === "isRequired"
                    ? "This field is required!"
                    : null
                }
              />
            </div>
            <div className='flex items-start border-gray-200 border-b py-5'>
              <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                Shift associated cost $
              </label>
              <div className='w-full'>
                <InputField
                  cssClass='mt-0 h-40-important'
                  inputWidth='w-full'
                  setValue={setValue}
                  register={register}
                  type='text'
                  value={watchFormData?.comment}
                  name='comment'
                  isRequired={false}
                  // ErrorMessage={
                  //   errors?.comment?.type === "required"
                  //     ? "This field is required!"
                  //     : null
                  // }
                />
              </div>
            </div>
            {selectedTimeSheet?.auto_calculated_km && (
              <div className='flex justify-between py-5'>
                <label className='text-gray-900 text-base font-semibold'>
                  Calculated Travel Distance (via Google API) : &nbsp;{" "}
                  {selectedTimeSheet?.auto_calculated_km} KM
                </label>
                {!(
                  selectedTimeSheet?.auto_calculated_km ===
                  selectedTimeSheet?.km
                ) && (
                  <div className='flex items-center gap-x-2'>
                    <label className='text-gray-700 text-sm font-medium mb-1'>
                      Use This Distance
                    </label>
                    <div>
                      <Controller
                        name={`applydistance`}
                        control={control}
                        defaultValue={watchFormData?.applydistance || false}
                        render={({ field }) => (
                          <SwitchWithIcon
                            onChange={(value) => {
                              field.onChange(value);
                              setValue(`applydistance`, value);
                              setValue(
                                `km`,
                                value
                                  ? selectedTimeSheet?.auto_calculated_km
                                  : selectedTimeSheet?.km
                              );
                            }}
                            checked={field.value}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className='border-gray-200 border-b py-5'>
              <div className='flex items-center'>
                <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                  KM
                </label>
                <div className='w-full'>
                  <InputField
                    cssClass='mt-0 h-40-important'
                    inputWidth='w-full'
                    setValue={setValue}
                    register={register}
                    readOnly={watchFormData?.applydistance}
                    type='text'
                    pattern={/^\d*[0-9][.]?\d*$/}
                    value={watchFormData?.km}
                    name='km'
                    ErrorMessage={
                      errors?.km?.type === "pattern"
                        ? "only number is required!"
                        : null
                    }
                  />
                </div>
              </div>

              {!isNaN(watchFormData?.km) &&
                watchFormData?.km &&
                parseBooleanVal(spRosteringSetting?.timesheet_invoices) && (
                  <div className='pt-5'>
                    <div className='flex items-center gap-x-2'>
                      <label className='text-gray-700 text-sm font-medium mb-1'>
                        Apply KMs to Invoicing
                      </label>
                      <div>
                        <Controller
                          name={`applykm`}
                          control={control}
                          defaultValue={watchFormData?.applykm || false}
                          render={({ field }) => (
                            <SwitchWithIcon
                              onChange={(value) => {
                                field.onChange(value);
                                setValue(`applykm`, value);
                                if (value) {
                                  fetchApplyKmDetails();
                                }
                              }}
                              checked={field.value}
                            />
                          )}
                        />
                      </div>
                    </div>
                    {watchFormData?.applykm ? (
                      traveldata !== null ? (
                        <>
                          <div className='flex items-center gap-x-4 py-5 '>
                            <diV className='flex items-center w-full gap-x-4'>
                              <label className='text-gray-900 text-base font-semibold  w-[130px] min-w-[130px] max-w-[130px]'>
                                NDIS Item Name
                              </label>
                              <div className='w-full'>
                                <InputField
                                  isDisabled={true}
                                  cssClass='mt-0 h-40-important'
                                  inputWidth='w-full'
                                  value={
                                    travelNdisNum?.support_item_name || null
                                  }
                                  isRequired={false}
                                />
                              </div>
                            </diV>
                          </div>
                          <div className='flex items-center gap-x-4 py-5 '>
                            <diV className='flex items-center w-full gap-x-4'>
                              <label className='text-gray-900 text-base font-semibold  w-[130px] min-w-[130px] max-w-[130px]'>
                                NDIS Item Number
                              </label>
                              <div className='w-full'>
                                <div className='hidden'>
                                  <select
                                    {...register("km_item_id", {
                                      //value: false,
                                      required: parseBooleanVal(
                                        watchFormData?.applykm
                                      ),
                                    })}
                                    name='km_item_id'
                                    id=''
                                    className='hidden did-floating-input'
                                    defaultValue={false}
                                  />
                                </div>
                                <SelectOption
                                  // isSearch={false}
                                  data={traveldata || []}
                                  selectedOption={travelNdisNum}
                                  handleChange={(e) => {
                                    setTravelNdisNum(e);
                                    setValue("km_item_id", e?.value);
                                  }}
                                  loading={loading}
                                  isDisabled={loading}
                                />
                                {errors.km_item_id && (
                                  <span className=' text-sm text-red-500'>
                                    This field is required
                                  </span>
                                )}
                              </div>
                            </diV>
                            <diV className='flex items-center w-full gap-x-4'>
                              <label className='text-gray-900 text-base font-semibold  w-[130px] min-w-[130px] max-w-[130px]'>
                                NDIS Item Rate
                              </label>
                              <div className='w-full'>
                                <InputField
                                  isDisabled={true}
                                  cssClass='mt-0 h-40-important'
                                  inputWidth='w-full'
                                  value={
                                    travelNdisNum?.price
                                      ? `$${travelNdisNum?.price}`
                                      : null
                                  }
                                  isRequired={false}
                                />
                              </div>
                            </diV>
                          </div>
                        </>
                      ) : (
                        <span className='flex justify-center my-5'>
                          Loading...
                        </span>
                      )
                    ) : null}
                    {/* <div className="mt-5 p-4 rounded border border-gray-500">
                      <div className="flex justify-between">
                        <label className="text-gray-900 text-base font-normal">
                          NDIS Item Name
                        </label>
                        <span>{traveldata?.support_item_name}</span>
                      </div>

                      <div className="flex justify-between my-2">
                        <label className="text-gray-900 text-base font-normal">
                          NDIS Item Number
                        </label>
                        <span>{traveldata?.support_item_number}</span>
                      </div>

                      <div className="flex justify-between">
                        <label className="text-gray-900 text-base font-normal">
                          NDIS Item Rate
                        </label>
                        <span>${traveldata?.price}</span>
                      </div>
                    </div>  : <span className="flex justify-center my-5">Loading...</span> : null */}
                  </div>
                )}
            </div>

            {selectedTimeSheet && selectedTimeSheet?.attachments && (
              <div className='flex items-start border-gray-200 border-b py-5'>
                <label className='text-gray-900 text-base whitespace-nowrap font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                  Participant's Attachments
                </label>
                <div className='w-full m-6 grid grid-cols-5 gap-4'>
                  {selectedTimeSheet.attachments
                    .slice(0, 5)
                    .map((attachment, index) => (
                      <a
                        key={index}
                        href={`https://docs.vertex360.io/${attachment.file_url}`}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <img
                          src={`https://docs.vertex360.io/${attachment.file_url}`}
                          alt={`Attachment ${index + 1}`}
                          className='max-w-full h-auto'
                        />
                      </a>
                    ))}
                </div>
              </div>
            )}

            {parseBooleanVal(spRosteringSetting?.timesheet_invoices) && (
              <div className='mt-10'>
                <div className='text-gray-900 font-semibold text-base '>
                  NDIS Catalogue Invoice
                </div>
                <div className='border border-gray-200 py-7 px-6 rounded-lg mt-2'>
                  <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
                    <div className='flex w-full gap-x-4'>
                      <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px] '>
                        Service Catalogue State
                        <span className='text-red-600'>*</span>
                      </label>

                      <div className='w-full'>
                        <div className='hidden'>
                          <select
                            {...register("state_id", {
                              //value: false,
                              required: true,
                            })}
                            name='state_id'
                            id=''
                            className='hidden did-floating-input'
                            defaultValue={false}
                          />
                        </div>
                        <SelectOption
                          isSearch={false}
                          data={catalogStates}
                          selectedOption={catalogState}
                          handleChange={setCatalogState}
                          isDisabled={loading}
                          loading={loading}
                        />
                        {errors.state_id && (
                          <span className=' text-sm text-red-500'>
                            This field is required
                          </span>
                        )}
                      </div>
                      <label className='text-gray-900 text-base font-semibold  w-[130px] min-w-[130px] max-w-[130px] '>
                        Service Category<span className='text-red-600'>*</span>
                      </label>
                      <div className='w-full'>
                        <div className='hidden'>
                          <select
                            {...register("invoice_service_type_id", {
                              required: false,
                            })}
                            name='invoice_service_type_id'
                            id=''
                            className='hidden did-floating-input'
                          />
                        </div>

                        <SelectOption
                          isSearch={false}
                          data={serviceTypes}
                          selectedOption={serviceCategory}
                          handleChange={setServiceCategory}
                          isDisabled={loading}
                          loading={loading}
                        />
                        {errors.invoice_service_type_id && !serviceCategory && (
                          <span className=' text-sm text-red-500'>
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  {!isTimesheetServiceCatNonSTA && (
                    <div className='flex items-center mt-2 bg-yellow-100 border border-yellow-500 text-yellow-700 p-1 rounded'>
                      <IoWarningOutline className='text-yellow-500 mr-2 h-6 w-6' />
                      <p className='text-sm font-semibold'>
                        {`STA Invoicing coming soon. For now, you can create STA
                      invoices using your integrated accounting software (Xero,
                      MYOB, QuickBooks).`}
                      </p>
                    </div>
                  )}

                  <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
                    <div className='flex w-full gap-x-4'>
                      <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
                        Category Item Name
                        <span className='text-red-600'>*</span>
                      </label>
                      <div className='w-full'>
                        <div className='hidden'>
                          <select
                            {...register("roster_invoicing_item", {
                              required: false,
                            })}
                            name='roster_invoicing_item'
                            id=''
                            className='hidden did-floating-input'
                          />
                        </div>
                        <SelectOption
                          isSearch={false}
                          data={CATEGORY_ITEM_NAME_AND_NOTES}
                          selectedOption={categoryItemName}
                          handleChange={setCategoryItemName}
                          isDisabled={loading || !isTimesheetServiceCatNonSTA}
                          loading={loading}
                        />
                        {errors.roster_invoicing_item && !categoryItemName && (
                          <span className=' text-sm text-red-500'>
                            This field is required
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
                    <label className='text-gray-900 text-base font-semibold  '>
                      Applied Ratio (Worker: Participant){" "}
                      {selectedTimeSheet?.ratio}
                    </label>

                    <div className='flex items-center gap-x-4  py-5 '>
                      <div className='flex items-center justify-start gap-x-4 '>
                        <div className='flex items-center gap-x-2'>
                          {" "}
                          <label className='text-gray-700 text-sm font-medium mb-1'>
                            Non-Billable
                          </label>
                          <div>
                            <Controller
                              name={`non_billable`}
                              control={control}
                              defaultValue={
                                watchFormData?.non_billable || false
                              }
                              render={({ field }) => (
                                <SwitchWithIcon
                                  disabled={!isTimesheetServiceCatNonSTA}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    setValue(`non_billable`, value);
                                  }}
                                  checked={field.value}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className='w-0.5 h-5 bg-gray-300'></div>
                        <div className='flex items-center gap-x-2'>
                          <label className='text-gray-700 text-sm font-medium mb-1'>
                            Cancelled
                          </label>
                          <div>
                            <Controller
                              name={`cancelled`}
                              control={control}
                              defaultValue={watchFormData?.cancelled || false}
                              render={({ field }) => (
                                <SwitchWithIcon
                                  disabled={!isTimesheetServiceCatNonSTA}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    setValue(`cancelled`, value);
                                  }}
                                  checked={field.value}
                                />
                              )}
                            />
                          </div>
                        </div>

                        {serviceCategory?.name === "Self-Care" && (
                          <>
                            <div className='w-0.5 h-5 bg-gray-300'></div>
                            <div className='flex items-center gap-x-2'>
                              <label className='text-gray-700 text-sm font-medium mb-1'>
                                Sleepover
                              </label>
                              <div>
                                <Controller
                                  name={`sleepover`}
                                  control={control}
                                  defaultValue={
                                    watchFormData?.sleepover || false
                                  }
                                  render={({ field }) => (
                                    <SwitchWithIcon
                                      // disabled={!isTimesheetServiceCatNonSTA}
                                      onChange={(value) => {
                                        field.onChange(value);
                                        setValue(`sleepover`, value);
                                        setValue("invoice_item_id", undefined);
                                      }}
                                      checked={field.value}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {timesheetBreakdown &&   timesheetBreakdown?.length > 0 && (
                    <div className='mt-5 text-gray-900 text-sm flex gap-x-2'>
                      <label className='text-gray-700 text-sm font-medium mb-1'>
                        {" "}
                        Do you want to break down the timesheet?{" "}
                      </label>{" "}
                      <div>
                        <Controller
                          name={`invoice_breakdown`}
                          control={control}
                          defaultValue={
                            watchFormData?.invoice_breakdown || false
                          }
                          render={({ field }) => (
                            <SwitchWithIcon
                              disabled={!isTimesheetServiceCatNonSTA}
                              onChange={(value) => {
                                field.onChange(value);
                                setValue(`invoice_breakdown`, value);
                              }}
                              checked={field.value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  )}

                  {!watchFormData?.invoice_breakdown && (
                    <>
                      <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
                        <div className='flex w-full gap-x-4'>
                          <label className='text-gray-900 text-base font-semibold  w-[130px] min-w-[130px] max-w-[130px]'>
                            NDIS Item Name
                            <span className='text-red-600'>*</span>
                          </label>
                          <div className='w-full'>
                            <div className='hidden'>
                              <select
                                {...register("invoice_item_id", {
                                  required: !isTimesheetServiceCatNonSTA
                                    ? false
                                    : true,
                                })}
                                name='invoice_item_id'
                                id=''
                                className='hidden did-floating-input'
                                defaultValue={false}
                              />
                            </div>
                            <SelectOption
                              isSearch={false}
                              data={itemNumber}
                              selectedOption={ndisItemName}
                              handleChange={(e) => {
                                setNDISItemName(e);
                              }}
                              isDisabled={
                                loading || !isTimesheetServiceCatNonSTA
                              }
                              loading={loading}
                            />
                            {errors.invoice_item_id && (
                              <span className=' text-sm text-red-500'>
                                This field is required
                              </span>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
                        <diV className='flex w-full gap-x-4'>
                          <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
                            NDIS Item Number
                            <span className='text-red-600'>*</span>
                          </label>
                          <div className='w-full'>
                            <InputField
                              isDisabled={true || !isTimesheetServiceCatNonSTA}
                              cssClass='mt-0 h-40-important'
                              inputWidth='w-full'
                              name='invoice_item_name'
                              isRequired={
                                !isTimesheetServiceCatNonSTA ? false : true
                              }
                              register={register}
                              setValue={setValue}
                            />
                          </div>
                          <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
                            Hourly Rate<span className='text-red-600'>*</span>
                          </label>
                          <div className='w-full'>
                            <SelectOption
                              name='invoice_ndis_rate'
                              isDisabled={
                                itemNumber?.length === 0 ||
                                watchFormData?.non_billable ||
                                loading ||
                                !ndisItemName?.value ||
                                !isTimesheetServiceCatNonSTA
                              }
                              cssClass='w-full'
                              loading={loading}
                              data={NDIS_RATE}
                              isRequired={
                                !isTimesheetServiceCatNonSTA ? false : true
                              }
                              register={register}
                              setValue={setValue}
                              clearErrors={clearErrors}
                              selectedOption={
                                watchFormData?.invoice_ndis_rate &&
                                NDIS_RATE?.find(
                                  (x) =>
                                    x.value === watchFormData?.invoice_ndis_rate
                                )
                              }
                            />
                          </div>
                        </diV>
                      </div>
                      <div className='flex items-center gap-x-4 border-gray-200 border-b py-5 '>
                        <diV className='flex w-full gap-x-4'>
                          <label className='text-gray-900 text-base font-semibold  w-[130px] min-w-[130px] max-w-[130px]'>
                            Rate Amount <span className='text-red-600'>*</span>
                          </label>
                          <div className='w-full'>
                            {watchFormData?.invoice_ndis_rate ===
                            "alternate" ? (
                              <InputField
                                isDisabled={
                                  watchFormData?.non_billable ||
                                  !isTimesheetServiceCatNonSTA
                                }
                                cssClass='mt-0 h-40-important'
                                inputWidth='w-full'
                                value={watchFormData?.rate_amount}
                                name='rate_amount'
                                isRequired={
                                  !isTimesheetServiceCatNonSTA ? false : true
                                }
                                type='number'
                                register={register}
                                step='0.01'
                              />
                            ) : (
                              <InputField
                                isDisabled={
                                  true || !isTimesheetServiceCatNonSTA
                                }
                                cssClass='mt-0 h-40-important'
                                inputWidth='w-full'
                                name='rate_amount'
                                isRequired={
                                  !isTimesheetServiceCatNonSTA ? false : true
                                }
                                register={register}
                                setValue={setValue}
                              />
                            )}
                          </div>
                          <label className='text-gray-900 text-base font-semibold w-[130px] min-w-[130px] max-w-[130px]'>
                            Applied Rate
                          </label>
                          <div className='w-full'>
                            <InputField
                              isDisabled={true || isTimesheetServiceCatNonSTA}
                              cssClass='mt-0 h-40-important'
                              inputWidth='w-full'
                              value={
                                watchFormData?.rate_amount && getAppliedAmount()
                              }
                              name='applied_rate'
                            />
                          </div>
                        </diV>
                      </div>
                    </>
                  )}

                  {watchFormData?.invoice_breakdown &&
                    timesheetBreakdown &&
                    timesheetBreakdown?.length > 0 &&
                    timesheetBreakdown?.map((item, index) => (
                      <>
                        <TimesheetBreakdown
                          spRosteringSetting={spRosteringSetting}
                          disabledAllComponent={!isTimesheetServiceCatNonSTA}
                          watchFormData={watchFormData}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          errors={errors}
                          register={register}
                          item={item}
                          index={index}
                          control={control}
                          serviceCategory={serviceCategory}
                          NDIS_RATE={NDIS_RATE}
                          selectedTimeSheet={selectedTimeSheet}
                          isNonBillable={watchFormData?.non_billable}
                          suggestedItemNumber={item?.item_numbers?.selected}
                          itemNumber={item?.item_numbers?.items?.map((el) => ({
                            label: el.support_item_name,
                            value: el.id,
                            price: el.price,
                            number: el?.support_item_number,
                          }))}
                          totalBreakDown={timesheetBreakdown?.length}
                          setError={setError}
                          totalItemsDuration={totalItemsDuration}
                        />
                      </>
                    ))}
                </div>
              </div>
            )}

            {selectedTimeSheet && selectedTimeSheet?.pt_sign && (
              <div className='flex items-start  border-gray-200 border-b py-5'>
                <label className='text-gray-900 text-base whitespace-nowrap font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                  Participant's Signature
                </label>
                <div className='w-full m-6'>
                  <img src={selectedTimeSheet?.pt_sign} alt='participant' />
                </div>
              </div>
            )}

            {selectedTimeSheet?.shift_location_tracking &&
              selectedTimeSheet?.shift_start_lat &&
              selectedTimeSheet?.shift_start_lng && (
                <>
                  <div className=' border-gray-200 border-b py-5'>
                    <label className='text-gray-900 text-base font-semibold w-[150px] min-w-[150px] max-w-[150px]'>
                      Location Tracking
                    </label>
                    <div className='w-full mt-4'>
                      <MapComponent selectedTimeSheet={selectedTimeSheet} />
                    </div>
                  </div>
                </>
              )}

            <div className='mt-10'>
              <div className='border border-gray-200 py-7 px-6 rounded-lg'>
                <div className='flex justify-between'>
                  <div className='flex items-center'>
                    <img
                      src={clockSvg}
                      alt='clock'
                      className='w-5 h-5 inline-block'
                    />
                    <div className='text-gray-900 font-semibold text-base ml-3'>
                      Time Approval
                    </div>
                  </div>
                  <div className='space-x-2 flex items-center'>
                    <CustomButton
                      variant='tertiary'
                      label='Approve'
                      type='submit'
                      showLoading={
                        (timesheetStatusChangeLoader &&
                          timesheetStatusChangeLoader.status ===
                            TIMESHEET_STATUS.APPROVED &&
                          timesheetStatusChangeLoader.isNext === false) ||
                        updateTimesheetLoader
                      }
                      isDisabled={
                        timesheetStatusChangeLoader ||
                        updateTimesheetLoader ||
                        isUserGracePeriodOver ||
                        updateTimesheetLoader
                      }
                      loaderCssClass={
                        timesheetStatusChangeLoader || updateTimesheetLoader
                          ? "text-black"
                          : ""
                      }
                      cssClass='hover:bg-gray-100 text-gray-500 text-base font-semibold bg-white border border-solid border-gray-200 py-3 px-5 rounded-lg shadow-sm'
                    />
                    <CustomButton
                      isDisabled={
                        timesheetStatusChangeLoader ||
                        updateTimesheetLoader ||
                        isUserGracePeriodOver
                      }
                      variant='tertiary'
                      label='Approve & Next'
                      type='button'
                      clickHandler={() => {
                        if (isUserGracePeriodOver) return;
                        handleSubmit(onSubmit)("approve-and-next");
                      }}
                      showLoading={
                        timesheetStatusChangeLoader &&
                        timesheetStatusChangeLoader.status ===
                          TIMESHEET_STATUS.APPROVED &&
                        timesheetStatusChangeLoader.isNext === true
                      }
                      loaderCssClass={
                        timesheetStatusChangeLoader ? "text-black" : ""
                      }
                      cssClass='hover:bg-green-100 text-green-700 text-base font-semibold bg-green-50 border border-solid border-gray-200 py-3 px-5 rounded-lg shadow-sm'
                    />
                    <CustomButton
                      isDisabled={
                        timesheetStatusChangeLoader ||
                        updateTimesheetLoader ||
                        isUserGracePeriodOver
                      }
                      variant='tertiary'
                      label='Reject Timesheet'
                      showLoading={
                        timesheetStatusChangeLoader &&
                        timesheetStatusChangeLoader.status ===
                          TIMESHEET_STATUS.REJECTED
                      }
                      loaderCssClass={
                        timesheetStatusChangeLoader ? "text-black" : ""
                      }
                      clickHandler={() => {
                        if (isUserGracePeriodOver) return;
                        handleChangeTimesheetStatus({
                          status: TIMESHEET_STATUS.REJECTED,
                        });
                      }}
                      cssClass='hover:bg-red-100 text-red-700 text-base font-semibold bg-red-50 border border-solid border-gray-200 py-3 px-5 rounded-lg shadow-sm'
                    />
                  </div>
                </div>
                <div className='mt-10'>
                  <div className='flex items-center justify-end'>
                    <span
                      tabIndex={0}
                      role='button'
                      onClick={() => {
                        if (isUserGracePeriodOver) return;

                        timesheetStatusChangeLoader === null &&
                          updateTimesheetLoader === false &&
                          handleChangeTimesheetStatus({
                            status: TIMESHEET_STATUS.DISCARDED,
                          });
                      }}
                      className={classNames(
                        "flex cursor-pointer items-center space-x-2 text-sm text-red-600 font-semibold",
                        isUserGracePeriodOver && "cursor-auto"
                      )}
                    >
                      {timesheetStatusChangeLoader !== null &&
                      timesheetStatusChangeLoader.status ===
                        TIMESHEET_STATUS.DISCARDED ? (
                        <Loader cssClass='text-black' />
                      ) : (
                        <img className='mr-2' src={deleteSvg} alt='delete' />
                      )}
                      Discard Timesheet
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default TimesheetContent;
