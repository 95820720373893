import React, { useState, useContext, useEffect, useCallback } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SideBar from "../../../../components/common/SideBar/SideBarNew";
import {
  PARENT_FORM_CATEGORY_SLUG,
  USER_ROLE,
  WORKER_PROFILE_TABS,
} from "../../../../Constants/constant";
import { LoginContext } from "../../../../helpers/LoginContext";
import WorkerDocument from "./Documents/index";
import WorkerSetting from "./WorkerSettings/Setting";
import WorkerProfileDetails from "./Profile";
import {
  HRM_BREADCRUMB,
  HRM_BREADCRUMB_PAGES,
} from "../../../../Constants/BreadcrumbConstant";
import Breadcrumb from "../../../../components/common/Breadcrumb/Breadcrumb";
import Tabs from "components/common/Enhansment/Tabs/Tabs";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import {
  workerHourlyRateSettingUpdateRequest,
  workerProfileDetailsFetchRequest,
  workerStatusUpdatedRequest,
} from "store/actions/sp.actions";
import { connect } from "react-redux";
import {
  getSelectedWorker,
  getWorkerHourlySettingLoader,
  getWorkerProfileDetailLoader,
  getWorkerStatusUpdateLoader,
} from "store/selector/sp.selector";
import { getFolderListForHRMLoadingSelector } from "store/selector/cos.selector";
import SubmittedFormView from "components/ServiceProvider/SubmittedFormsView/index";
import WorkerProfileHeader from "components/ServiceProvider/Participant/WorkerProfileHeader/WorkerProfileHeader";
import ParticipantProgressBar from "../../../../components/common/ProgressBarNew/ParticipantProgressBar";
import ProgressBarNew from "../../../../components/common/ProgressBarNew/ProgressBarNew";
import {
  addFiltersAndPagination,
  apiGetRequest,
} from "../../../../helpers/Requests";
import { roleAccess } from "helpers/RolesPermission";
import {
  BLK_PORTAL_PREMISSION_KEYS_CHILDREN,
  PERMISSION_KEYS,
} from "store/constants";
import ProgressBarHRM from "components/common/ProgressBarNew/HRMProgressBar";
import { selectedTabState } from "Utils/utils";

const WorkerProfile = (props) => {
  const {
    getFolderListForHRMLoading,
    workerHourlyRateSettingRequest,
    workerSettingCreateLoader,
    WorkerProfileDataRequest,
    workerProfileDetailLoader,
    selectedWorker,
    workerStatusUpdateLoader,
    workerStatusUpdated,
    slug,
  } = props;

  const location = useLocation();
  const { workerId, workerInfo } = useParams();
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);

  const [documentList, setDocumentList] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(WORKER_PROFILE_TABS[0].id);

  const [progressbarData, setProgressBar] = useState([]);
  const userType = localStorage.getItem("userType");
  const [keyStrength, setKeyStrength] = useState([]);
  const [isWorkerAvailablity, setRefreshPageForWorkerTimeSlot] =
    useState(false);

useEffect(()=>{
if(selectedTabState?.get()){
  setSelectedTab(selectedTabState?.get().id)
}
},[])

  useEffect(() => {
    localStorage.setItem("workerTab", selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (selectedWorker) {
      localStorage.setItem("selectedWorkerID", selectedWorker.id);
    }
  }, [selectedWorker]);

  useEffect(() => {
    const { state } = location;
    if ((!state || state === null) && !workerId) {
      navigate("/all-workers");
    } else if ((state && !state.hasOwnProperty("workerUUID")) || !workerId) {
      navigate("/all-workers");
    } else if (
      (state &&
        state.hasOwnProperty("workerUUID") &&
        state.workerUUID.length > 0) ||
      workerId
    ) {
      WorkerProfileDataRequest({
        profile_id: state && state?.workerUUID ? state?.workerUUID : workerId,
      });
    }
  }, [location.pathname, isWorkerAvailablity]);

  useEffect(() => {
    localStorage.setItem("workerTab", selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    getKeyStrengthData();
  }, []);

  const getKeyStrengthData = useCallback(async () => {
    const res = await apiGetRequest("/hrm/key-strength");
    if (res && res.status === 200) {
      setKeyStrength(res.data.data.key_strengths);
    }
  }, []);

  useEffect(() => {
    if (selectedWorker) {
      localStorage.setItem("selectedWorkerID", selectedWorker.id);
    }
  }, [selectedWorker]);

  const navigate = useNavigate();

  const [child, setChildId] = useState(null);
  const handleTab = (tab) => setSelectedTab(tab);

  useEffect(() => {
    if (child) {
      localStorage.setItem("workerTab", child);

      setSelectedTab(child);
    }
    if (child === null) {
      const { state } = location;
      if (state && state.hasOwnProperty("id")) {
        setSelectedTab(state.id);
      }
    }
  }, [child]);

  useEffect(() => {
    if (
      roleAccess(
        [],
        PERMISSION_KEYS.DOCUMENT,
        BLK_PORTAL_PREMISSION_KEYS_CHILDREN.DOCUMEMT.SHOW
      )
    ) {
      getEmployeeDocuments();
    }
  }, [selectedWorker?.uuid, userType]);

  const [openRequiredModalAgain, setOpenRequiredModalAgain] = useState(false);

  const getEmployeeDocuments = async (callFrom = null) => {
    try {
      if (!selectedWorker?.uuid) return;

      setIsLoading(true);
      let url = "";
      if (userType === "sp_standard_user") {
        url = addFiltersAndPagination(`/hrm/employee-docs`, {
          page: 1,
          per_page: 100,
        });
      } else {
        url = addFiltersAndPagination(`/sp/employee-docs`, {
          page: 1,
          per_page: 100,
        });
      }
      const response = await apiGetRequest(
        `${url}&profile_id=${selectedWorker?.uuid}`
      );
      const uploadedDoc = response?.data?.data?.employee_documents.data;
      setDocuments(uploadedDoc);
      if (!roleAccess([USER_ROLE.Sp_Standard_User])) {
        getDocumentsListForProgressBar();
      }
      if (callFrom && callFrom === "from-uploaded") {
        setOpenRequiredModalAgain(true);
      }
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      setIsLoading(false);
      if (
        callFrom &&
        callFrom === "from-uploaded" &&
        openRequiredModalAgain === false
      ) {
        setOpenRequiredModalAgain(true);
      }
    }
  };

  const [isFetchDocumentBarLoader, setIsFetchDocumentBarLoader] =
    useState(false);
  //to get documents list
  const getDocumentsListForProgressBar = async () => {
    try {
      setIsFetchDocumentBarLoader(true);
      const res = await apiGetRequest(
        `/hrm/employee/documents-list?employee_profile_uuid=${selectedWorker?.uuid}`
      );
      if (res && res.status === 200) {
        const workerComplianceSettings = res.data.data[0];
        const requiredDocs = res.data.data[1];
        setDocumentList(requiredDocs);

        setProgressBar(workerComplianceSettings);
      }
      setIsFetchDocumentBarLoader(false);
    } catch (error) {
      setIsFetchDocumentBarLoader(false);
      console.log({ error });
    }
  };

  // const getQuickBookEmployees = async () => {
  //   try {
  //     setisLoadingQuickBookEmployee(true);
  //     const response = await employeeApi.quickBooksEmployeeList();
  //     if (response && response.data) {
  //       setQuickBooksEmployees(
  //         response?.data?.map((x) => ({
  //           ...x,
  //           label: x.name,
  //           value: x.qb_id,
  //         })) || []
  //       );
  //     }
  //     setisLoadingQuickBookEmployee(false);
  //   } catch (err) {
  //     console.log("err", err);
  //     setisLoadingQuickBookEmployee(false);
  //   }
  // };

  const getTab = () => {
    switch (selectedTab) {
      case WORKER_PROFILE_TABS[0].id:
        return (
          <WorkerProfileDetails
            isUserGracePeriodOver={isUserGracePeriodOver}
            worker={selectedWorker}
            setSelectedTab={setSelectedTab}
            setRefreshPageForWorkerTimeSlot={setRefreshPageForWorkerTimeSlot}
            isWorkerAvailablity={isWorkerAvailablity}
          />
        );
      case WORKER_PROFILE_TABS[1].id:
        return (
          <WorkerDocument
            getDocumentsListForProgressBar={getDocumentsListForProgressBar}
            getFolderListForHRMLoading={getFolderListForHRMLoading}
            requiredDocuments={true}
            workerUUID={selectedWorker.uuid}
            worker={selectedWorker}
            documentList={documentList}
            isLoading={isLoading}
            documents={documents}
            setDocuments={setDocuments}
            getEmployeeDocuments={getEmployeeDocuments}
            openRequiredModal={openRequiredModalAgain}
            resetRequiredModalParentCheck={() => {
              setOpenRequiredModalAgain(false);
            }}
          />
        );
      // return <WorkerDocument workerUUID={selectedWorker.uuid} />;
      case WORKER_PROFILE_TABS[2].id:
        return (
          <WorkerSetting
            isUserGracePeriodOver={isUserGracePeriodOver}
            workerHourlyRateSettingRequest={workerHourlyRateSettingRequest}
            workerSettingCreateLoader={workerSettingCreateLoader}
            worker={selectedWorker}
            workerStatusUpdateLoader={workerStatusUpdateLoader}
            workerStatusUpdated={workerStatusUpdated}
            keyStrength={keyStrength}
            WorkerProfileDataRequest={WorkerProfileDataRequest}
            workerInfo={workerInfo}
            // quickBooksEmployees={quickBooksEmployees}
            // isLoadingQuickBookEmployee={isLoadingQuickBookEmployee}
            // syncQuickBooksEmployeesCall={syncQuickBooksEmployeesCall}
            // quickBooksBusinesses={quickBooksBusinesses}
            // isLoadingQuickBookBusiness={isLoadingQuickBookBusiness}
            // getQuickBookBusinesses={getQuickBookBusinesses}
            // getMyobBusiness={getMyobBusiness}
          />
        );
      case 5:
        return (
          <>
            {selectedWorker && selectedWorker.uuid ? (
              <SubmittedFormView
                role='hrm'
                parentSlug={PARENT_FORM_CATEGORY_SLUG.Compliance.SLUG}
                participantId={selectedWorker.id}
                supportPlansId={PARENT_FORM_CATEGORY_SLUG.Compliance.UUID}
                participantsUid={selectedWorker.uuid}
                title='Compliance'
              />
            ) : (
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            )}
          </>
        );
      case 6:
        return (
          <>
            <SubmittedFormView
              role='hrm'
              participantId={selectedWorker.id}
              supportPlansId={PARENT_FORM_CATEGORY_SLUG.Onboarding.UUID}
              participantsUid={selectedWorker.uuid}
              title='Onboarding'
              parentSlug={PARENT_FORM_CATEGORY_SLUG.Onboarding.SLUG}
            />
          </>
        );
      case 8:
        return (
          <>
            <SubmittedFormView
              role='hrm'
              participantId={selectedWorker.id}
              supportPlansId={PARENT_FORM_CATEGORY_SLUG.Other.UUID}
              participantsUid={selectedWorker.uuid}
              title='Other'
              parentSlug={PARENT_FORM_CATEGORY_SLUG.Other.SLUG}
            />
          </>
        );
      case 9:
        return (
          <>
            <SubmittedFormView
              role='hrm'
              supportPlansId={
                PARENT_FORM_CATEGORY_SLUG.Training_And_Development.UUID
              }
              participantsUid={selectedWorker?.uuid}
              title='Training And Development'
              participantId={selectedWorker?.id}
              parentSlug={
                PARENT_FORM_CATEGORY_SLUG.Training_And_Development.SLUG
              }
            />
          </>
        );

      case 18:
        return (
          <>
            <SubmittedFormView
              role='hrm'
              supportPlansId={
                PARENT_FORM_CATEGORY_SLUG.Performance_Management.UUID
              }
              participantsUid={selectedWorker?.uuid}
              title='Performance Management'
              participantId={selectedWorker?.id}
              parentSlug={PARENT_FORM_CATEGORY_SLUG.Performance_Management.SLUG}
            />
          </>
        );
      case 19:
        return (
          <>
            <SubmittedFormView
              role='hrm'
              participantId={selectedWorker.id}
              supportPlansId={PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.UUID}
              participantsUid={selectedWorker.uuid}
              title={PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.NAME}
              parentSlug={PARENT_FORM_CATEGORY_SLUG.Exit_Transaction.SLUG}
            />
          </>
        );
      default:
        return (
          <WorkerProfileDetails
            isUserGracePeriodOver={isUserGracePeriodOver}
            worker={selectedWorker}
          />
        );
    }
  };

  return (
    <>
      <div className='min-full '>
        <SideBar classes='bg-gradient' />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          } flex flex-col fullScreenHeight`}
        >
          <main className='flex-1'>
            {workerProfileDetailLoader ||
            !selectedWorker ||
            Object.keys(selectedWorker).length === 0 ? (
              <div
                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <>
                <main className='flex-1 w-full sticky top-0 z-[9]'>
                  <div className='sticky top-0 z-[9]'>
                    <WorkerProfileHeader
                      isUserGracePeriodOver={isUserGracePeriodOver}
                      selectedTab={selectedTab}
                      workerInfo={selectedWorker}
                    />
                  </div>
                </main>
                <div className='w-full'>
                  <div className='max-w-7xl mx-auto '>
                    <div className='sm:px-6 lg:px-8'>
                      <div className='mb-5 border-gray-200 py-3'>
                        <div className='rounded-lg bg-white shadow relative'>
                          <h2 className='sr-only' id='profile-overview-title'>
                            Profile Overview
                          </h2>
                        </div>
                      </div>

                      <div className='max-w-7xl mx-auto print:hidden'>
                        <div className='px-2 has-tooltip-progress bg-white flex justify-center cursor-pointer print:hidden'>
                          {!isFetchDocumentBarLoader &&
                            progressbarData.length > 0 && (
                              <ProgressBarHRM
                                progressBarDetails={progressbarData}
                                uuid={workerId}
                              />
                            )}
                          <ProgressBarNew
                            isLoading={isFetchDocumentBarLoader}
                            progressline={{
                              on_boarding_progress: progressbarData?.reduce(
                                (sum, item) => {
                                  return item.submitted
                                    ? sum + item.score
                                    : sum;
                                },
                                0
                              ),
                            }}
                          />
                        </div>
                      </div>
                      <div className='rounded-b-lg sticky top-0'>
                        <div className='border-gray-200 sticky top-0'>
                          <Breadcrumb
                            pages={HRM_BREADCRUMB(HRM_BREADCRUMB_PAGES.WORKER)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='bg-white shadow-lg rounded-lg'>
                      <div className='print:hidden sticky z-[8] top-[79px] mt-4'>
                        <div className='bloc-tabs bg-gradient py-4 rounded-t-lg text-white '>
                          <Tabs
                            tabs={WORKER_PROFILE_TABS}
                            selectedTab={selectedTab}
                            handleChange={handleTab}
                            setChildId={setChildId}
                          />
                        </div>
                      </div>
                      <div className='max-w-7xl mx-auto '>
                        <div className='sm:px-6 lg:px-8'>
                          <div>{getTab()}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </main>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  workerHourlyRateSettingRequest: workerHourlyRateSettingUpdateRequest,
  WorkerProfileDataRequest: workerProfileDetailsFetchRequest,
  workerStatusUpdated: workerStatusUpdatedRequest,
};
const mapStateToProps = (state) => {
  const workerSettingCreateLoader = getWorkerHourlySettingLoader(state);
  const workerProfileDetailLoader = getWorkerProfileDetailLoader(state);
  const selectedWorker = getSelectedWorker(state);
  const workerStatusUpdateLoader = getWorkerStatusUpdateLoader(state);
  const getFolderListForHRMLoading = getFolderListForHRMLoadingSelector(state);

  // export const getFolderListForHRMLoadingSelector = createSelector(
  //   cosStateData,
  //   (cos) => cos.get("removeFolderLoading") || false
  // );

  return {
    getFolderListForHRMLoading,
    workerSettingCreateLoader,
    workerProfileDetailLoader,
    selectedWorker,
    workerStatusUpdateLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkerProfile);
