import { connect } from "react-redux";
import ParticipantProfile from "./ParticipantProfile";
import {
  createClientTaskRequest,
  deleteClientTaskListRequest,
  fetchServiceSupportsForDeliveredServiceRequest,
  getCaseNotesRequest,
  getClientTaskListRequest,
  getCosParticipantsProfileRequest,
  getFundingSetupRequest,
  getRepoertDataRequest,
  // getSelectedFundingSetupRequest,
  supportCoordinatorsRequest,
  updateClientTaskListRequest,
  resetParticipantFundings,
  resetFundingParticipantProfileData,
  getSupportTrackListRequest,
  getSingleSupportTrackRequest,
  getContactListRequest,
  createNewPeriorityRequest,
  getCatogeryListRequest,
  getPeriorityListRequest,
  removeClientTaskSuccess,
  ClientTaskListUnmount,
  fundingSetupParticipantFosCosRequest,
  clearServiceSupportServicesForCaseNotes,
  getCaseNoteDetailRequest,
  updateCaseNoteRequest,
} from "../../../../store/actions/cos.actions";
import {
  getServiceSupportItems,
  getParticipantFundings,
  getParticipantFundingsList,
  getParticipantsProfile,
  getCosCaseNoteList,
  getCliesntTaskList,
  getSupportCoordinator,
  getCosCaseNoteListLoading,
  getParticipantFundingsLoading,
  getCliesntTaskListLoading,
  getReportDataSelector,
  getFundingListData,
  getFundingTableLoading,
  getSupportTractList,
  getSingleSupportTract,
  getServiceSupportItemsCaseNotes,
  getCaseNotesCreateLoadingSelector,
  getSingleSupportTrackLoadingSelector,
  cerateCaseNotesSuccessDataSelector,
  getCatogeryListSelector,
  getPeriorityListSelector,
  getContactListOPTSelector,
  getClientTaskResponseSelector,
  getServiceSupportDropDownOptions,
  updateClientTaskResponseSelector,
  clientTaskListLoadingSelector,
  fundingsForCosSelector,
  grtServiceSupportItemsForDeliveredLoading,
  getCaseNotesErrorSelector,
  reportLoadingSelector,
  getCOSLoader,
  getCaseNoteDetailLoader,
  getCaseNoteDataSelector,
  updateCaseNotesLoadingSelector,
  updateCaseNotesSuccessSelector,
  deleteCaseNoteSuccessSelector,
} from "../../../../store/selector/cos.selector";

const mapDispatchToProps = {
  getParticipantProfile: getCosParticipantsProfileRequest,
  getFundingSetup: getFundingSetupRequest,
  // getSelectedFunding: getSelectedFundingSetupRequest,
  createTaskRequest: createClientTaskRequest,
  gettaskList: getClientTaskListRequest,
  getAssignedToList: supportCoordinatorsRequest,
  updateClientTask: updateClientTaskListRequest,
  deleteClientTask: deleteClientTaskListRequest,
  getServiceSupportForDS: fetchServiceSupportsForDeliveredServiceRequest,
  getCaseNotes: getCaseNotesRequest,
  getReportRequest: getRepoertDataRequest,
  getSupportTrackList: getSupportTrackListRequest,
  getSelectedSupport: getSingleSupportTrackRequest,
  resetParticipantFundings,
  resetFundingParticipantProfileData,
  createNewPeriorityRequest,
  getContactListRequest,
  getCatogeryListRequest,
  getPeriorityListRequest,
  removeClientTaskSuccess,
  ClientTaskListUnmount,
  fundingSetupParticipantFosCosRequest,
  clearServiceSupportServicesForCaseNotes,
  getCaseNoteDetailRequest,
  updateCaseNoteRequest,
};

const mapStateToProps = (state) => {
  const updateCaseNotesSuccess = updateCaseNotesSuccessSelector(state);
  const deleteCaseNotesSuccess = deleteCaseNoteSuccessSelector(state);
  const updateCaseNotesLoading = updateCaseNotesLoadingSelector(state);
  const getCaseNoteData = getCaseNoteDataSelector(state);
  const getCaseNoteDetailLoading = getCaseNoteDetailLoader(state);
  const cosParticipantsProfile = getParticipantsProfile(state);
  const cosParticipantsProfileLoader = getCOSLoader(state);
  const participantFundingsList = getParticipantFundingsList(state);
  const participantFunding = getParticipantFundings(state);
  const caseNotesDropDown = getServiceSupportItems(state);
  const casNotesList = getCosCaseNoteList(state);
  const casNotesListLoading = getCosCaseNoteListLoading(state);
  const clientTaskList = getCliesntTaskList(state);
  const clientTaskListLoading = getCliesntTaskListLoading(state);
  const supportCoordinator = getSupportCoordinator(state);
  const participantFundingsLoading = getParticipantFundingsLoading(state);
  const fundingList = getFundingListData(state);
  const fundingTableLoading = getFundingTableLoading(state);
  const reportData = getReportDataSelector(state);
  const supportTractList = getSupportTractList(state);
  const singleSupportTract = getSingleSupportTract(state);
  const caseNotesCreateLoading = getCaseNotesCreateLoadingSelector(state);
  const singleSupportTrackLoading = getSingleSupportTrackLoadingSelector(state);
  const serviceSupportItemsCaseNotes = getServiceSupportItemsCaseNotes(state);
  const cerateCaseNotesSuccessData = cerateCaseNotesSuccessDataSelector(state);
  const CatogeryList = getCatogeryListSelector(state);
  const periorityList = getPeriorityListSelector(state);
  const ContactList = getContactListOPTSelector(state);
  const clientTaskResponse = getClientTaskResponseSelector(state);
  const serviceSupportDropDownOptions = getServiceSupportDropDownOptions(state);
  const updateClientTaskResponse = updateClientTaskResponseSelector(state);
  const createclientTaskListLoading = clientTaskListLoadingSelector(state);
  const fundingsForCos = fundingsForCosSelector(state);
  const fetchServiceSupportItemsForDeliveredLoader =
    grtServiceSupportItemsForDeliveredLoading(state);
  const caseNoteerror = getCaseNotesErrorSelector(state);
  const reportLoading = reportLoadingSelector(state);

  return {
    singleCaseNoteData: getCaseNoteData,
    updateCaseNotesLoading,
    getCaseNoteDetailLoading,
    reportLoading,
    fetchServiceSupportItemsForDeliveredLoader,
    fundingsForCos,
    caseNoteerror,
    createclientTaskListLoading,
    updateClientTaskResponse,
    serviceSupportDropDownOptions,
    CatogeryList,
    clientTaskResponse,
    periorityList,
    cerateCaseNotesSuccessData,
    participantFundingsLoading,
    singleSupportTrackLoading,
    reportData,
    supportTractList,
    cosParticipantsProfile,
    participantFundingsList,
    participantFunding,
    caseNotesDropDown,
    caseNotesList: casNotesList,
    ContactList,
    // fetchPlanBudget,
    clientTaskList,
    supportCoordinator,
    casNotesListLoading,
    clientTaskListLoading,
    caseNotesCreateLoading,
    singleSupportTrack: singleSupportTract,
    fundingTableLoading,
    serviceSupportItemsCaseNotes,
    fundingList,
    updateCaseNotesSuccess,
    deleteCaseNotesSuccess,
    cosParticipantsProfileLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantProfile);
