import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "helpers/LoginContext";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import SideBarNew from "components/common/SideBar/SideBarNew";
import Header from "components/common/Header/Header";
import Breadcrumb from "components/common/Breadcrumb";
import {
  SETTINGS_BREADCRUMB_PAGES,
  SP_SETTING_BREADCRUMB,
} from "Constants/BreadcrumbConstant";
import { FaQuestionCircle } from "react-icons/fa";
import { Controller, useForm } from "react-hook-form";
import SwitchWithIcon from "components/common/SwitchWithIcon";
import { Tooltip } from "react-tooltip";

import SelectOption from "components/common/Select/Select";
import {
  calendarViewOptions,
  SELECT_TIME_PREFERENCE_TIMESHEET,
  TimeIntervals,
} from "Constants/constant";
import DateRangeSelect from "components/common/Select/DateRangeSelect";

import { apiGetRequest } from "helpers/Requests";
import { URL_ROSTERING_GET_KM_CODE_LIST_IN_SETTING } from "store/services/URL";

const RosteringSetting = ({
  spRosteringSettingLoader,
  spRosteringSetting,
  spRosteringSettingsRequest,
  spRosteringSettingsUpdateRequest,
  spRosteringSettingUpdateLoader,
}) => {
  const { sideBarPadding } = useContext(LoginContext);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
    control,
  } = useForm();

  useEffect(() => {
    spRosteringSettingsRequest();
  }, []);

  useEffect(() => {
    reset(spRosteringSetting);
  }, [spRosteringSetting]);

  const watchFormData = watch();

  const [selectedTimePreference, setSelectedTimePreference] = useState(null);
  const [selectedKMCode, setSelectedKMCode] = useState(null);
  const [KMcodes, setKMcodes] = useState([]);

  useEffect(() => {
    if (spRosteringSetting?.time_display_preference) {
      const value = SELECT_TIME_PREFERENCE_TIMESHEET?.filter(
        (item) => item?.value === spRosteringSetting?.time_display_preference
      );
      setSelectedTimePreference(value);
    }
  }, [spRosteringSetting?.time_display_preference]);

  useEffect(() => {
    apiGetRequest(URL_ROSTERING_GET_KM_CODE_LIST_IN_SETTING).then(
      (response) => {
        if (response?.data) {
          const value = response?.data?.data?.items?.map((item) => ({
            label: `${item?.support_item_number} ${item?.support_item_name}`,
            value: item?.support_item_number,
          }));

          setKMcodes(value);
        }
      }
    );
  }, []);

  useEffect(() => {
    if (spRosteringSetting?.default_km_code) {
      const value = KMcodes?.filter(
        (item) => item?.value === spRosteringSetting?.default_km_code
      );
      setSelectedKMCode(value);
    }
  }, [spRosteringSetting?.default_km_code, KMcodes]);
  const onSubmit = (data) => {
    try {
      const payload = {
        // apply_alternate_rate: data.apply_alternate_rate,
        // default_alternate_rate: data.default_alternate_rate,
        apply_km_to_invoice: data.apply_km_to_invoice,
        default_km_code: selectedKMCode?.value,
        participant_signature: data.participant_signature,
        shift_acceptance_required: data.shift_acceptance_required,
        worker_tracking: data.worker_tracking,
        timesheet_invoices: data.timesheet_invoices,
        roster_default_view: data.roster_default_view,
        is_radius: data.is_radius,
        shift_can_start_before: data.shift_can_start_before,
        time_display_preference: selectedTimePreference?.value,
        weekly_shift_update_email_notification:
          data?.weekly_shift_update_email_notification,
          default_timesheet_breakdown: data?.default_timesheet_breakdown
      };
      if (
        data.calendar_type?.value === "previous" ||
        data.calendar_type?.value === "current"
      ) {
        Object.assign(payload, {
          calendar_type: "fortnightly",
          default_fortnight:
            data.calendar_type?.value === "previous" ? "f2" : "f1",
        });
      } else if (data.calendar_type?.value === "weekly") {
        Object.assign(payload, {
          calendar_type: "weekly",
          default_fortnight: null,
        });
      }

      spRosteringSettingsUpdateRequest(payload);
    } catch (err) {
      console.log("err", err);
    }
  };

  // console.log("watchFormData", watchFormData);

  const getToolTipContent = () => {
    return (
      <div className='flex flex-col gap-4 p-2'>
        <p className='text-xs text-white flex items-center '>
          <SwitchWithIcon checked={true} disabled={true} cssClass='mr-2' />
          Worker need to manually accept this shift
        </p>
        <p className='text-xs text-white flex items-center '>
          <SwitchWithIcon checked={false} disabled={true} cssClass='mr-2' />{" "}
          Shifts will be automatically accepted without requiring worker manual
          confirmation
        </p>
      </div>
    );
  };

  return (
    <div>
      <div className='min-h-screen bg-gray-100'>
        <SideBarNew />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <Header
            title='Rostering Settings'
            breadcrumb={
              <Breadcrumb
                pages={SP_SETTING_BREADCRUMB(
                  SETTINGS_BREADCRUMB_PAGES.ROSTERING_SETTING
                )}
              />
            }
          />
          <section className='container mx-auto sm:px-6 lg:px-8 mt-3'>
            {spRosteringSettingLoader ? (
              <div
                className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
              >
                <CircularLoader
                  classes='flex justify-center items-center'
                  classloader='loader-l'
                />
              </div>
            ) : (
              <div className='mt-5 md:mt-0 md:col-span-2'>
                <div className='max-w-7xl mx-auto '>
                  <div className='shadow sm:rounded-md sm:overflow-hidden p-4 bg-white shadow-md'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className=''>
                        <h5 className='px-2 pb-2 font-bold'>
                          Schedule Settings
                        </h5>
                        <div className='grid grid-cols-2 gap-x-2 gap-y-6'>
                          <div className='col-span-1 px-2'>
                            <div className='flex justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Default Scheduled view
                              </label>
                              <Controller
                                name='roster_default_view'
                                control={control}
                                defaultValue={
                                  watchFormData?.roster_default_view || true
                                }
                                render={({ field }) => {
                                  // console.log("field", field);
                                  const feildValue = field.value?.value
                                    ? field.value.value
                                    : field.value;
                                  return (
                                    <SelectOption
                                      cssClass=''
                                      isSearch={false}
                                      selectedOption={
                                        feildValue
                                          ? calendarViewOptions?.find(
                                              (x) =>
                                                x.value === "worker-view" &&
                                                feildValue === "worker"
                                            )?.value
                                            ? calendarViewOptions[1]
                                            : calendarViewOptions[0]
                                          : null
                                      }
                                      handleChange={(e) => {
                                        field.onChange(e?.value);
                                        setValue(
                                          "roster_default_view",
                                          e?.value === "worker-view"
                                            ? "worker"
                                            : "participant"
                                        );
                                      }}
                                      data={calendarViewOptions}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Default Calendar View
                              </label>
                              <Controller
                                name='calendar_type'
                                control={control}
                                defaultValue={
                                  watchFormData?.calendar_type || true
                                }
                                render={({ field }) => (
                                  <DateRangeSelect
                                    defaultType={
                                      field.value?.value
                                        ? field.value?.value === "previous" ||
                                          field.value?.value === "current"
                                          ? "fortnightly"
                                          : "weekly"
                                        : field.value
                                    }
                                    defaultFortnightView={
                                      watchFormData?.default_fortnight
                                    }
                                    onChangeView={(e) => {
                                      setValue("calendar_type", e);
                                      field.onChange(e);
                                    }}
                                    onlyView={true}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Default Worker Tracking
                              </label>
                              <Controller
                                name='worker_tracking'
                                control={control}
                                defaultValue={
                                  watchFormData?.worker_tracking || true
                                }
                                render={({ field }) => (
                                  <SwitchWithIcon
                                    onChange={(value) => {
                                      field.onChange(value);
                                      setValue("worker_tracking", value);
                                    }}
                                    checked={field.value}
                                    disabled={false}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Default Participant's Signature
                              </label>
                              <Controller
                                name='participant_signature'
                                control={control}
                                defaultValue={
                                  watchFormData?.participant_signature || false
                                }
                                render={({ field }) => (
                                  <SwitchWithIcon
                                    onChange={(value) => {
                                      field.onChange(value);
                                      setValue("participant_signature", value);
                                    }}
                                    checked={field.value}
                                    disabled={false}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1  sm:text-xs flex items-center justify-center'>
                                Default Shift Acceptance Required
                                <a data-tooltip-id='my-tooltip'>
                                  <FaQuestionCircle
                                    data-tip
                                    data-tooltip-id='manualAcceptHelp'
                                    className='ml-1 text-gray-500 cursor-pointer'
                                  />
                                </a>
                              </label>
                              <Tooltip
                                id='my-tooltip'
                                place='top'
                                content={getToolTipContent()}
                                variant='dark'
                                className='z-[501]'
                              />
                              <Controller
                                name='shift_acceptance_required'
                                control={control}
                                defaultValue={
                                  watchFormData?.shift_acceptance_required
                                }
                                render={({ field }) => {
                                  return (
                                    <SwitchWithIcon
                                      onChange={(value) => {
                                        field.onChange(value);
                                        setValue(
                                          "shift_acceptance_required",
                                          value
                                        );
                                      }}
                                      checked={field.value}
                                      disabled={false}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs flex items-center justify-center'>
                                Default Enforce Distance Restriction
                              </label>
                              <Controller
                                name='is_radius'
                                control={control}
                                defaultValue={watchFormData?.is_radius || false}
                                render={({ field }) => (
                                  <div className='relative group'>
                                    <SwitchWithIcon
                                      onChange={(value) => {
                                        field.onChange(value);
                                        setValue("is_radius", value);
                                      }}
                                      checked={field.value}
                                      disabled={false}
                                      className='relative'
                                      title={
                                        field.value
                                          ? "Restrict worker to start shift within 100 meters of the participant's address."
                                          : "Allow worker to start the shift from anywhere"
                                      }
                                    />
                                    <div
                                      className={`absolute -left-72 top-1/2 transform -translate-y-1/2 w-64 p-2 rounded-md text-xs text-white bg-gray-800 shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300`}
                                    >
                                      {field.value
                                        ? "Restrict worker to start shift within 100 meters of the participant's address."
                                        : "Allow worker to start the shift from anywhere"}
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                          </div>

                          {/* <div className='col-span-1 px-2' /> */}
                          <div className='col-span-1 px-2'>
                            <div className='flex  justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs flex items-center justify-center '>
                                Set Interval for Shift Start
                                <a data-tooltip-id='interval-tooltip'>
                                  <FaQuestionCircle
                                    data-tip
                                    data-tooltip-id='interval-tooltip'
                                    className='ml-1 text-gray-500 cursor-pointer'
                                  />
                                </a>
                              </label>
                              <Tooltip
                                id='interval-tooltip'
                                place='top'
                                content='Based on the selected duration, the worker can start the shift before the scheduled time.'
                                variant='dark'
                                className='z-[501]'
                              />
                              <Controller
                                name='shift_can_start_before'
                                control={control}
                                defaultValue={
                                  watchFormData?.shift_can_start_before || "60"
                                }
                                render={({ field }) => (
                                  <select
                                    className='text-sm rounded-md border border-gray-300 border border-gray-300 text-left w-[25%]'
                                    {...register("shift_can_start_before", {
                                      value: "60",
                                    })}
                                  >
                                    {TimeIntervals.map((option, index) => (
                                      <option
                                        key={`${index}-${option.value}`}
                                        value={option.value}
                                    
                                      >
                                        {option.label}
                                      </option>
                                    ))}
                                  </select>
                                )}
                              />
                            </div>
                          </div>
                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs flex items-center justify-center'>
                                Do you want to receive Weekly Shift Update
                                Notifications via Email?
                              </label>
                              <Controller
                                name='weekly_shift_update_email_notification'
                                control={control}
                                defaultValue={
                                  watchFormData?.weekly_shift_update_email_notification ||
                                  false
                                }
                                render={({ field }) => (
                                  <div className='relative group'>
                                    <SwitchWithIcon
                                      onChange={(value) => {
                                        field.onChange(value);
                                        setValue(
                                          "weekly_shift_update_email_notification",
                                          value
                                        );
                                      }}
                                      checked={field.value}
                                      disabled={false}
                                      className='relative'
                                      title={
                                        field.value
                                          ? "Restrict worker to start shift within 100 meters of the participant's address."
                                          : "Allow worker to start the shift from anywhere"
                                      }
                                    />
                                  </div>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='w-full h-0.5 mx-1 bg-gray-300 my-8'></div>

                      <div className=''>
                        <h5 className='px-2 pb-2 font-bold'>
                          Timesheet Settings
                        </h5>
                        <div className='grid grid-cols-2 gap-x-2 gap-y-6'>
                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Show Invoice section in Timesheet
                              </label>
                              <Controller
                                name='timesheet_invoices'
                                control={control}
                                defaultValue={
                                  watchFormData?.timesheet_invoices || false
                                }
                                render={({ field }) => (
                                  <SwitchWithIcon
                                    onChange={(value) => {
                                      field.onChange(value);
                                      setValue("timesheet_invoices", value);
                                    }}
                                    checked={field.value}
                                    disabled={false}
                                  />
                                )}
                              />
                            </div>
                          </div>

                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                                Default Apply KMs to Invoices
                              </label>
                              <Controller
                                name='apply_km_to_invoice'
                                control={control}
                                defaultValue={
                                  watchFormData?.apply_km_to_invoice || false
                                }
                                render={({ field }) => (
                                  <SwitchWithIcon
                                    onChange={(value) => {
                                      field.onChange(value);
                                      setValue("apply_km_to_invoice", value);
                                    }}
                                    checked={field.value}
                                    disabled={false}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className='col-span-1 px-2'>
                            <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs flex items-center justify-center'>
                                Select Time Preference for Timesheet Breakdown{" "}
                                <a data-tooltip-id='time-preference-tooltip'>
                                  <FaQuestionCircle
                                    data-tip
                                    data-tooltip-id='time-preference-tooltip'
                                    className='ml-1 text-gray-500 cursor-pointer'
                                  />
                                </a>
                              </label>
                              <Tooltip
                                id='time-preference-tooltip'
                                place='top'
                                content='Timesheet breakdowns will be generated based on the chosen time preference.'
                                variant='dark'
                                className='z-[501]'
                              />
                              <div className='w-[40%]'>
                                <SelectOption
                                  data={SELECT_TIME_PREFERENCE_TIMESHEET}
                                  selectedOption={selectedTimePreference}
                                  handleChange={setSelectedTimePreference}
                                />
                              </div>
                            </div>
                          </div>
                          {watchFormData?.apply_km_to_invoice && (
                            <div className='col-span-1 px-2'>
                              <div className='flex items-center justify-between'>
                                <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs flex items-center justify-center'>
                                  Default Select KM Code{" "}
                                </label>

                                <div className='w-[60%]'>
                                  <SelectOption
                                    data={KMcodes}
                                    selectedOption={selectedKMCode}
                                    handleChange={setSelectedKMCode}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          <div className='col-span-1 px-2'>
                          <div className='flex items-center justify-between'>
                              <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                              Default Shift Breakdown to Timesheet
                              </label>
                              <Controller
                                name='default_timesheet_breakdown'
                                control={control}
                                defaultValue={
                                  watchFormData?.default_timesheet_breakdown || false
                                }
                                render={({ field }) => (
                                  <SwitchWithIcon
                                    onChange={(value) => {
                                      field.onChange(value);
                                      setValue("default_timesheet_breakdown", value);
                                    }}
                                    checked={field.value}
                                    disabled={false}
                                  />
                                )}
                              />
                            </div>

                          </div>
                        </div>
                      </div>
                      <button
                        id='rosteringSettingsBtnUpdate'
                        type='submit'
                        className='hidden sr-only'
                      />
                    </form>
                    <div className='mt-6 flex justify-end'>
                      <CustomButton
                        showLoading={spRosteringSettingUpdateLoader}
                        isDisabled={spRosteringSettingUpdateLoader}
                        variant='primary'
                        clickHandler={() => {
                          const buttonRef = document.getElementById(
                            "rosteringSettingsBtnUpdate"
                          );
                          if (buttonRef) {
                            buttonRef.click();
                          }
                        }}
                        label='Update Changes'
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default RosteringSetting;
