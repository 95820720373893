import { roleAccess } from "helpers/RolesPermission";
import { PARTICIPANT_PROFILE_TABS, USER_ROLE } from "./constant";

export const HRM_BREADCRUMB = (pageName) => {
  const obj = [{ name: "HRM Dashboard", href: "/hrm" }];
  if (pageName === HRM_BREADCRUMB_PAGES.ADD_APPLICANT) {
    obj.push(
      { name: "All Applicant", href: "/hrm/all-applicants", state: null },
      { name: "Add Applicant", href: "/add-applicant", state: null }
    );
  } else if (pageName === HRM_BREADCRUMB_PAGES.ALL_APPLICANT) {
    obj.push({
      name: "All Applicant",
      href: "/hrm/all-applicants",
      state: null,
    });
  } else if (pageName === HRM_BREADCRUMB_PAGES.SINGLE_APPLICANT) {
    obj.push(
      { name: "All Applicant", href: "/hrm/all-applicants", state: null },
      { name: "Applicant Profile", href: "/hrm/applicant-profile", state: null }
    );
  } else if (pageName === HRM_BREADCRUMB_PAGES.ASSESSMENT_APPLICANT) {
    obj.push(
      { name: "All Applicant", href: "/hrm/all-applicants", state: null },
      {
        name: "Applicant Profile",
        href: "/hrm/applicant-profile",
        state: null,
      },
      {
        name: "Applicant Assesment",
        href: "/hrm/applicant-assessment",
        state: null,
      }
    );
  } else if (pageName === HRM_BREADCRUMB_PAGES.TEAM_MANAGMENT) {
    obj.push({
      name: "Team Management",
      href: "/hrm/team-management",
      state: null,
    });
  } else if (pageName === HRM_BREADCRUMB_PAGES.WORKER) {
    obj.push(
      { name: "Team Management", href: "/hrm/team-management", state: null },
      { name: "Team Member Profile", href: "#", state: null }
    );
  } else if (pageName === HRM_BREADCRUMB_PAGES.MANAGER_LIST) {
    obj.push({ name: "All Primary Portal Users", href: "/hrm/all-managers", state: null });
  } else if (pageName === HRM_BREADCRUMB_PAGES.ADD_MANAGER) {
    obj.push({ name: "Add Primary Portal User", href: "/add-manager", state: null });
  }
  return obj;
};

export const COS_BREADCRUMB = (pageName) => {
  const obj = [{ name: "COS Dashboard", href: "/cos/dashboard" }];

  if (pageName === COS_BREADCRUMB_PAGES.CREATE_NDIS_CLAIMS) {
    obj.push(
      {
        name: "NDIS Claims",
        href: "/cos/ndis-claims",
        state: null,
      },
      {
        name: "Create NDIS Claim",
        href: "/cos/ndis-claims-new",
        state: null,
      }
    );
  }
  if (pageName === COS_BREADCRUMB_PAGES.CREATED_NDIS_CLAIMS_REVIEW) {
    obj.push(
      {
        name: "NDIS Claims",
        href: "/cos/ndis-claims",
        state: null,
      },
      {
        name: "NDIS Claim Review",
        href: "#",
        state: null,
      }
    );
  }

  if (pageName === COS_BREADCRUMB_PAGES.CREATED_NDIS_CLAIMS) {
    obj.push({
      name: "NDIS Claims",
      href: "/cos/ndis-claims",
      state: null,
    });
  }
  if (pageName === COS_BREADCRUMB_PAGES.CREATED_COS_INVOICE) {
    obj.push({
      name: "Cos Invoices",
      href: "/cos/invoice",
      state: null,
    });
  }
  if (pageName === COS_BREADCRUMB_PAGES.CREATE_COS_INVOICE) {
    obj.push(
      {
        name: "Cos Invoices",
        href: "/cos/invoice",
        state: null,
      },
      {
        name: "Create Cos Invoice",
        href: "/cos/invoice-new",
        state: null,
      }
    );
  }
  if (pageName === COS_BREADCRUMB_PAGES.CASE_NOTES_HISTORY) {
    obj.push({
      name: "Case Notes History",
      href: "/cos/casenotes-history",
      state: null,
    });
  }

  if (pageName === COS_BREADCRUMB_PAGES.SERVICE_CATALOG) {
    obj.push({
      name: "Catalogue Services",
      href: "/cos/catalog-services",
      state: null,
    });
  }

  if (pageName === COS_BREADCRUMB_PAGES.EXTERNAL_SERVICE_PROVIDERS) {
    obj.push({
      name: "External Service Providers",
      href: "/cos/external-service-provider",
      state: null,
    });
  }

  if (pageName === COS_BREADCRUMB_PAGES.COS_REPORTING) {
    obj.push({
      name: "Reporting",
      href: "/cos/reporting",
      state: null,
    });
  }

  if (pageName === COS_BREADCRUMB_PAGES.PARTICIPANT_LIST) {
    obj.push({
      name: "Participants List",
      href: "#",
      state: null,
    });
  }

  if (pageName === COS_BREADCRUMB_PAGES.COS_SETTING) {
    obj.push({
      name: "Settings",
      href: "/cos/settings",
      state: null,
    });
  }

  if (pageName === COS_BREADCRUMB_PAGES.COS_PRIORITY_SETTING) {
    obj.push({
      name: "Settings",
      href: "/cos/settings",
      state: null,
    });
    obj.push({
      name: "Task Priorities",
      href: "#",
      state: null,
    });
  }

  if (pageName === COS_BREADCRUMB_PAGES.COS_CONTACT_SETTING) {
    obj.push({
      name: "Settings",
      href: "/cos/settings",
      state: null,
    });
    obj.push({
      name: "Note Contact Type",
      href: "#",
      state: null,
    });
  }

  if (pageName === COS_BREADCRUMB_PAGES.COS_CATEGORIES_SETTING) {
    obj.push({
      name: "Settings",
      href: "/cos/settings",
      state: null,
    });
    obj.push({
      name: "Task Categories",
      href: "#",
      state: null,
    });
  }

  if (pageName === COS_BREADCRUMB_PAGES.COS_INVOICE_REVIEW) {
    obj.push({
      name: "Cos Invoices",
      href: "/cos/invoice",
      state: null,
    });
    obj.push({
      name: "Invoice Preview",
      href: "#",
      state: null,
    });
  }

  if (pageName === COS_BREADCRUMB_PAGES.PARTICIPANTS_LIST) {
    obj.push({
      name: "Participant List",
      href: "/cos/participant-list",
      state: null,
    });
  }

  if (pageName === COS_BREADCRUMB_PAGES.PARTICIPANT_PROFILE) {
    obj.push(
      { name: "Participants List", href: "/cos/participant-list", state: null },
      {
        name: "Participant Profile",
        href: "#",
        state: null,
        addAdditionalParams: true,
      }
    );
  }
  return obj;
};

export const EMPLOYEE_BREADCRUMB = (pageName) => {
  const obj = [{ name: "Dashboard", href: "/dashboard" }];
  if (pageName === EMPLOYEE_BREADCRUMB_PAGES.ADD_DOCUMENTS) {
    obj.push({ name: "Documents", href: "/documents", state: null });
  } else if (pageName === EMPLOYEE_BREADCRUMB_PAGES.ALL_PARTICIPANTS) {
    obj.push({ name: "All Participants", href: "/participants", state: null });
  } else if (pageName === EMPLOYEE_BREADCRUMB_PAGES.POLICIES) {
    obj.push({ name: "Policies", href: "/employee-policies", state: null });
  } else if (pageName === EMPLOYEE_BREADCRUMB_PAGES.SETTINGS) {
    obj.push({ name: "Settings", href: "/settings", state: null });
  } else if (pageName === EMPLOYEE_BREADCRUMB_PAGES.COMPLAINT) {
    obj.push({ name: "Complaint", href: "/complaints", state: null });
  } else if (pageName === EMPLOYEE_BREADCRUMB_PAGES.FEEDBACK) {
    obj.push({ name: "Feedback", href: "/feedback", state: null });
  } else if (pageName === EMPLOYEE_BREADCRUMB_PAGES.ASK_QUESTION) {
    obj.push({ name: "Ask a Question", href: "/ask-a-question", state: null });
  } else if (pageName === EMPLOYEE_BREADCRUMB_PAGES.DASHBOARD) {
    obj.push({ name: "Dashboard", href: "/dashboard", state: null });
  } else if (pageName === EMPLOYEE_BREADCRUMB_PAGES.SUBMITTED_FORM) {
    obj.push({
      name: "Submitted Forms",
      href: "/submitted-forms",
      state: null,
    });
  }
  return obj;
};

export const FUNDING_BREADCRUMB = (pageName, selectedFunding) => {
  const obj = [{ name: "COS Dashboard", href: "/dashboard" }];
  if (pageName === FUNDING_PAGES.PARTICIPANTS_PROFILE) {
    obj.push({
      name: "Participant Profile",
      href: `/${
        roleAccess([USER_ROLE.Sp_Standard_User])
          ? "participant-profile"
          : "sp-profile"
      }/${selectedFunding?.participant?.uuid}`,
      state: {
        selectedTab: PARTICIPANT_PROFILE_TABS.find((x) => x.id === 17),
      },
    });
    obj.push({
      name: `${selectedFunding.contract_id}`,
      href: `/cos/participant-funding/${selectedFunding?.uuid}/${selectedFunding?.participant?.uuid}`,
      state: null,
    });
  }
  return obj;
};

export const SUPER_ADMIN_BREADCRUMB = (pageName, routeRole) => {
  const obj = [{ name: "Dashboard", href: "/dashboard" }];
  if (pageName === SUPER_ADMIN_BREADCRUMBS_PAGES.CREATE_FORM_TEMPLATE) {
    obj.push({
      name: "Forms List",
      href:
        routeRole === "sil"
          ? `/forms-accommodation`
          : routeRole === "hrm"
          ? `/forms-list-hrm`
          : `/forms-list`,
      state: null,
    });
    obj.push({
      name: "New Form",
      href: `#`,
      state: null,
    });
  }
  if (pageName === SUPER_ADMIN_BREADCRUMBS_PAGES.UPDATE_FORM_TEMPLATE) {
    obj.push({
      name: "Forms List",
      href:
        routeRole === "sil"
          ? `/forms-accommodation`
          : routeRole === "hrm"
          ? `/forms-list-hrm`
          : `/forms-list`,
      state: null,
    });
    obj.push({
      name: "Update Form",
      href: `#`,
      state: null,
    });
  }
  return obj;
};

export const ROSTERING_BREADCRUMB = (pageName) => {
  const obj = [{ name: "Rostering Dashboard", href: "/rostering/dashboard" }];
  if (pageName === ROSTERING_BREADCRUMB_PAGES.SCHEDULE) {
    obj.push({ name: "Schedule", href: "/rostering/schedule" });
  }
  if (pageName === ROSTERING_BREADCRUMB_PAGES.TIMESHEET) {
    obj.push({ name: "Timesheet", href: "/rostering/timesheet" });
  }
  if (pageName === ROSTERING_BREADCRUMB_PAGES.EXPORTTIMESHEET) {
    obj.push({ name: "Export Timesheet", href: "/rostering/timesheetexport" });
  }
  return obj;
};
export const ROSTERING_INVOICING_BREADCRUMB = (pageName) => {
  const obj = [{ name: "Invoice Dashboard", href: "/rostering/invoicing" }];

  if (
    pageName === ROSTERING_INVOICING_BREADCRUMB_PAGES.CREATE_ROSTER_INVOICING
  ) {
    obj.push({
      name: "Create Invoice",
      href: "/rostering/create-roster-invoice",
    });
  }
  if (
    pageName === ROSTERING_INVOICING_BREADCRUMB_PAGES.ROSTER_INVOICE_PREVIEW
  ) {
    obj.push({
      name: "Roster Invoice Preview",
      href: "#",
    });
  }

  return obj;
};

export const REPORTING_BREADCRUMB = (pageName) => {
  const obj = [{ name: "Reporting Dashboard", href: "/reporting" }];

  if (pageName === REPORTING_BREADCRUMB_PAGES.ROC_REPORTS) {
    obj.push({
      name: "ROC Reports",
      href: "/reporting/roc-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.KYP_REPORTS) {
    obj.push({
      name: "KYP Reports",
      href: "/reporting/kyp-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.ROC_WEEKLY_REPORTS) {
    obj.push({
      name: "ROC Weekly Reports",
      href: "/reporting/roc-weekly-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.CASE_NOTES_REPORTS) {
    obj.push({
      name: "Case Notes Reports",
      href: "/reporting/case-notes-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.BSP_REPORTS) {
    obj.push({
      name: "BSP Reports",
      href: "/reporting/bsp-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.COS_REPORTS) {
    obj.push({
      name: "COS Case Note Reports",
      href: "/reporting/cos-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.FUNDING_REPORTS) {
    obj.push({
      name: "COS Funding Reports",
      href: "/reporting/funding-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.PROGRESS_REPORTS) {
    obj.push({
      name: "Progress Reports",
      href: "/reporting/progress-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.DETAILED_INVOICING_REPORTS) {
    obj.push({
      name: "Detailed Invoicing Reports",
      href: "/reporting/detailed-invoicing-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.SUMMARY_INVOICING_REPORTS) {
    obj.push({
      name: "Summary Invoicing Reports",
      href: "/reporting/summary-invoicing-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.RISK_ASSESSMENT_REPORTS) {
    obj.push({
      name: "Risk Assessment Reports",
      href: "/reporting/risk-assessment-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.TIMESHEET_REPORTS) {
    obj.push({
      name: "Timesheet Reports",
      href: "/reporting/timesheet-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.INCIDENT_REPORTS) {
    obj.push({
      name: "Incident Reports",
      href: "/reporting/incident-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.FEEDBACK_REPORTS) {
    obj.push({
      name: "Feedback Reports",
      href: "/reporting/feedback-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.COMPLAINTS_REPORTS) {
    obj.push({
      name: "Complaint Reports",
      href: "/reporting/complaints-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.PARTICIPANT_LIST_REPORTS) {
    obj.push({
      name: "Participant List Reports",
      href: "/reporting/participant-listing-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.WORKER_LIST_REPORTS) {
    obj.push({
      name: "Worker List Reports",
      href: "/reporting/worker-list-reports",
    });
  }
  if (
    pageName === REPORTING_BREADCRUMB_PAGES.WORKER_EXPIRING_DOCUMENTS_REPORTS
  ) {
    obj.push({
      name: "Worker Expiring Document Reports",
      href: "/reporting/worker-expiring-documents-reports",
    });
  }
  if (
    pageName ===
    REPORTING_BREADCRUMB_PAGES.PARTICIPANT_EXPIRING_DOCUMENTS_REPORTS
  ) {
    obj.push({
      name: "Participant Expiring Document Reports",
      href: "/reporting/participant-expiring-documents-reports",
    });
  }
  if (pageName === REPORTING_BREADCRUMB_PAGES.INVOICING_REPORTS) {
    obj.push({
      name: "Invoicing Reports",
      href: "/reporting/invoicing-reports",
    });
  }
  return obj;
};

export const BILLING_BREADCRUMB = (pageName) => {
  const obj = [{ name: "Dashboard", href: "/subscription" }];
  if (pageName === BILLING_BREADCRUMB_PAGES.BILLING_METHOD) {
    obj.push({ name: "Billing Method", href: "/subscription/billing-method" });
  }
  if (pageName === BILLING_BREADCRUMB_PAGES.BILLING_PLAN) {
    obj.push({ name: "Subscription Plans", href: "/subscription/plans" });
  }
  if (pageName === BILLING_BREADCRUMB_PAGES.INVOICES) {
    obj.push({ name: "Invoices", href: "/invoices" });
  }
  return obj;
};

export const SP_SETTING_BREADCRUMB = (pageName) => {
  const obj = [{ name: "Dashboard", href: "/settings" }];
  if (pageName === SETTINGS_BREADCRUMB_PAGES.SP_SETTING) {
    obj.push({ name: "SP settings", href: "/settings/sp-setting" });
  }
  if (pageName === SETTINGS_BREADCRUMB_PAGES.WORKER_COMPLIANCE_SETTING) {
    obj.push({
      name: "Worker Compliance Settings",
      href: "/settings/worker-compliance-setting",
    });
  }
  if (pageName === SETTINGS_BREADCRUMB_PAGES.ROSTERING_SETTING) {
    obj.push({
      name: "Rostering Settings",
      href: "/settings/rostering-setting",
    });
  }
  if (pageName === SETTINGS_BREADCRUMB_PAGES.REGION_SETTING) {
    obj.push({
      name: "Region Settings",
      href: "/settings/region-setting",
    });
  }
  if (pageName === SETTINGS_BREADCRUMB_PAGES.INCIDENT_REPORTING_SETTING) {
    obj.push({
      name: "Incident Reporting Settings",
      href: "/settings/incident-reporting-setting",
    });
  }
  if (pageName === SETTINGS_BREADCRUMB_PAGES.BRANDING) {
    obj.push({
      name: "Branding",
      href: "/settings/branding",
    });
  }
  return obj;
};

export const BILLING_BREADCRUMB_PAGES = {
  BILLING_METHOD: "billingMethod",
  BILLING_PLAN: "billingPlan",
  INVOICES: "invoices",
};

export const SUPER_ADMIN_BREADCRUMBS_PAGES = {
  FORM_TEMPLATES_LIST: "formTemplatesList",
  CREATE_FORM_TEMPLATE: "createFormTemplate",
  UPDATE_FORM_TEMPLATE: "updateFormTemplate",
};

export const FUNDING_PAGES = {
  PARTICIPANTS_PROFILE: "participantsProfile",
};

export const COS_BREADCRUMB_PAGES = {
  PARTICIPANTS_LIST: "participantsList",
  SERVICE_CATALOG: "serviceCatalog",
  EXTERNAL_SERVICE_PROVIDERS: "EXternalServiceProviders",
  PARTICIPANT_PROFILE: "participantProfile",
  CREATE_COS_INVOICE: "Create Cos Invoice",
  CREATED_COS_INVOICE: "Created Cos Invoice",
  CREATED_NDIS_CLAIMS: "Created NDIS Claims",
  CREATE_NDIS_CLAIMS: "Create New NDIS Claim",
  CREATED_NDIS_CLAIMS_REVIEW: "Created  NDIS Claim Review",
  CASE_NOTES_HISTORY: "Case Notes History",
  COS_REPORTING: "COS Reporting",
  COS_SETTING: "COS Setting",
  COS_PRIORITY_SETTING: "Priority Setting",
  COS_CONTACT_SETTING: "Contact Setting",
  COS_CATEGORIES_SETTING: "Category Setting",
  PARTICIPANT_LIST: "Participant List",
  COS_INVOICE_REVIEW: "Invoice Review",
};

export const EMPLOYEE_BREADCRUMB_PAGES = {
  ADD_DOCUMENTS: "addDocuments",
  DASHBOARD: "dashboard",
  ALL_PARTICIPANTS: "allParticipants",
  POLICIES: "policies",
  SETTINGS: "settings",
  COMPLAINT: "complaint",
  FEEDBACK: "feedback",
  ASK_QUESTION: "askquestion",
  SUBMITTED_FORM: "submittedForms",
};

export const HRM_BREADCRUMB_PAGES = {
  ADD_APPLICANT: "addApplicant",
  ALL_APPLICANT: "allApplicant",
  SINGLE_APPLICANT: "profileApplicant",
  ASSESSMENT_APPLICANT: "assesmentApplicant",
  TEAM_MANAGMENT: "teamManagment",
  WORKER: "worker",
  MANAGER_LIST: "managerList",
  ADD_MANAGER: "addManager",
};

export const ROSTERING_BREADCRUMB_PAGES = {
  TIMESHEET: "timesheet",
  SCHEDULE: "schedule",
  EXPORTTIMESHEET: "timesheetexport",
};

export const SETTINGS_BREADCRUMB_PAGES = {
  SP_SETTING: "spSetting",
  WORKER_COMPLIANCE_SETTING: "workerComplianceSetting",
  ROSTERING_SETTING: "RosteringSetting",
  REGION_SETTING: "regionSetting",
  INCIDENT_REPORTING_SETTING: "incidentReportingSetting",
  BRANDING: "brandingSetting",
};
export const ROSTERING_INVOICING_BREADCRUMB_PAGES = {
  INVOICING: "invoicing",
  CREATE_ROSTER_INVOICING: "createRosterInvoicing",
  ROSTER_INVOICE_PREVIEW: "rosterInvoicePreview",
};

export const REPORTING_BREADCRUMB_PAGES = {
  ROC_REPORTS: "rocReports",
  ROC_WEEKLY_REPORTS: "rocWeeklyReports",
  TIMESHEET_REPORTS: "timesheetReports",
  INCIDENT_REPORTS: "incidentReports",
  FEEDBACK_REPORTS: "feedbackReports",
  COMPLAINTS_REPORTS: "complaintReports",
  PARTICIPANT_LIST_REPORTS: "participantListRedports",
  PARTICIPANT_EXPIRING_DOCUMENTS_REPORTS: "participantExpiringDocumentsReports",
  WORKER_LIST_REPORTS: "workerListReports",
  WORKER_EXPIRING_DOCUMENTS_REPORTS: "workerExpiringDocumentsReports",
  INVOICING_REPORTS: "invoicingReports",
  BSP_REPORTS: "bspReports",
  CASE_NOTES_REPORTS: "caseNoteReports",
  COS_REPORTS: "cosReports",
  DETAILED_INVOICING_REPORTS: "detailedInvoicingReports",
  FUNDING_REPORTS: "fundingReports",
  PROGRESS_REPORTS: "progressReports",
  RISK_ASSESSMENT_REPORTS: "riskAssessmentReports",
  SUMMARY_INVOICING_REPORTS: "summaryInvoicingReports",
  KYP_REPORTS: "kypReports",
};
