import { connect } from "react-redux";
import {
  getSpSettingFetchRequest,
  spSettingUpdateRequest,
} from "../../../../store/actions/sp.actions";
import {
  getSpSetting,
  getSpSettingLoader,
  getSpSettingUpdateLoader,
} from "../../../../store/selector/sp.selector";
import SpSetting from "./SpSetting";
import { getUser } from "store/selector/auth.selector";

const mapDispatchToProps = {
  getSpSettingRequest: getSpSettingFetchRequest,
  spSettingUpdateRequest,
};

const mapStateToProps = (state) => {
  const isLoading = getSpSettingLoader(state);
  const settingUpdateLoading = getSpSettingUpdateLoader(state);
  const settings = getSpSetting(state);
  const loginUser=getUser(state)
  return { isLoading, settings, settingUpdateLoading,loginUser };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpSetting);
