import React, { useEffect, useState } from "react";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import TableHeader from "components/common/Table/TableHeader";
import PublishShiftItem from "./PublishShiftItem";
import CheckBox from "components/common/checkBox/checkbox";
import { getAllPendingShifts } from "store/selector/rostering.selector";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SHIFT_STATUS, TOAST_TYPE } from "Constants/constant";
import RosteringAPI from "store/services/rostering.services";
import { workerAndParticipantShiftStatusChangedLocally } from "store/actions/rostering.actions";
import {
  capitalizeFirstLetter,
  formatDateIntoDMM,
  formatDateIntoDMMMY,
  formatDateIntoDMYToDisplay,
} from "Utils/utils";
import { showToast } from "store/actions/general.actions";
import { TOAST_IDS } from "store/constants";
import moment from "moment";
import AvatarGroup from "components/common/Avatar/AvatarGroup";

const PubishShiftModal = (props) => {
  const {
    isShow,
    handleClose,
    calendarView,
    isUserGracePeriodOver,
    selectedDateTypes,
  } = props;

  const dispatch = useDispatch();

  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const shifts = useSelector((state) => getAllPendingShifts(state));
  console.log({shifts})

  // Function to filter shifts based on the start_time
  const filterUpcomingShifts = (shifts = []) => {
    if (!shifts) return;
    return shifts?.filter((shift) => {
      const shiftDate = moment(shift?.shift_date, "YYYY-MM-DD");

      return shiftDate.isSameOrAfter(moment(), "day");
    });
  };

  // Filter the shifts
  const data = filterUpcomingShifts(shifts);
  const handlePublishShift = async () => {
    if (isUserGracePeriodOver) {
      return;
    }
    if (checkboxes.length === 0) {
      dispatch(
        showToast({
          id: TOAST_IDS.PUBLISH_SHIFT_MODAL_BUTTON_ID,
          type: TOAST_TYPE.DEFAULT,
          title: "No Any Shift Selected!",
        })
      );
      return;
    }
    const data = {
      uuid: checkboxes.map((x) => x.uuid),
      status: SHIFT_STATUS.PUBLISHED,
    };
    try {
      setIsLoading(true);
      const response = await RosteringAPI.changeStatusWorkerAndParticipantShift(
        data
      );
      if (response) {
        dispatch(
          workerAndParticipantShiftStatusChangedLocally({
            shifts: checkboxes,
            status: SHIFT_STATUS.PUBLISHED,
            calendarView:
              calendarView === "worker-view" ? "worker" : "participant",
          })
        );
        toast.success(
          `Shift ${capitalizeFirstLetter(
            SHIFT_STATUS.PUBLISHED
          )}  Successfully!`
        );
      }
      setIsLoading(false);
      handleClose();
    } catch (err) {
      setIsLoading(false);
      console.log("err", err);
      toast.error(err?.message ?? "Something went wrong!");
    }
  };

  const toggleCheckboxes = (val) => {
    setCheckboxes(val ? data : []);
  };

  useEffect(() => {
    if (
      data &&
      checkboxes &&
      // checkboxes.length === data.filter((s) => s.worker_id ).length &&
      checkboxes?.length === data?.length &&
      data.length !== 0
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [checkboxes]);

  const handleCheckboxChange = (e, uuid, item) => {
    const filteredList = checkboxes.filter((c) => c.uuid !== uuid);
    if (e) {
      filteredList.push({ ...item });
    }
    setCheckboxes([...filteredList]);
  };

  const modalFooter = () => (
    <>
      <CustomButton
        variant='danger'
        clickHandler={() => {
          handleClose();
        }}
        label='Cancel'
      />
      <CustomButton
        clickHandler={handlePublishShift}
        showLoading={isLoading}
        isDisabled={isLoading || isUserGracePeriodOver}
        label='Publish'
      />
    </>
  );

  //   const publishShiftContent = () => (
  //     <div className='min-w-full p-4 py-0'>
  //       <div className="flex justify-between items-center">
  //       {data && data?.length > 0 && (
  //         <div className='py-2'>
  //           <h4>
  //             <b>Total Shifts:</b> {data?.length}
  //           </h4>
  //         </div>
  //       )}
  //       {/* <div>
  //       <h4>
  //             <b>Date Range:</b> {
  //     `${formatDateIntoDMMMY(selectedDateTypes?.startDate)} - ${formatDateIntoDMMMY(selectedDateTypes?.endDate)}`
  //   }
  //           </h4>

  // </div> */}
  // </div>

  //       {/* <div className='overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
  //         <table className='min-w-full divide-y divide-gray-300 border'>
  //           <thead className='capitalize'>
  //             <tr>
  //               <th
  //                 scope='col'
  //                 className={`pl-3 text-center text-sm font-semibold text-gray-900 bg-blue-50 w-[10%]`}
  //               >
  //                 <CheckBox
  //                   checked={allSelected}
  //                   handleChange={(e) => {
  //                     if (data && data.length > 0) {
  //                       setAllSelected(e);
  //                       toggleCheckboxes(e);
  //                     }
  //                   }}
  //                 />
  //               </th>
  //               <TableHeader isSort={false} label={"Worker name"} />
  //               <TableHeader isSort={false} label={"Participant name"} />
  //               <TableHeader
  //                 cssClass='text-center'
  //                 isSort={false}
  //                 label={"Shift date"}
  //               />
  //               <TableHeader
  //                 cssClass='text-center'
  //                 isSort={false}
  //                 label={"Shift length"}
  //               />
  //               <TableHeader
  //                 cssClass='text-center'
  //                 isSort={false}
  //                 label={"Shift start time"}
  //               />
  //               <TableHeader
  //                 cssClass='text-center'
  //                 isSort={false}
  //                 label={"Shift end time"}
  //               />
  //             </tr>
  //           </thead>
  //           <tbody className='divide-y divide-gray-200 bg-white'>
  //             {false ? (
  //               <tr>
  //                 <td colSpan={7} className='bg-gray-50 py-2'>
  //                   <CircularLoader
  //                     classes='flex justify-center items-center'
  //                     classloader='loader-l'
  //                   />
  //                 </td>
  //               </tr>
  //             ) : data && data?.length > 0 ? (
  //               data?.map((shift, index) => (
  //                 <PublishShiftItem
  //                   shift={shift}
  //                   handleCheckboxChange={(e) =>
  //                     handleCheckboxChange(e, shift.uuid, shift)
  //                   }
  //                   checkboxes={checkboxes}
  //                   key={index}
  //                   calendarView={calendarView}
  //                 />
  //               ))
  //             ) : (
  //               data.length === 0 && (
  //                 <tr>
  //                   <td colSpan={7} className='py-6 text-center text-red-600'>
  //                   No Shift to be Published!
  //                   </td>
  //                 </tr>
  //               )
  //             )}
  //           </tbody>
  //         </table>
  //       </div> */}
  //       <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
  //   <table className="min-w-full divide-y divide-gray-300 border table-fixed">
  //     {/* Sticky Header */}
  //     <thead className="capitalize bg-blue-50 sticky top-0 z-10">
  //       <tr>
  //         <th className="pl-3 text-center text-sm font-semibold text-gray-900 w-[10%]">
  //           <CheckBox
  //             checked={allSelected}
  //             handleChange={(e) => {
  //               if (data && data.length > 0) {
  //                 setAllSelected(e);
  //                 toggleCheckboxes(e);
  //               }
  //             }}
  //           />
  //         </th>
  //         <TableHeader isSort={false} label={"Worker name"} />
  //         <TableHeader isSort={false} label={"Participant name"} />
  //         <TableHeader cssClass="text-center" isSort={false} label={"Shift date"} />
  //         <TableHeader cssClass="text-center" isSort={false} label={"Shift length"} />
  //         <TableHeader cssClass="text-center" isSort={false} label={"Shift start time"} />
  //         <TableHeader cssClass="text-center" isSort={false} label={"Shift end time"} />
  //       </tr>
  //     </thead>
  //   </table>

  //   {/* Scrollable Table Body */}
  //   <div className="max-h-[300px] overflow-y-auto">
  //     <table className="min-w-full divide-y divide-gray-300 border ">
  //       <tbody className="divide-y divide-gray-200 bg-white">
  //         {data && data.length > 0 ? (
  //           data.map((shift, index) => (
  //             <PublishShiftItem
  //               key={index}
  //               shift={shift}
  //               handleCheckboxChange={(e) => handleCheckboxChange(e, shift.uuid, shift)}
  //               checkboxes={checkboxes}
  //               calendarView={calendarView}
  //             />
  //           ))
  //         ) : (
  //           <tr>
  //             <td colSpan={7} className="py-6 text-center text-red-600">
  //               No Shift to be Published!
  //             </td>
  //           </tr>
  //         )}
  //       </tbody>
  //     </table>
  //   </div>
  // </div>

  //     </div>
  //   );

  const publishShiftContent = () => {
    return (
      <div className='p-4'>
        {data && data.length > 0 && (
          <div className='py-2'>
            <h4>
              <b>Total Shifts:</b> {data?.length}
            </h4>
          </div>
        )}
        <div className='border rounded-lg overflow-hidden'>
          <div className='max-h-[350px] overflow-y-auto'>
            <table className='w-full border-collapse'>
              <thead className='bg-blue-50 text-left sticky top-0 z-10'>
                <tr>
                  <th className='px-4 py-4 w-10 text-center'>
                    <CheckBox
                      checked={allSelected}
                      handleChange={(e) => toggleCheckboxes(e)}
                    />
                  </th>
                  <th className='px-4 py-3 text-sm'>Worker Name</th>
                  <th className='px-4 py-3 text-sm'>Participant Name</th>
                  <th className='px-4 py-3 text-center text-sm'>Shift Date</th>
                  <th className='px-4 py-3 text-center text-sm'>Shift Length</th>
                  <th className='px-4 py-3 text-center text-sm'>Start Time</th>
                  <th className='px-4 py-3 text-center text-sm'>End Time</th>
                </tr>
              </thead>
              <tbody>
                {data.length > 0 ? (
                  data.map((shift, index) => (
                    <tr key={index} className='border-t'>
                      <td className='px-4 py-3 text-center'>
                        <CheckBox
                          checked={checkboxes.some(
                            (c) => c.uuid === shift.uuid
                          )}
                          handleChange={(e) =>
                            handleCheckboxChange(e, shift.uuid, shift)
                          }
                        />
                      </td>
                      <td className='px-4 text-sm py-3'>
                        {" "}
                        <div className='capitalize  text-gray-500'>
                          {shift.worker.name !== "undefined undefined" ? (
                            shift?.worker?.name
                          ) : (
                            <span className='text-red-300'>
                              No worker assigned
                            </span>
                          )}
                        </div>
                      </td>
                      <td className='px-4 text-sm py-3'>
                        {" "}
                        <div className='capitalize text-gray-500'>
                          {shift &&
                            calendarView === "worker-view" &&
                            shift?.participants.length > 0 && (
                              <AvatarGroup
                                type='worker'
                                arrayData={shift?.participants || []}
                                cssClass='mt-0'
                                maxLength={10}
                              />
                            )}
                          {shift &&
                            calendarView === "participant-view" &&
                            shift?.participant?.name}
                        </div>
                      </td>
                      <td className='px-4 py-3 text-sm text-center'>
                        {" "}
                        <div className='text-gray-500'>
                          {formatDateIntoDMYToDisplay(shift.shift_date)}
                        </div>
                      </td>
                      <td className='px-4 py-3 text-sm text-center'>
                        {" "}
                        <div className='text-gray-500'>
                          {shift.shift_length && shift.shift_length.length > 4
                            ? shift?.shift_length?.slice(0, 5)?.split(":")
                                .length >= 1 &&
                              `${
                                shift?.shift_length?.slice(0, 5)?.split(":")[0]
                              }h ${
                                shift?.shift_length?.slice(0, 5)?.split(":")[1]
                              }m`
                            : shift.shift_length}
                        </div>
                      </td>
                      <td className='px-4 py-3 text-sm text-center'>
                        <div className='text-gray-500'>
                          {moment(
                            shift.start_time,
                            "YYYY-MM-DD HH:mm:ss"
                          ).format("hh:mm a")}
                        </div>
                      </td>
                      <td className='px-4 py-3  text-sm text-center'>
                        <div className='text-gray-500'>
                          {moment(shift.end_time, "YYYY-MM-DD HH:mm:ss").format(
                            "hh:mm a"
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7} className='py-6 text-center text-red-600'>
                      No Shift to be Published!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {isShow && (
        <ModalSkeleton
          title={`Publish Shifts From ${formatDateIntoDMMMY(
            selectedDateTypes?.startDate
          )} To ${formatDateIntoDMMMY(selectedDateTypes?.endDate)}`}
          isOpen={isShow}
          cssClass='w-[60%]'
          bodyPadding='px-0'
          closeModal={() => {
            handleClose();
          }}
          modalFooter={modalFooter()}
        >
          {false ? (
            <div
              className={`flex flex-col w-full min-h-screen bg-gray-50 items-center justify-center`}
            >
              <CircularLoader
                classes='flex justify-center items-center'
                classloader='loader-l'
              />
            </div>
          ) : (
            publishShiftContent()
          )}
        </ModalSkeleton>
      )}
    </div>
  );
};

export default PubishShiftModal;
