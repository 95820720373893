import React from "react";
import { connect } from "react-redux";
import { getUser } from "../../../store/selector/auth.selector";
import { baseURLImage } from "../../../Utils/utils";
import { toast } from "react-toastify";

const FileUploadRoundedShape = ({ profilePicture, setProfilePicture, ImgURL }) => {
  const handleFileUpload = (e, error) => {
    const file = e.target.files[0];
    if (file && file.size > 6 * 1024 * 1024) {
      // Display error message if file size exceeds 6MB
      toast.error(error?.message ?? "Image size should not exceed 6MB.");
      // Reset the value of the file input element
      e.target.value = null;
    } else {
      // Set profile picture if file size is within limits
      setProfilePicture(file);
    }
  };


  return (
    <div className="relative w-60 h-60 rounded-full overflow-hidden">
       <input
        disabled={false}
        accept="image/*"
        onChange={(e) => handleFileUpload(e, { message: "File too large. The maximum allowed size is 2MB. Try a smaller file." })}
        id="file-upload"
        type="file"
        className="sr-only"
      />
      <label htmlFor="file-upload">
        <div className="max-w-full h-full rounded-full border-2 border-dashed border-gray-300 flex items-center justify-center cursor-pointer transition duration-200 hover:border-blue-500">
          {profilePicture ? (
            <img
              className="object-cover w-60 h-60 rounded-full"
              src={URL.createObjectURL(profilePicture)}
              alt=""
            />
          ) : ImgURL ? (
            <img
              className="object-cover w-60 h-60 rounded-full"
              src={baseURLImage(ImgURL)}
              alt=""
            />
          ) : (
            <div className="relative h-[38px] flex items-center justify-center px-3 cursor-pointer max-w-full">
              <div className="absolute inset-0 w-full h-full cursor-pointer flex items-center justify-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

const mapStateToProps = (state) => {
  const currentLoginUser = getUser(state);
  return { currentLoginUser };
};

export default connect(mapStateToProps, null)(FileUploadRoundedShape);
