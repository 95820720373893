import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import ModalSkeleton from "components/Modals/ModalSkeleton";
import SelectOption from "components/common/Select/Select";
import CustomButton from "components/common/Button/CustomButton";
import FileUpload from "components/common/FileUpload/FileUploadInput";
import {
  DUMMY_ADD_SERVICE_PROVIDER_VALUE,
  LOADER_STATUS,
} from "Constants/constant";
import { NumericFormat } from "react-number-format";
import { LoginContext } from "helpers/LoginContext";

const ServiceCategoryBudgetModal = (props) => {
  const {
    openModal,
    closeModal,
    PlanBudgetCatAndSubCatagories,
    funding,
    catalogState,
    storeServiceCategoryPlanBudget,
    storeServicePlanLoader,
    participant,
    storeServicePlanError,
    editServiceCategoryBudget = null,
    externalSps,
  } = props;

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const watchFormData = watch();
  const { isUserGracePeriodOver } = useContext(LoginContext);

  const [catagory, setCatagory] = useState(null);
  const [subCatagory, setSubCatagory] = useState(null);
  const [externalServiceProvider, setExternalServiceProvider] = useState(null);
  const [attachment, setAttachment] = useState(null);
  const [storeRenderLoader, setStoreRenderLoader] = useState(LOADER_STATUS.NA);
  const navigate = useNavigate();
  const [updateFileName, setUpdateFileName] = useState(null);

  useEffect(() => {
    if (catagory !== null) {
      setValue("service_category_id", catagory.value);
      clearErrors("service_category_id");
    }
    const valdiate = () => {
      const index = PlanBudgetCatAndSubCatagories.planBudgetSubCategories[
        catagory.label.replace(" ", "_")
      ].findIndex((x) => x.value === subCatagory.value);
      if (index === -1) {
        setValue("service_sub_category_id", null);
        setSubCatagory(null);
      }
    };
    if (
      editServiceCategoryBudget &&
      catagory &&
      subCatagory &&
      catagory.value !== catagory.id
    ) {
      valdiate();
    }
    if (editServiceCategoryBudget === null && catagory && subCatagory) {
      valdiate();
    }
  }, [catagory]);

  useEffect(() => {
    if (subCatagory !== null) {
      setValue("service_sub_category_id", subCatagory.value);
      clearErrors("service_sub_category_id");
    }
  }, [subCatagory]);

  useEffect(() => {
    if (externalServiceProvider !== null) {
      setValue("external_sp_id", externalServiceProvider.value);
      clearErrors("external_sp_id");
    }
  }, [externalServiceProvider]);

  useEffect(() => {
    if (attachment) {
      setValue("file", attachment);
      clearErrors("file");
    } else setValue("file", null);
  }, [attachment]);

  useEffect(() => {
    if (
      !storeServicePlanLoader &&
      storeRenderLoader !== LOADER_STATUS.NA &&
      storeServicePlanError.length <= 0
    ) {
      handleClose();
    }
  }, [storeServicePlanLoader]);

  useEffect(() => {
    if (editServiceCategoryBudget) {
      setExternalServiceProvider({
        label: editServiceCategoryBudget.external_sp.name,
        value: editServiceCategoryBudget.external_sp.uuid,
      });

      setCatagory({
        label:
          editServiceCategoryBudget.service_sub_category.service_category.name,
        value:
          editServiceCategoryBudget.service_sub_category.service_category.id,
      });

      setSubCatagory({
        label: editServiceCategoryBudget.service_sub_category.title,
        value: editServiceCategoryBudget.service_sub_category.id,
      });

      reset({
        total_budget_amount: editServiceCategoryBudget.total_budget_amount,
        funding_id: funding.funding_contract_id,
      });
      setValue("notes", editServiceCategoryBudget?.note);
      setUpdateFileName(editServiceCategoryBudget?.file_name);
      setAttachment(editServiceCategoryBudget?.file);
    }
  }, [editServiceCategoryBudget]);

  useEffect(() => {
    if (catalogState) {
      setValue("state_id", catalogState.label);
    }
  }, [catalogState]);

  const onSubmit = (data) => {
    if (isUserGracePeriodOver) {
      return;
    }
    let formData = new FormData();
    if (attachment) {
      formData.append("file", attachment);
    }
    if (catalogState) {
      formData.append("state_id", catalogState.value);
    }
    if (editServiceCategoryBudget) {
      formData.append("sp_cat_budget_uuid", editServiceCategoryBudget.uuid);
    }
    formData.append("pt_id", participant ? participant.id : "1");
    formData.append("funding_id", funding.id);
    formData.append("funding_contract_id", funding.contract_id);
    if (data.notes) {
      formData.append("notes", data.notes);
    }

    formData.append("total_budget_amount", data.total_budget_amount);
    formData.append("service_sub_category_id", data.service_sub_category_id);
    formData.append("service_category_id", data.service_category_id);
    formData.append("external_sp_id", data.external_sp_id);
    setStoreRenderLoader(LOADER_STATUS.PENDING);
    storeServiceCategoryPlanBudget({
      data: formData,
      isUpdate: editServiceCategoryBudget ? true : false,
    });
  };

  const handleClose = () => {
    reset();
    setCatagory(null);
    setSubCatagory(null);
    closeModal();
  };

  const handleFile = (e) => {
    setAttachment(e);
    setUpdateFileName(null);
  };

  const modalFooter = () => (
    <CustomButton
      showLoading={storeServicePlanLoader}
      isDisabled={storeServicePlanLoader || isUserGracePeriodOver}
      // cssClass={`mt-2 w-1/2 ${
      //   editServiceCategoryBudget
      //     ? "bg-yellow-400 "
      //     : "bg-green-600 "
      // }  text-white px-4 py-2 rounded-md`}
      label={editServiceCategoryBudget ? "Update" : "Submit"}
      clickHandler={() => {
        if (isUserGracePeriodOver) {
          return;
        }
        const buttonRef = document.getElementById(
          "serviceCategoryBudgetButtonModal"
        );
        if (buttonRef) {
          buttonRef.click();
        }
      }}
    />
  );

  return (
    <ModalSkeleton
      modalFooter={modalFooter()}
      isOpen={openModal}
      closeModal={handleClose}
      cssClass='w-[60%]'
      title={
        editServiceCategoryBudget
          ? "Update Service Provider Category Budget"
          : "Create New Service Provider Category Budget"
      }
    >
      <div className='flex flex-col'>
        <p className='text-left text-sm text-gray-500 mb-2'>
          Please enter the following details to
          {editServiceCategoryBudget ? " update" : " create"} service provider
          category budget
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className='space-y-3'>
          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <label className='block text-sm font-medium text-gray-700'>
                Funding ID
              </label>
              <input
                {...register("funding_id", {
                  required: true,
                  value: funding.contract_id,
                })}
                style={{
                  height: "38px",
                  backgroundColor: "#4b6bb710",
                }}
                value={funding.contract_id}
                disabled
                readOnly
                name='funding_id'
                id=''
                className='did-floating-input mt-1 bg-[#4b6bb710]'
              />
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='state_id'
                className='block text-sm font-medium text-gray-700'
              >
                State
              </label>
              <div className='mt-1'>
                <input
                  {...register("state_id", {
                    required: true,
                    maxLength: 254,
                  })}
                  placeholder={
                    watchFormData.state_id === undefined
                      ? "Please select state from setting..."
                      : null
                  }
                  readOnly
                  disabled
                  type='text'
                  name='state_id'
                  id='state_id'
                  className='px-4 py-2 border shadow-sm block w-full sm:text-sm border-gray-300 rounded-md capitalize bg-[#4b6bb710]'
                />
                {errors?.state_id?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  {...register("external_sp_id", {
                    required: true,
                  })}
                  name='external_sp_id'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Service Provider*
              </label>
              <SelectOption
                cssClass='mt-1'
                isSearch={false}
                data={externalSps}
                selectedOption={externalServiceProvider}
                handleChange={(e) => {
                  if (e.value === DUMMY_ADD_SERVICE_PROVIDER_VALUE) {
                    navigate("/cos/external-service-provider");
                  } else {
                    setExternalServiceProvider(e);
                  }
                }}
                // placeHolder="Select Service Provider..."
              />
              {errors?.external_sp_id?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <label
                htmlFor='total_budget_amount'
                className='block text-sm font-medium text-gray-700'
              >
                Budget Amount*
              </label>
              <div className='mt-1'>
                <input
                  type='hidden'
                  {...register("total_budget_amount", {
                    required: true,
                    // value: parseInt(watchFormData.total_budget_amount, 10),
                  })}
                />
                <NumericFormat
                  min={1}
                  type='text'
                  maxLength={"13"}
                  className={`${"focus:bg-[#4b6bb710] px-4 py-2 border shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"} w-full`}
                  value={parseInt(watchFormData.total_budget_amount, 10)}
                  thousandsGroupStyle='thousand'
                  thousandSeparator=','
                  valueIsNumericString={true}
                  decimalScale={2} // Ensuring up to two decimal places
                  fixedDecimalScale={true}
                  prefix={"$"}
                  allowLeadingZeros={false}
                  onValueChange={(e) => {
                    const { value } = e;
                    // console.log("value", value);
                    if (value > 0) {
                      setValue("total_budget_amount", value);
                      clearErrors("total_budget_amount");
                    } else {
                      setValue("total_budget_amount", null);
                    }
                  }}
                />
                {errors?.total_budget_amount?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  {...register("service_category_id", {
                    required: true,
                  })}
                  name='service_category_id'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Plan Category*
              </label>
              <SelectOption
                cssClass='mt-1'
                isSearch={false}
                data={
                  PlanBudgetCatAndSubCatagories
                    ? PlanBudgetCatAndSubCatagories.planBudgetCategories
                    : []
                }
                isDisabled={editServiceCategoryBudget ? true : false}
                selectedOption={catagory}
                handleChange={setCatagory}
                // placeHolder="Select Plan Catagory"
              />
              {errors?.service_category_id?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
            <div className='col-span-1'>
              <div className='hidden'>
                <select
                  {...register("service_sub_category_id", {
                    required: true,
                  })}
                  name='service_sub_category_id'
                  id=''
                  className='hidden did-floating-input'
                />
              </div>
              <label className='block text-sm font-medium text-gray-700'>
                Plan Subcatagory*
              </label>
              <SelectOption
                cssClass='mt-1'
                isSearch={false}
                data={
                  PlanBudgetCatAndSubCatagories && catagory && catagory.label
                    ? PlanBudgetCatAndSubCatagories.planBudgetSubCategories[
                        catagory.label.replace(" ", "_")
                      ]
                    : []
                }
                isDisabled={editServiceCategoryBudget ? true : false}
                selectedOption={subCatagory}
                handleChange={setSubCatagory}
                // placeHolder={`${
                //   catagory === null
                //     ? "Select Plan Catagory First!"
                //     : "Select Plan Sub Catagory"
                // }`}
              />
              {errors?.service_sub_category_id?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-2'>
              <label className='block text-sm font-medium text-gray-700'>
                Notes
              </label>
              <div className='mt-1'>
                <textarea
                  {...register("notes", {
                    required: false,
                  })}
                  name='notes'
                  rows={3}
                  className='overflow-y-auto focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md resize-none'
                />
              </div>
              {errors?.notes?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required
                </span>
              )}
            </div>
          </div>

          <div className='grid grid-cols-2 gap-x-4'>
            <div className='col-span-2'>
              <div className='hidden'>
                <input
                  {...register("file", {
                    required: false,
                    maxLength: 254,
                  })}
                  type='text'
                />
              </div>
              <label
                htmlFor='attachment'
                className='block text-sm font-medium text-gray-700'
              >
                Attachment
              </label>
              <div className='mt-1'>
                <FileUpload
                  updateFileName={updateFileName}
                  type='pdf'
                  handleFile={handleFile}
                  size={"2"}
                  file={attachment}
                  handleDelete={() => {
                    setAttachment(null);
                    setUpdateFileName(null);
                  }}
                />
                {errors?.file && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            </div>
          </div>

          <button
            className='hidden sr-only'
            type='submit'
            id='serviceCategoryBudgetButtonModal'
          />
        </form>
      </div>
    </ModalSkeleton>
  );
};

export default ServiceCategoryBudgetModal;
