import React, {useContext, useEffect, useState} from "react";
import { LoginContext } from "../../../helpers/LoginContext";
import SideBar from "../../../components/common/SideBar/SideBarNew";
import { BiPencil, BiPlus } from "react-icons/bi";
import CircularLoader from "../../../components/common/CircularLoader/CircularLoader";
import Breadcrumb from "../../../components/common/Breadcrumb/Breadcrumb";
import {
  HRM_BREADCRUMB,
  HRM_BREADCRUMB_PAGES,
} from "../../../Constants/BreadcrumbConstant";
import {
  LOADER_STATUS,
  USER_ROLE,
  portalTypeOptions,
  SP_PARTICIPANTS_LIST_LIMIT,
  emptyPaginationObj,
  StatusOptions
} from "../../../Constants/constant";
import Header from "../../../components/common/Header/Header";
import AddManager from "./AddManager";
import api from "store/services/auth.services";
import { roleAccess } from "helpers/RolesPermission";
import { PERMISSION_KEYS, PERMISSION_KEYS_CHILDREN } from "store/constants";
import { parseBooleanVal, truncateTextReporting } from "Utils/utils";
import PopoverContainer from "components/common/Popover/PopoverContainer";
import {apiPostRequest} from "../../../helpers/Requests";
import {URL_SP_PRIMARY_PORTAL_USER_LIST} from "../../../store/services/URL";
import Pagination from "components/common/Pagination";
import SelectOption from '../../../components/common/Select/Select';
import Search from '../../../components/common/Search/Search';
import { toast } from 'react-toastify';

const ManagerList = (props) => {
  const { sideBarPadding, isUserGracePeriodOver } = useContext(LoginContext);
  const {
    getSpManager,
    loading,
    error,
    managerList,
    createSPManager,
    isRefreshManagerList,
  } = props;
  const [managers, setManagers] = useState(managerList);
  const [fetchLaoder, setFetchLoader] = useState(LOADER_STATUS.NA);
  const [isOpenModel, setIsModelOpen] = useState(false);
  const [roleOptions, setRoleOptions] = useState([]);
  const [fetchRoleLoader, setFetchRoleLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
// for list down all users
  const [primaryPortalUsers, setPrimaryPortalUsers] = useState();
  const [selectedStatus, setSelectedStatus] = useState({
    label: 'Active',
    value: 'active',
  });
  const [search, setSearch] = useState(null);
  // for pagination
  const [pagination, setPagination] = useState(emptyPaginationObj);
  const [page, setPage] = useState(1);
  const [isRefresh , setIsRefresh] = useState(false);

  useEffect(() => getRoles(), []);

  const getRoles = async () => {
    try {
      setFetchRoleLoader(true);
      const response = await api.roleAndPermissionList({
        type: portalTypeOptions[0].value,
      });
      if (response && response.data) {
        setRoleOptions(
          response?.data?.map((x) => ({ label: x.name, value: x.id,is_super_admin:x.is_super_admin }))
        );
      }
      setFetchRoleLoader(false);
    } catch (err) {
      setFetchRoleLoader(false);
      console.log("err", err);
    }
  };

  const getPrimaryPortalUsers = async (page = 1, resetPage = false) => {
    try {
      setFetchLoader(LOADER_STATUS.PENDING);

      const endpoint = URL_SP_PRIMARY_PORTAL_USER_LIST;
      const statusValue =
          selectedStatus?.value === 'both'
              ? []
              : selectedStatus?.value === 'active'
                  ? [true]
                  : selectedStatus?.value === 'inactive'
                      ? [false]
                      : [];

      const payload = {
        sort_field: 'created_at',
        sort_order: 'desc',
        page: resetPage ? 1 : page,
        search: search || '',
        per_page: 10,
        filter: { status: statusValue },
      };

      const response = await apiPostRequest(endpoint, payload);
      const primaryUsers = response?.data?.data?.managers?.data || [];
      const primaryPagination = response?.data?.data?.managers || null;

      setPrimaryPortalUsers(primaryUsers);
      setPagination(primaryPagination);
    } catch (error) {
      toast.error('Error fetching primary portal users:', error);
      setPrimaryPortalUsers([]);
    } finally {
      setFetchLoader(LOADER_STATUS.COMPLATE);
    }
  };

  useEffect(() => {
    // Reset page to 1 when selectedStatus or search changes
    setPage(1);
  }, [selectedStatus, search]);

  useEffect(() => {
    if (search === null) return;
    const timeOutId = setTimeout(() => {
      getPrimaryPortalUsers(1, true);
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    getPrimaryPortalUsers(page, false);
  }, [page,isRefreshManagerList]);

  useEffect(() => {
    getPrimaryPortalUsers(1, true);
  }, [selectedStatus]);

  // useEffect(() => setManagers(managerList), [managerList]);

  useEffect(() => {
    if (loading && fetchLaoder === LOADER_STATUS.PENDING) {
      setFetchLoader(LOADER_STATUS.COMPLATE);
    }
  }, [loading]);

  // useEffect(() => {
  //   // if (managerList.length === 0) {
  //   getSpManager();
  //   // }
  //   if (fetchLaoder === LOADER_STATUS.NA) {
  //     setFetchLoader(LOADER_STATUS.PENDING);
  //   }
  // }, [isRefreshManagerList]);

  return (
    <>
      <div className='min-full'>
        <SideBar classes='bg-gradient' />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          } flex flex-col fullScreenHeight`}
        >
          <main className='flex-1 bg-gray-50'>
            <Header
              title='All Primary Portal Users'
              desc='All primary portal users in one place'
              breadcrumb={
                <Breadcrumb
                  pages={HRM_BREADCRUMB(HRM_BREADCRUMB_PAGES.MANAGER_LIST)}
                />
              }
              {...(roleAccess(
                [],
                PERMISSION_KEYS.HRM,
                PERMISSION_KEYS_CHILDREN.HRM.MANAGER.CREATE
              ) && {
                  handleClick: () => setIsModelOpen(true),
                  btnLabel: "Add Primary Portal User",
                  icon: <BiPlus />,
                  isButton: true,
                  btnCss:
                    "background-gradient border-gradient text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
                })}
            />
            <div className='w-full'>
              <div className='max-w-7xl mx-auto'>

                <div className="flex items-center justify-between px-4 py-4">
                  <div className="w-full sm:w-1/5 md:w-2/12 lg:w-2/12">
                    <SelectOption
                        isSearch={false}
                        selectedOption={selectedStatus}
                        handleChange={(e) => {
                          setSelectedStatus(e);
                        }}
                        data={StatusOptions}
                        cssClass="z-20"
                    />
                  </div>
                  <div className="w-full sm:w-auto">
                    <Search setSearch={setSearch} search={search} />
                  </div>
                </div>


                <div>
                  {fetchRoleLoader || fetchLaoder === LOADER_STATUS.PENDING ? (
                      <div className='flex items-center min-h-screen justify-center'>
                        <CircularLoader
                            classes='flex justify-center items-center'
                            classloader='loader-l'
                        />
                      </div>
                  ) : (
                      <div>
                        <div className='max-w-7xl custom-scroll11 overflow-y-auto11 allApplicantHeight11'>
                          <div>
                            <div className='flex flex-col'>
                              <div className='overflow-x-auto111'>
                                <div className='inline-block min-w-full py-2 align-middle md:px-4 lg:px-4'>
                                  <div
                                      className='overflow-hidden111 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
                                    <table className='min-w-full divide-y divide-gray-300'>
                                      <thead className='bg-white capitalize'>
                                      <tr className='sticky top-[80px] z-7'>
                                        <th
                                            scope='col'
                                            className={`py-3.5 px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50`}
                                        >
                                          <div className='flex items-center flex-start space-x-2'>
                                            Name
                                          </div>
                                        </th>
                                        <th
                                            scope='col'
                                            className={`py-3.5 px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50`}
                                        >
                                          <div className='flex items-center flex-start space-x-2'>
                                            Email
                                          </div>
                                        </th>
                                        <th
                                            scope='col'
                                            className={`py-3.5 px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50`}
                                        >
                                          <div className='flex items-center flex-start space-x-2'>
                                            Status
                                          </div>
                                        </th>
                                        <th
                                            scope='col'
                                            className={`py-3.5 px-3 text-left text-sm font-semibold text-gray-900 bg-blue-50`}
                                        >
                                          <div className='flex items-center flex-start space-x-2'>
                                            Regions
                                          </div>
                                        </th>
                                        <th
                                            scope='col'
                                            className={`py-3.5 px-3 text-center text-sm font-semibold text-gray-900 bg-blue-50`}
                                        >
                                          {/* <div className='flex items-center flex-start space-x-2'> */}
                                          Role
                                          {/* </div> */}
                                        </th>
                                        {roleAccess([USER_ROLE.Sp_Admin]) && (
                                            <>
                                              <th
                                                  scope='col'
                                                  className='relative py-3.5 pl-3 pr-4 sm:pr-6 bg-blue-50 z-0'
                                              >
                                              <span className='sr-only'>
                                                Edit
                                              </span>
                                              </th>
                                            </>
                                        )}
                                      </tr>
                                      </thead>
                                      <tbody className='divide-y divide-gray-200'>
                                      {primaryPortalUsers &&
                                          primaryPortalUsers.map((item) => (
                                                  <tr
                                                      key={item.id}
                                                      className='bg-white hover:bg-gray-100'
                                                  >
                                                    <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm'>
                                                      <div className='flex items-center'>
                                                        <div className=''>
                                                          <div className='capitalize font-medium text-gray-900'>
                                                            {item.name}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
                                                      <div className='text-gray-900'>
                                                        {item.email}
                                                      </div>
                                                    </td>
                                                    <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                                                <span
                                                    className={`${
                                                        parseBooleanVal(item.status)
                                                            ? "bg-green-100 text-green-700"
                                                            : "bg-red-100 text-red-700"
                                                    } inline-flex rounded-full  px-2 text-xs font-semibold leading-5 capitalize `}
                                                >
                                                  {parseBooleanVal(item.status)
                                                      ? "Active"
                                                      : "inactive"}
                                                </span>
                                                    </td>
                                                    <td className='whitespace-nowrap px-2 py-4 text-sm text-gray-500'>
                                                      <div>
                                                        {item?.regions?.length ===
                                                        0 ? (
                                                            "N/A"
                                                        ) : (
                                                            <PopoverContainer
                                                                cssClass={`text-white text-sm bg-gradient p-2 rounded-md z-10`}
                                                                itemComponent={() => {
                                                                }}
                                                                toolTip={`${item?.regions
                                                                    ?.map((el) => el?.name)
                                                                    .join(", ")}`}
                                                            >
                                                              {item?.regions?.length > 3
                                                                  ? item?.regions
                                                                  ?.slice(0, 3)
                                                                  .map(
                                                                      (el) => el?.name
                                                                  )
                                                                  .join(", ") + "..."
                                                                  : item?.regions
                                                                      ?.map(
                                                                          (el) => el?.name
                                                                      )
                                                                      .join(", ")}
                                                            </PopoverContainer>
                                                        )}
                                                      </div>
                                                    </td>

                                                    <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                                                      <div className='capitalize text-gray-900'>
                                                        {roleOptions.find(
                                                            (x) =>
                                                                x.value ===
                                                                item.role_id
                                                        )?.label ?? "-"}
                                                      </div>
                                                    </td>
                                                    {roleAccess([
                                                      USER_ROLE.Sp_Admin,
                                                    ]) && (
                                                        <>
                                                          <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
                                                            <button
                                                                className=''
                                                                onClick={() => {
                                                                  setSelectedItem(item);
                                                                  setIsModelOpen(true);
                                                                }}
                                                            >
                                                              <div
                                                                  className='icon-pencil-wrapper'
                                                                  title='Edit'
                                                              >
                                                                <BiPencil className='icon-size'/>
                                                              </div>
                                                            </button>
                                                          </td>
                                                        </>
                                                    )}
                                                  </tr>
                                              ))}
                                      {primaryPortalUsers?.length === 0  &&
                                      fetchLaoder === LOADER_STATUS.COMPLATE ? (
                                          <tr>
                                            <td
                                                colSpan={7}
                                                className='py-6 text-center text-red-600'
                                            >
                                              No data found!
                                            </td>
                                          </tr>
                                      ) : null}
                                      </tbody>
                                    </table>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  )}
                  <div className='sm:px-6 lg:px-8 max-w-7xl mx-auto py-4'>
                    {primaryPortalUsers?.length > 0 && (
                        <Pagination
                            page={pagination.current_page}
                            activeListLength={primaryPortalUsers}
                            limit={SP_PARTICIPANTS_LIST_LIMIT}
                            totalList={pagination.total}
                            handlePagination={(newPage) => {
                              setPage(newPage);
                            }}
                            totalPages={pagination.total_pages}
                            cssClass='!m-0'
                        />
                    )}

                  </div>
                </div>
              </div>
            </div>

          </main>
        </div>
      </div>
      {isOpenModel && (
          <AddManager
              isUserGracePeriodOver={isUserGracePeriodOver}
              selectedItem={selectedItem}
              fetchRoleLoader={fetchRoleLoader}
              roleOptions={roleOptions}
              createSPManager={createSPManager}
              loading={loading}
              error={error}
              openModel={isOpenModel}
              handleClose={(e) => {
                setIsModelOpen(e);
                setSelectedItem(null);
              }}
              setIsRefresh={setIsRefresh}
          />
      )}
    </>
  );
};

export default ManagerList;
