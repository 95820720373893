import { spAllFormRequest } from "../../../../store/actions/sp.actions";
import { connect } from "react-redux";
import IncidentReporting from "./IncidentReportingSetting";
import { spFormTemplates } from "store/selector/sp.selector";

const mapDispatchToProps = {
  fetchFormTemplate: spAllFormRequest,
};

const mapStateToProps = (state) => {
  return {
    spFormTemplatesList: spFormTemplates(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IncidentReporting);
