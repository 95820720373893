import React, { useState, useEffect, useMemo } from "react";
import { BiPlus } from "react-icons/bi";
import CustomButton from "components/common/Button/CustomButton";
import SelectOption from "components/common/Select/Select";
import SideBar from "components/common/SideBar/SideBarNew";
import TableHeader from "components/common/Table/TableHeader";
import { LOADER_STATUS } from "Constants/constant";
import { LoginContext } from "helpers/LoginContext";
import CheckBox from "components/common/checkBox/checkbox";
import COSInvoiceTabel from "./COSInvoiceTabel";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import Header from "components/common/Header/Header";
import Breadcrumb from "components/common/Breadcrumb";
import {
  COS_BREADCRUMB,
  COS_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import COSNewModal from "./COSNewModal";

const COSInvoicePage = (props) => {
  const {
    cosInvoiceList,
    cosInvoiceLoading,
    createCosInvoiceLoading,
    createCosInvoices,
    getCosInvoice,
    emptyTableData,
    getClientsRequest,
    clientsList,
    supportCoordinatorsRequest,
    supportCoordinator,
    clientsListLoader,
  } = props;

  const { sideBarPadding, isUserGracePeriodOver } =
    React.useContext(LoginContext);
  const [renderLoading, setRenderLoading] = useState(LOADER_STATUS.NA);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [supportCoordiantion, setSupportCoordination] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [isOpenModel, setIsModelOpen] = useState(false);
  const [reportType, setReportType] = useState(null);

  const cosInvoiceListToMap = useMemo(() => {
    if (cosInvoiceList && cosInvoiceList.length > 0) {
      return cosInvoiceList;
    }
    return [];
  }, [cosInvoiceList]);

  const handleCheckboxChange = (e, id, item) => {
    const filteredList = checkboxes.filter((c) => c !== id);
    if (e) {
      filteredList.push(id);
      setAmount(() => parseFloat(amount) + parseFloat(item.total_amount));
      setQuantity(() => parseInt(quantity) + parseInt(item.quantity));
    } else {
      setAmount(() => parseFloat(amount) - parseFloat(item.total_amount));
      setQuantity(() => parseInt(quantity) - parseInt(item.quantity));
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    if (val) {
      const data = cosInvoiceListToMap.reduce((obj, data) => {
        Object.assign(obj, {
          totalAmount: obj["totalAmount"]
            ? obj["totalAmount"] + parseFloat(data.total_amount)
            : parseFloat(data.total_amount),
          totalQuantity: obj["totalQuantity"]
            ? obj["totalQuantity"] + parseFloat(data.quantity)
            : parseFloat(data.quantity),
        });
        return obj;
      }, {});
      setAmount(data?.totalAmount || 0);
      setQuantity(data?.quantity || 0);
      setCheckboxes(cosInvoiceListToMap.map((t) => t.id));
    } else {
      setCheckboxes([]);
      setAmount(0);
      setQuantity(0);
    }
  };

  useEffect(() => {
    if (
      cosInvoiceListToMap &&
      checkboxes &&
      checkboxes.length === cosInvoiceListToMap.length &&
      cosInvoiceListToMap.length !== 0
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [checkboxes]);

  useEffect(() => {
    getClientsRequest();
    supportCoordinatorsRequest();
    return () => emptyTableData();
  }, []);

  useEffect(() => {
    if (!cosInvoiceLoading && renderLoading !== LOADER_STATUS.NA) {
      setRenderLoading(LOADER_STATUS.COMPLATE);
    }
  }, [cosInvoiceLoading]);

  useEffect(() => {
    setRenderLoading(LOADER_STATUS.PENDING);
    setCheckboxes([]);
    getCosInvoice({
      pt_id: selectedClient ? selectedClient.value : "all",
      from_date: fromDate ? fromDate : "all",
      to_date: toDate ? toDate : "all",
      cos_id: supportCoordiantion ? supportCoordiantion.value : "all",
    });
    setAmount(0);
  }, [supportCoordiantion, fromDate, selectedClient]);

  const handleOnCreateClaimList = () => {
    if (isUserGracePeriodOver) return;
    if (checkboxes && checkboxes.length > 0) {
      setIsModelOpen(true);
    }
  };

  useEffect(() => {
    if (reportType) {
      getCosInvoice({
        report: reportType,
        pt_id: selectedClient ? selectedClient.value : "all",
        from_date: fromDate ? fromDate : "all",
        to_date: toDate ? toDate : "all",
        cos_id: supportCoordiantion ? supportCoordiantion.value : "all",
      });

      setReportType(null);
    }
  }, [reportType]);

  useEffect(() => {
    if (clientsList && clientsList) {
      clientsList.unshift({ label: "All", value: "all" });
    }
  }, [clientsList]);

  useEffect(() => {
    if (supportCoordinator && supportCoordinator) {
      supportCoordinator.unshift({ label: "All", value: "all" });
    }
  }, [supportCoordinator]);

  console.log({ amount });

  return (
    <>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <main className='h-screen flex-1 bg-white'>
            <Header
              title='Create Cos Invoices'
              breadcrumb={
                <Breadcrumb
                  pages={COS_BREADCRUMB(
                    COS_BREADCRUMB_PAGES.CREATE_COS_INVOICE
                  )}
                />
              }
            />
            <div className='max-w-7xl mx-auto py-3 bg-white'>
              <div className='flex my-3 justify-between sm:px-6 lg:px-8 items-center bg-white max-w-7xl mx-auto py-2 sticky top-[80px] z-[8]'>
                <div className='flex gap-x-2 w-[70%]'>
                  <div className='col-span-1 w-[25%]'>
                    <label className='block  text-sm font-medium text-gray-700 text-start'>
                      Participant
                    </label>
                    <SelectOption
                      isClearable={true}
                      isDisabled={clientsListLoader || cosInvoiceLoading}
                      cssClass=''
                      name='client'
                      isSearch={false}
                      data={clientsList && clientsList}
                      selectedOption={selectedClient}
                      handleChange={(e) => {
                        setSelectedClient(e);
                      }}
                      // placeHolder="Participant Name"
                    />
                  </div>
                  <div className='col-span-1 w-[30%]'>
                    <label className='block  text-sm font-medium text-gray-700 text-start'>
                      Support Coordination
                    </label>
                    <SelectOption
                      isClearable={true}
                      isDisabled={clientsListLoader || cosInvoiceLoading}
                      cssClass=''
                      name='sp_cos_id'
                      isSearch={false}
                      data={supportCoordinator && supportCoordinator}
                      selectedOption={supportCoordiantion}
                      handleChange={(e) => {
                        setSupportCoordination(e);
                      }}
                      // placeHolder="support Coordination"
                    />
                  </div>
                  <div className='w-[30%] '>
                    <label className='block  text-sm font-medium text-gray-700 text-start'>
                      Select Date
                    </label>
                    <DateRangePickerNew
                      isDisabled={clientsListLoader}
                      handleCancel={() => {
                        setFromDate(null);
                        setToDate(null);
                      }}
                      handleChange={(obj) => {
                        if (obj && obj.fromDate && obj.toDate) {
                          setFromDate(obj.fromDate);
                          setToDate(obj.toDate);
                        }
                      }}
                      fromDate={fromDate}
                      toDate={toDate}
                    />
                  </div>
                </div>
                {/* <div className="w-56 inline-flex flex-col">
                  <label className="text-start text-white text-sm font-medium text-gray-700 text-start">
                    nbsp;
                  </label>
                  <SelectBox setReportType={setReportType} />
                </div> */}
                <CustomButton
                  clickHandler={handleOnCreateClaimList}
                  Icon={<BiPlus />}
                  label='Create Cos Invoice'
                  isDisabled={
                    (checkboxes.length === 0 ? true : false) ||
                    isUserGracePeriodOver
                  }
                />
                {/* <Search setSearch={setSearch} search={search} /> */}
              </div>
              <div className='sm:px-6 lg:px-8'>
                <div className='flex flex-col'>
                  <div className='-my-2 overflow-x-auto'>
                    <div className='min-w-full py-2 align-middle'>
                      <div className='overflow-x-auto border md:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                          <thead className='bg-blue-50 sticky capitalize'>
                            <tr>
                              <th
                                scope='col'
                                className={`pl-3 text-center text-sm font-semibold text-gray-900 bg-blue-50`}
                              >
                                <CheckBox
                                  isDisabled={isUserGracePeriodOver}
                                  checked={allSelected}
                                  handleChange={(e) => {
                                    if (
                                      cosInvoiceListToMap &&
                                      cosInvoiceListToMap.length > 0
                                    ) {
                                      setAllSelected(e);
                                      toggleCheckboxes(e);
                                    }
                                  }}
                                />
                              </th>
                              <TableHeader isSort={false} label={"Reference"} />
                              <TableHeader
                                isSort={false}
                                label={"Created By"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Created Date"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Participant"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Mgmt Style"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Support Item"}
                              />
                              <TableHeader
                                isSort={false}
                                label={"Support Item Number"}
                              />
                              {/* <TableHeader
                                isSort={false}
                                sortName="management_style"
                                //   flipSort={flipSort}
                                //   sortBy={sortBy}
                                //   sortDescending={sortDescending}
                                label={"COS"}
                              /> */}
                              {/* <TableHeader
                                isSort={false}
                                sortName="date_delivered"
                                //   flipSort={flipSort}
                                //   sortBy={sortBy}
                                //   sortDescending={sortDescending}
                                label={"NDIS Number"}
                              /> */}
                              <TableHeader isSort={false} label={"Status"} />
                              <TableHeader
                                isSort={false}
                                label={"Date Delivered"}
                              />
                              {/* <TableHeader
                                isSort={false}
                                sortName="total_amount"
                                //   flipSort={flipSort}
                                //   sortBy={sortBy}
                                //   sortDescending={sortDescending}
                                label={"GST Code"}
                              /> */}
                              <TableHeader isSort={false} label={"Km"} />
                              <TableHeader isSort={false} label={"Hours"} />
                              <TableHeader isSort={false} label={"Rate"} />
                              <TableHeader
                                isSort={false}
                                label={"Total value"}
                              />
                              <th>
                                <div className='sr-only'>View</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200 bg-white'>
                            {(renderLoading === LOADER_STATUS.PENDING &&
                              cosInvoiceLoading) ||
                            createCosInvoiceLoading ? (
                              <tr>
                                <td colSpan={13} className='bg-gray-50 py-2'>
                                  <CircularLoader
                                    classes='flex justify-center items-center'
                                    classloader='loader-l'
                                  />
                                </td>
                              </tr>
                            ) : cosInvoiceListToMap &&
                              cosInvoiceListToMap.length > 0 ? (
                              cosInvoiceListToMap.map((item, index) => (
                                <COSInvoiceTabel
                                  isUserGracePeriodOver={isUserGracePeriodOver}
                                  checkboxes={checkboxes}
                                  handleCheckboxChange={(e) =>
                                    handleCheckboxChange(e, item.id, item)
                                  }
                                  item={item}
                                  key={index}
                                />
                              ))
                            ) : (
                              renderLoading !== LOADER_STATUS.NA &&
                              cosInvoiceListToMap.length === 0 && (
                                <tr>
                                  <td
                                    colSpan={13}
                                    className='py-6 text-center text-red-600'
                                  >
                                    No Data Found !
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      {isOpenModel && isUserGracePeriodOver === false && (
        <COSNewModal
          createCosInvoices={createCosInvoices}
          checkboxes={checkboxes}
          amount={amount}
          openModel={isOpenModel}
          handleClose={setIsModelOpen}
        />
      )}
    </>
  );
};

export default COSInvoicePage;
