import React, { useEffect, useState } from "react";
import SelectOption from "components/common/Select/Select";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
  portalOption,
  RentalTypeOptions,
  RoomBedHeightOptions,
  RoomBedTypeOptions,
} from "Constants/constant";
import CustomButton from "components/common/Button/CustomButton";
import api from "store/services/sil.services";
import { toast } from "react-toastify";
import {
  setSelectedSilAccommodationRoomLocally,
  setSelectedSilAccommodationRoomVacantLocally,
} from "store/actions/sil.actions";
import { MdDelete } from "react-icons/md";
import { getSilAccommodationRoomDeleteLoading } from "store/selector/sil.selector";
import CheckBox from "components/common/checkBox/checkbox";
import { NumericFormat } from "react-number-format";

const SilRoomDetails = ({
  room,
  participantsOptions,
  workersOptions,
  deleteSilRoomRequest,
  updateDetails,
  selectedSilAccommodation,
  spParticipantsListLoader,
  isUserGracePeriodOver,
}) => {
  const dispatch = useDispatch();
  const [isUpdateLoader, setIsUpdateLoader] = useState(false);
  const isDeleteLoader = useSelector(getSilAccommodationRoomDeleteLoading);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    // clearErrors,
    reset,
    control,
  } = useForm();

  const watchFormData = watch();

  useEffect(() => {
    if (room) {
      reset(room);
    }
  }, [room]);

  const onSubmit = async (values) => {
    try {
      if (isUserGracePeriodOver) return;
      setIsUpdateLoader(true);
      const response = await api.silRoomDetailUpdateApi({
        ...values,
        uuid: room.uuid,
        room_uuid: room.uuid,
        cupboard: values.cupboard === "yes" ? true : false,
        tv: values.tv === "yes" ? true : false,
      });
      if (response) {
        updateDetails({
          ...values,
          participants: participantsOptions?.filter((x) =>
            watchFormData?.participants?.includes(x.id)
          ),
          cupboard: values.cupboard === "yes" ? true : false,
          tv: values.tv === "yes" ? true : false,
        });

        dispatch(
          setSelectedSilAccommodationRoomLocally(
            {
              ...values,
              participants: participantsOptions?.filter((x) =>
                watchFormData?.participants?.includes(x.id)
              ),
              workers: workersOptions?.filter((x) =>
                watchFormData?.workers?.includes(x.id)
              ),
            },
            room.uuid
          )
        );
        toast.success("Room details updated successfully");
      }
      setIsUpdateLoader(false);
    } catch (err) {
      setIsUpdateLoader(false);
      toast.error(err?.message || "Something went wrong!");
      console.log("err", err);
    }
  };

  const handleDeleteRoom = () => {
    if (isUserGracePeriodOver) return;
    deleteSilRoomRequest({ room_uuid: room.uuid }, async () => {
      const homeUpdatedData = await api.silAccommodationDetailApi(
        selectedSilAccommodation?.uuid
      );
      if (homeUpdatedData) {
        dispatch(setSelectedSilAccommodationRoomVacantLocally(homeUpdatedData));
      }
    });
  };
  // console.log("watchFormData", watchFormData);
  return (
    <div>
      <form
        className='h-full flex flex-col justify-between'
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className='grid grid-cols-2 gap-6'>
          {/* <div /> */}
          <div className='col-span-2'>
            <label className='text-gray-700 text-base font-semibold'>
              Room Features
            </label>
          </div>

          <div>
            <label className='block text-sm font-medium text-gray-800'>
              Bed Type
              {/* <span className='text-red-500'>*</span> */}
            </label>
            <div className='mt-1'>
              <SelectOption
                cssClass='truncate'
                isRequired={false}
                register={register}
                setValue={setValue}
                name='bed_type'
                isSearch={false}
                data={RoomBedTypeOptions}
                selectedOption={RoomBedTypeOptions?.find(
                  (x) => watchFormData?.bed_type === x.value
                )}
                errorMessage={
                  errors?.bed_type?.type === "required" ? (
                    <div className='text-sm text-red-500'>
                      This field is required!
                    </div>
                  ) : null
                }
              />
            </div>
          </div>
          <div>
            <label className='block text-sm font-medium text-gray-800'>
              Bed Height
              {/* <span className='text-red-500'>*</span> */}
            </label>
            <div className='mt-1'>
              <SelectOption
                cssClass='truncate'
                isRequired={false}
                register={register}
                setValue={setValue}
                name='bed_height'
                isSearch={false}
                data={RoomBedHeightOptions}
                selectedOption={RoomBedHeightOptions?.find(
                  (x) => watchFormData?.bed_height === x.value
                )}
                errorMessage={
                  errors?.bed_height?.type === "required" ? (
                    <div className='text-sm text-red-500'>
                      This field is required!
                    </div>
                  ) : null
                }
              />
            </div>
          </div>
          <div>
            <label className='block text-sm font-medium text-gray-800'>
              Sofas
              {/* <span className='text-red-500'>*</span> */}
            </label>
            <div className='mt-1'>
              <input
                {...register("total_sofas", {
                  required: false,
                  value: watchFormData?.total_sofas || 0,
                  maxLength: 50,
                })}
                type='number'
                placeholder='Enter number of sofas'
                name='total_sofas'
                id='total_sofas'
                className='did-floating-input'
              />
              {errors.total_sofas && (
                <span className='text-red-500 text-sm'>
                  This field is required
                </span>
              )}
            </div>
          </div>
          <div>
            <label className='block text-sm font-medium text-gray-700 mb-1'>
              Room Cupboard
            </label>
            <SelectOption
              cssClass='truncate'
              isRequired={false}
              register={register}
              setValue={setValue}
              name='cupboard'
              isSearch={false}
              data={portalOption}
              selectedOption={portalOption?.find(
                (x) => watchFormData?.cupboard === x.value
              )}
              errorMessage={
                errors?.cupboard?.type === "required" ? (
                  <div className='text-sm text-red-500'>
                    This field is required!
                  </div>
                ) : null
              }
            />
          </div>
          <div>
            <label className='block text-sm font-medium text-gray-800 mb-1'>
              Room TV
            </label>
            <SelectOption
              cssClass='truncate'
              isRequired={false}
              register={register}
              setValue={setValue}
              name='tv'
              isSearch={false}
              data={portalOption}
              selectedOption={portalOption?.find(
                (x) => watchFormData?.tv === x.value
              )}
              errorMessage={
                errors?.tv?.type === "required" ? (
                  <div className='text-sm text-red-500'>
                    This field is required!
                  </div>
                ) : null
              }
            />
          </div>
          {/* <div>
            <label className='block text-sm font-medium text-gray-800'>
              Chairs
              <span className='text-red-500'>*</span>
            </label>
            <div className='mt-1'>
              <input
                {...register("total_chairs", {
                  required: true,
                  value: watchFormData?.total_chairs || 0,
                  maxLength: 50,
                })}
                type='number'
                placeholder=""
                name='total_chairs'
                id='total_chairs'
                className='did-floating-input'
              />
              {errors.total_chairs && (
                <span className='text-red-500 text-sm'>
                  This field is required
                </span>
              )}
            </div>
          </div> */}
          <div>
            <label className='block text-sm font-medium text-gray-800'>
            Tables 
              {/* <span className='text-red-500'>*</span> */}
            </label>
            <div className='mt-1'>
              <input
                {...register("total_tables", {
                  required: false,
                  value: watchFormData?.total_tables,
                  maxLength: 50,
                })}
                type='number'
                placeholder='Enter number of tables'
                name='total_tables'
                id='total_tables'
                className='did-floating-input'
              />
              {errors.total_tables && (
                <span className='text-red-500 text-sm'>
                  This field is required
                </span>
              )}
            </div>
          </div>

          <div>
            <label className='block text-sm font-medium text-gray-800'>
              Width of Door Room in CM
              {/* <span className='text-red-500'>*</span> */}
            </label>
            <div className='mt-1'>
              <input
                {...register("door_width", {
                  required: false,
                  value: watchFormData?.door_width,
                  // maxLength: 50,
                })}
                type='number'
                name='door_width'
                id='door_width'
                className='did-floating-input'
              />
              {errors.door_width && (
                <span className='text-red-500 text-sm'>
                  This field is required
                </span>
              )}
            </div>
          </div>

          <div>
            <label className='block text-sm font-medium text-gray-800'>
              Rent Amount
              {/* <span className='text-red-500'>*</span> */}
            </label>
            <div className='mt-1'>
              <Controller
                name={"rent_amount"}
                control={control}
                defaultValue={watchFormData?.rent_amount}
                render={({ field }) => {
                  return (
                    <NumericFormat
                      onValueChange={(e) => {
                        field.onChange(e.floatValue);
                      }}
                      className='did-floating-input'
                      type='text'
                      value={watchFormData?.rent_amount}
                      thousandsGroupStyle='thousand'
                      thousandSeparator=','
                      valueIsNumericString={true}
                      // disabled
                      prefix={"$"}
                    />
                  );
                }}
              />
              {/* <input
                {...register("rent_amount", {
                  required: false,
                  value: watchFormData?.rent_amount,
                  // maxLength: 50,
                  validate: {
                    notNegative: (value) => {
                      if (value < 0) {
                        return "Room cannot be a negative number.";
                      }
                      return true;
                    },
                  },
                })}
                type='number'
                name='rent_amount'
                id='rent_amount'
                className='did-floating-input'
              /> */}
              {errors.rent_amount && (
                <span className='text-red-500 text-sm'>
                  {errors.rent_amount.message || "Field is required!"}
                </span>
              )}
            </div>
          </div>

          <div>
            <label className='block text-sm font-medium text-gray-800 mb-1'>
              Rent Frequency
              {/* <span className='text-red-500'>*</span> */}
            </label>
            <SelectOption
              cssClass='truncate'
              isRequired={false}
              register={register}
              setValue={setValue}
              name='rent_type'
              isSearch={false}
              data={RentalTypeOptions}
              selectedOption={RentalTypeOptions?.find(
                (x) => watchFormData?.rent_type === x.value
              )}
              errorMessage={
                errors?.rent_type?.type === "required" ? (
                  <div className='text-sm text-red-500'>
                    This field is required!
                  </div>
                ) : null
              }
            />
          </div>

          <div className='col-span-1 pt-6'>
            {/* <div className='grid sm:grid-cols-1 grid-cols-1 gap-x-1'> */}
            {/* <div>
                <Controller
                  name={"high_bed"}
                  control={control}
                  defaultValue={watchFormData?.high_bed}
                  render={({ field }) => {
                    return (
                      <div className='flex justify-start items-center space-x-2'>
                        <CheckBox
                          name='high_bed'
                          checked={watchFormData?.high_bed}
                          cssClass='border-gray-500 rounded-sm'
                          handleChange={(value) => {
                            field.onChange(value);
                          }}
                        />
                        <label
                          htmlFor='high_bed'
                          className='block text-sm font-medium text-gray-700'
                        >
                          High Bed
                        </label>
                      </div>
                    );
                  }}
                />
              </div>
              <div>
                <Controller
                  name={"low_bed"}
                  control={control}
                  defaultValue={watchFormData?.low_bed}
                  render={({ field }) => {
                    return (
                      <div className='flex justify-start items-center space-x-2'>
                        <CheckBox
                          checked={watchFormData?.low_bed}
                          handleChange={(value) => {
                            field.onChange(value);
                          }}
                          cssClass='border-gray-500 rounded-sm'
                        />
                        <label className='block text-sm font-medium text-gray-700'>
                          Low Bed
                        </label>
                      </div>
                    );
                  }}
                />
              </div> */}
            {/* <div className='col-span-2'> */}

            <Controller
              name={"on_suite_bathroom"}
              control={control}
              defaultValue={watchFormData?.on_suite_bathroom}
              render={({ field }) => {
                return (
                  <div className='flex justify-start items-center space-x-2'>
                    <CheckBox
                      checked={watchFormData?.on_suite_bathroom}
                      handleChange={(value) => {
                        field.onChange(value);
                      }}
                      cssClass='border-gray-500 rounded-sm'
                    />
                    <label className='block text-sm font-medium text-gray-700'>
                      On-Suite Bathroom
                    </label>
                  </div>
                );
              }}
            />
            {/* </div> */}
            {/* </div> */}
          </div>

          <div className='col-span-2'>
            <label className='block text-sm font-medium text-gray-800'>
              Description
            </label>
            <div className='mt-1'>
              <textarea
                rows={3}
                name='details'
                id='details'
                defaultValue={watchFormData?.details}
                className='resize-none overflow-y-auto focus:bg-[#4b6bb710] border px-4 py-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                {...register("details", {
                  required: false,
                  value: watchFormData?.details || "",
                })}
              />
              {errors.details && (
                <span className='text-red-500 text-sm'>
                  This field is required
                </span>
              )}
            </div>
          </div>

          <div className='col-span-1'>
            <label className='mb-1 block text-sm font-medium text-gray-700'>
              Participant Assigned
            </label>
            <Controller
              name={"participants"}
              control={control}
              defaultValue={watchFormData?.participants || []}
              render={({ field }) => {
                return (
                  <SelectOption
                    loading={spParticipantsListLoader}
                    isDisabled={isUpdateLoader || spParticipantsListLoader}
                    isMulti={true}
                    data={participantsOptions}
                    selectedOption={participantsOptions?.filter((x) =>
                      watchFormData?.participants?.includes(x.id)
                    )}
                    handleChange={(data) => {
                      field.onChange(data?.map((x) => x.id));
                    }}
                  />
                );
              }}
            />
          </div>
          <div className='col-span-1'>
            <label className='block text-sm font-medium text-gray-700'>
              Date Participant Moved In
              {/* into the room */}
              {/* <span className='text-red-500'>*</span> */}
            </label>
            <div className='mt-1'>
              <input
                {...register(`participant_moved_in`, {
                  required: false,
                  value: watchFormData?.participant_moved_in || null,
                })}
                type='date'
                name='participant_moved_in'
                id='participant_moved_in'
                className='did-floating-input'
                // min={!watchFormData?.is_review ? reviewDate : ""}
              />
              {errors?.participant_moved_in?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>

          {/* <div className='col-span-2'>
            <label className='text-gray-700 text-sm font-medium mb-1'>
              Worker Assigned
            </label>
            <Controller
              name={"workers"}
              control={control}
              defaultValue={watchFormData?.workers}
              render={({ field }) => {
                return (
                  <SelectOption
                    isDisabled={isUpdateLoader}
                    isMulti={true}
                    data={workersOptions}
                    selectedOption={workersOptions?.filter((x) =>
                      watchFormData?.workers?.includes(x.id)
                    )}
                    handleChange={(data) => {
                      field.onChange(data?.map((x) => x.id));
                    }}
                  />
                );
              }}
            />
          </div> */}

          {/* <div className='col-span-2'>
            <label className='text-gray-700 text-lg font-bold pt-4'>
              Leaving Date with Room Leaving Attachment:
            </label>
          </div> */}

          <div>
            <label className='block text-sm font-medium text-gray-800'>
              Date Participant Moved Out
            </label>
            <div className='mt-1'>
              <input
                {...register(`participant_leave_on`, {
                  required: false,
                  value: watchFormData?.participant_leave_on || null,
                })}
                type='date'
                name='participant_leave_on'
                id='participant_leave_on'
                className='did-floating-input'
              />
              {errors?.participant_leave_on?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
          </div>
        </div>
        <div className='pt-2 w-full flex justify-between space-x-4 items-center'>
          <div className='w-[70%]'>
            <CustomButton
              showLoading={isUpdateLoader}
              isDisabled={
                isUpdateLoader || isDeleteLoader || isUserGracePeriodOver
              }
              cssClass='w-full text-center justify-center'
              label={isUpdateLoader ? "Updating" : "Update"}
              type='submit'
            />
          </div>
          <div className='w-[30%]'>
            <CustomButton
              Icon={<MdDelete />}
              showLoading={isDeleteLoader}
              isDisabled={isDeleteLoader || isUserGracePeriodOver}
              cssClass='w-full text-center justify-center'
              variant='danger'
              loaderCssClass='text-black'
              label={isDeleteLoader ? "Deleting" : "Delete"}
              clickHandler={handleDeleteRoom}
              type='button'
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SilRoomDetails;
