import React, { useState, useEffect, useMemo } from "react";
import { BiPlus } from "react-icons/bi";
import CustomButton from "components/common/Button/CustomButton";
import SelectOption from "components/common/Select/Select";
import SideBar from "components/common/SideBar/SideBarNew";
import TableHeader from "components/common/Table/TableHeader";

import { LoginContext } from "helpers/LoginContext";
import CheckBox from "components/common/checkBox/checkbox";
import { connect } from "react-redux";
import DateRangePickerNew from "components/common/DateRangePicker/DateRangePickerNew";
import Header from "components/common/Header/Header";
import Breadcrumb from "components/common/Breadcrumb";
import {
  ROSTERING_INVOICING_BREADCRUMB,
  ROSTERING_INVOICING_BREADCRUMB_PAGES,
} from "Constants/BreadcrumbConstant";
import RosterModal from "./RosterModal";
import { getRosterInvoicesRequest } from "store/actions/rostering.actions";
import {
  getHRMTeamManagmentRequest,
  getSPParticipantsListRequest,
} from "store/actions/sp.actions";
import {
  getRosterInvoicesLoading,
  getRosterInvoicing,
} from "store/selector/rostering.selector";
import {
  getParticipantsList,
  getTeamManagmentList,
} from "store/selector/sp.selector";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import RosterInvoiceTable from "./RosterInvoiceTable";
import { capitalizeFirstLetter, capitalizeWords } from "Utils/utils";

const CreateRosterInvoicing = ({
  getRosterInvoicesList,
  rosterInvoiceLoading,
  teamManagementList,
  spParticipants,
  getRosterInvoicesRequest,
  getTeamManagmentListCall,
  getParticipantList,
}) => {
  const { sideBarPadding } = React.useContext(LoginContext);
  const [checkboxes, setCheckboxes] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const [selectedWorker, setSelectedWorker] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [isOpenModel, setIsModelOpen] = useState(false);

  const [dropdownList, setDropdown] = useState({
    participants: [],
    workers: [],
  });
  useEffect(() => {
    const filters = {
      page: 1,
      per_page: 500,
      search: null,
    };

    getTeamManagmentListCall({ filters, bodyFilter: null });
    getParticipantList({ filters, bodyFilter: null });
  }, []);

  useEffect(() => {
    setDropdown((prevDropdown) => {
      const newParticipants =
        spParticipants?.participant?.data?.map((item) => ({
          label: `${capitalizeWords(item?.first_name)} ${capitalizeWords(item?.last_name || "")}`,
          value: item?.id,
        })) || [];

      const newWorkers =
        teamManagementList?.employees?.data?.map((item) => ({
          label: `${item?.first_name} ${item?.last_name}`,
          value: item?.id,
        })) || [];

      return {
        ...prevDropdown,
        participants: newParticipants,
        workers: newWorkers,
      };
    });
  }, [spParticipants, teamManagementList]);

  const rosterInvoiceListToMap = useMemo(() => {
    if (getRosterInvoicesList && getRosterInvoicesList.length > 0) {
      return getRosterInvoicesList;
    }
    return [];
  }, [getRosterInvoicesList]);

  const handleCheckboxChange = (e, uuid, item) => {
    const filteredList = checkboxes.filter((c) => c !== uuid);

    if (e) {
      filteredList.push(uuid);
      setQuantity((val) => val + 1);
      setAmount(() => {
        const newAmount = parseFloat(amount) + parseFloat(item.total_amount);
        return parseFloat(newAmount);
      });
    } else {
      setAmount(() => {
        const newAmount = parseFloat(amount) - parseFloat(item.total_amount);
        return parseFloat(newAmount);
      });
      setQuantity((val) => val - 1);
    }
    setCheckboxes(filteredList);
  };

  const toggleCheckboxes = (val) => {
    if (val) {
      const data = rosterInvoiceListToMap.reduce((obj, data) => {
        Object.assign(obj, {
          totalAmount: obj["totalAmount"]
            ? obj["totalAmount"] + parseFloat(data.total_amount)
            : parseFloat(data.total_amount),
        });
        return obj;
      }, {});

      setAmount(data?.totalAmount || 0);
      setQuantity(rosterInvoiceListToMap?.length || 0);
      setCheckboxes(rosterInvoiceListToMap.map((t) => t.uuid));
    } else {
      setCheckboxes([]);
      setAmount(0);
      setQuantity(0);
    }
  };
  // const handleCheckboxChange = (e, uuid, item) => {
  //   const filteredList = checkboxes.filter((c) => c !== uuid);
  //   if (e) {
  //     filteredList.push(uuid);

  //   }
  //   setCheckboxes(filteredList);
  // };
  // const toggleCheckboxes = (val) => {

  //   if (val) {

  //     setCheckboxes(rosterInvoiceListToMap.map((t) => t.uuid));
  //   } else {
  //     setCheckboxes([]);

  //   }
  // };

  useEffect(() => {
    if (
      rosterInvoiceListToMap &&
      checkboxes &&
      checkboxes.length === rosterInvoiceListToMap.length &&
      rosterInvoiceListToMap.length !== 0
    ) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [checkboxes]);

  useEffect(() => {
    setCheckboxes([]);
    getRosterInvoicesRequest({
      worker_id: selectedWorker?.value === "all" ? "" : selectedWorker?.value,
      participant_id:
        selectedParticipant?.value === "all" ? "" : selectedParticipant?.value,
      start_date: fromDate ? fromDate : "",
      end_date: toDate ? toDate : "",
    });

    setAmount(0);
  }, [fromDate, selectedParticipant, selectedWorker]);

  const handleOnCreateClaimList = () => {
    setIsModelOpen(true);
  };

  useEffect(() => {
    if (dropdownList && dropdownList) {
      dropdownList?.participants?.unshift({ label: "All", value: "all" });
      dropdownList?.workers?.unshift({ label: "All", value: "all" });
    }
  }, [dropdownList]);

  return (
    <>
      <div className='min-h-full'>
        <SideBar />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <main className='h-screen flex-1 bg-white'>
            <Header
              title='Create Invoices'
              breadcrumb={
                <Breadcrumb
                  pages={ROSTERING_INVOICING_BREADCRUMB(
                    ROSTERING_INVOICING_BREADCRUMB_PAGES.CREATE_ROSTER_INVOICING
                  )}
                />
              }
            />
            <div className='max-w-full mx-auto py-3 bg-white'>
              <div className='flex my-3 justify-between sm:px-6 lg:px-8 items-center bg-white max-w-full mx-auto py-2 sticky top-[80px] z-[8]'>
                <div className='flex gap-x-2 w-[70%]'>
                  <div className='col-span-1 w-[25%]'>
                    <label className='block  text-sm font-medium text-gray-700 text-start'>
                      Participant
                    </label>
                    <SelectOption
                      isClearable={true}
                      isDisabled={rosterInvoiceLoading}
                      cssClass=''
                      name='participant'
                      isSearch={true}
                      data={dropdownList && dropdownList?.participants}
                      selectedOption={selectedParticipant}
                      handleChange={(e) => {
                        setSelectedParticipant(e);
                      }}
                      // placeHolder='Participant'
                    />
                  </div>
                  <div className='col-span-1 w-[30%]'>
                    <label className='block  text-sm font-medium text-gray-700 text-start'>
                      Worker
                    </label>
                    <SelectOption
                      isClearable={true}
                      isDisabled={rosterInvoiceLoading}
                      cssClass=''
                      name='worker'
                      isSearch={true}
                      data={dropdownList && dropdownList?.workers}
                      selectedOption={selectedWorker}
                      handleChange={(e) => {
                        setSelectedWorker(e);
                      }}
                      // placeHolder='Worker'
                    />
                  </div>
                  <div className='w-[30%] '>
                    <label className='block  text-sm font-medium text-gray-700 text-start'>
                      Select Date Range
                    </label>
                    <DateRangePickerNew
                      isDisabled={rosterInvoiceLoading}
                      handleCancel={() => {
                        setFromDate(null);
                        setToDate(null);
                      }}
                      handleChange={(obj) => {
                        if (obj && obj.fromDate && obj.toDate) {
                          setFromDate(obj.fromDate);
                          setToDate(obj.toDate);
                        }
                      }}
                      fromDate={fromDate}
                      toDate={toDate}
                    />
                  </div>
                </div>

                <CustomButton
                  clickHandler={handleOnCreateClaimList}
                  Icon={<BiPlus />}
                  label='Create Invoice'
                  isDisabled={checkboxes.length === 0 ? true : false}
                />
              </div>
              <div className='sm:px-6 lg:px-8'>
                <div className='flex flex-col'>
                  <div className='-my-2 overflow-x-auto'>
                    <div className='min-w-full py-2 align-middle'>
                      <div className='overflow-x-auto border md:rounded-lg'>
                        <table className='min-w-full divide-y divide-gray-300'>
                          <thead className='bg-blue-50 sticky capitalize'>
                            <tr>
                              <TableHeader isSort={false} label={""} />

                              <th
                                scope='col'
                                className={`pl-3 text-center text-sm font-semibold text-gray-900 bg-blue-50`}
                              >
                                <CheckBox
                                  checked={allSelected}
                                  handleChange={(e) => {
                                    if (
                                      rosterInvoiceListToMap &&
                                      rosterInvoiceListToMap.length > 0
                                    ) {
                                      setAllSelected(e);
                                      toggleCheckboxes(e);
                                    }
                                  }}
                                />
                              </th>
                              <TableHeader
                                isSort={false}
                                label={"Participant(s)"}
                                cssClass='normal-case'
                              />
                              <TableHeader isSort={false} label={"Worker"} />
                             
                              <TableHeader
                                isSort={false}
                                label={"Date Delivered"}
                                cssClass='text-center'
                              />
                              <TableHeader
                                isSort={false}
                                label={"Type Of Service"}
                                cssClass='text-center'
                              />

                              <TableHeader
                                isSort={false}
                                label={"Status"}
                                cssClass='text-center'
                              />

                              <TableHeader
                                isSort={false}
                                label={"Hours"}
                                cssClass='text-center'
                              />
                              <TableHeader
                                isSort={false}
                                label={"Total Amount"}
                                cssClass='text-center'
                              />
                              <th>
                                <div className='sr-only'>View</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200 bg-white'>
                            {rosterInvoiceLoading ? (
                              <tr>
                                <td colSpan={13} className='bg-gray-50 py-2'>
                                  <CircularLoader
                                    classes='flex justify-center items-center'
                                    classloader='loader-l'
                                  />
                                </td>
                              </tr>
                            ) : rosterInvoiceListToMap &&
                              rosterInvoiceListToMap.length > 0 ? (
                              rosterInvoiceListToMap.map((item, index) => (
                                <RosterInvoiceTable
                                  checkboxes={checkboxes}
                                  handleCheckboxChange={(e) =>
                                    handleCheckboxChange(e, item.uuid, item)
                                  }
                                  item={item}
                                  key={index}
                                />
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={13}
                                  className='py-20 text-center text-red-600'
                                >
                                  No Data Found !
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      {isOpenModel && (
        <RosterModal
          quantity={quantity}
          checkboxes={checkboxes}
          amount={amount}
          openModel={isOpenModel}
          handleClose={setIsModelOpen}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  getRosterInvoicesRequest,
  getTeamManagmentListCall: getHRMTeamManagmentRequest,
  getParticipantList: getSPParticipantsListRequest,
};

const mapStateToProps = (state) => {
  const getRosterInvoicesList = getRosterInvoicing(state);
  const teamManagementList = getTeamManagmentList(state);
  const spParticipants = getParticipantsList(state);
  const rosterInvoiceLoading = getRosterInvoicesLoading(state);

  return {
    getRosterInvoicesList,
    teamManagementList,
    spParticipants,
    rosterInvoiceLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRosterInvoicing);
