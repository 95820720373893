import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import _ from "lodash";
import TextEditor from "../TextEditor/TextEditor";
import {
  convertLabelIntoName,
  getDynamicFormSelectValue,
  parseBooleanVal,
} from "../../../Utils/utils";
import SelectOption from "../Select/Select";
import {
  DateValidaionOptions,
  PARENT_FORM_CATEGORY_SLUG,
  portalOption,
  SelectTypeOptions,
} from "../../../Constants/constant";
import ModalSkeleton from "../../Modals/ModalSkeleton";
import CustomButton from "../Button/CustomButton";

function InputCreationForm(props) {
  const {
    closeModalHandler,
    editData,
    dataSubmitHandler,
    template,
    showModal,
    title,
    sectionIndex,
    subSectionIndex,
    formCategoryId,
  } = props;

  const [viewOptions, setViewOptions] = useState(false);
  const [participantVlaidation, setParticipantValidation] = useState(false);
  const [viewTableFields, setViewTableFields] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [legalRef, setLegalRef] = useState(
    editData && editData.hasOwnProperty("legalRef") ? editData.legalRef : ""
  );
  const [hint, setHint] = useState(
    editData && editData.hasOwnProperty("hint") ? editData.hint : ""
  );
  const [content, setContent] = useState(
    editData && editData.hasOwnProperty("content") ? editData.content : ""
  );

  const [riskManagement, setRiskManagement] = useState(false);
  const [riskNotification, setRiskNotification] = useState(false);
  const [dateValidationValue, setDateValidationValue] = useState(
    editData ? editData.dateValidation : null
  );
  const [isFullWidth, setIsFullWidth] = useState(
    getDynamicFormSelectValue(editData, "isFullWidth")
  );
  const [addedInSupportPlan, setAddedInSupportPlan] = useState(
    getDynamicFormSelectValue(editData, "isSupportPlan")
  );
  const [required, setRequired] = useState(
    getDynamicFormSelectValue(editData, "required")
  );
  const [fieldType, setFieldType] = useState(
    editData ? SelectTypeOptions.find((x) => x.value === editData.type) : null
  );
  const [bodyModal, setBodyModal] = useState(
    getDynamicFormSelectValue(editData, "isBodyModal")
  );

  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    defaultValues: editData,
  });
  const watchFormData = watch();

  useEffect(() => {
    if (
      (watchFormData.type === "radio" || watchFormData.type === "select") &&
      watchFormData.numberOfOptions &&
      parseInt(watchFormData.numberOfOptions, 10) > 0
    ) {
      if (watchFormData.type === "radio") {
        setRiskManagement(true);
      } else {
        setRiskManagement(false);
      }
      setRiskNotification(true);
    } else {
      setRiskNotification(false);
      setRiskManagement(false);
    }
  }, [watchFormData]);

  useEffect(() => {
    if (watchFormData.type === "date") {
      setValue("dateValidation", dateValidationValue);
    }
    setValue(
      "isFullWidth",
      isFullWidth ? (isFullWidth.value === "yes" ? true : false) : false
    );
    setValue(
      "isSupportPlan",
      addedInSupportPlan
        ? addedInSupportPlan.value === "yes"
          ? true
          : false
        : false
    );
    setValue(
      "required",
      required ? (required.value === "yes" ? true : false) : false
    );
    setValue(
      "isBodyModal",
      bodyModal ? (bodyModal.value === "yes" ? true : false) : false
    );
  }, [
    dateValidationValue,
    isFullWidth,
    addedInSupportPlan,
    required,
    bodyModal,
  ]);

  useEffect(() => {
    if (watchFormData?.dependent_field_name) {
      // console.log("here");
      setValue("dependent_field_value", null);
    }
  }, [watchFormData?.dependent_field_name]);

  // console.log("watchFormData", watchFormData);

  useEffect(() => {
    setValue("type", fieldType ? fieldType.value : null);
    if (
      fieldType &&
      (fieldType.value === "select" || fieldType.value === "radio")
    ) {
      setViewOptions(true);
      setViewTableFields(false);
    } else if (fieldType && fieldType.value === "table") {
      setViewTableFields(true);
      setViewOptions(false);
    } else {
      setViewOptions(false);
      setViewTableFields(false);
    }
  }, [fieldType]);

  const { fields, append, remove } = useFieldArray({
    name: "options",
    control,
  });

  const closeModal = () => {
    reset();
    closeModalHandler();
  };

  const columnOptionChangeHandler = (e, i) => {
    const data = _.cloneDeep(tableData);
    if (e.target.value === "") {
      data[i].totalOptions = 0;
      data[i].options = [];
      setTableData(data);
    } else {
      data[i].totalOptions = 0;
      data[i].options = [];
      data[i].totalOptions = parseInt(e.target.value);
      for (let j = 0; j < data[i].totalOptions; j++) {
        const obj = {
          lable: "",
          type: "text",
        };
        data[i].options.push(obj);
      }
      setTableData(data);
    }
  };

  const columnsTypeChangeHandler = (e, i) => {
    const data = _.cloneDeep(tableData);
    if (e.target.value === "select") {
      data[i].viewOptions = true;
      setTableData(data);
    } else {
      data[i].viewOptions = false;
      setTableData(data);
    }
  };

  const columnsChangeHandler = (e) => {
    let columns = parseInt(e.target.value);
    let data = _.cloneDeep(tableData);
    if (e.target.value === "") {
      data = [];
      setTableData(data);
    } else {
      data = [];
      setTableData(data);
      for (let i = 0; i < columns; i++) {
        const obj = {
          type: "",
          viewOptions: false,
          totalOptions: 0,
          options: [],
        };
        data.push(obj);
      }
      setTableData(data);
    }
  };

  const dataSubmissionFormatHandler = (data) => {
    if (template && template.length > 0) {
      const valid = template
        .map((sec) => {
          return sec?.input_fields.some(
            (field) => field.name === "participant_name"
          );
        })
        .some((item) => item === true);
      if (valid.length > 1) {
        const val=data?.lable?.trim();

        data.name = val
          .replace(/[^a-zA-Z ]/g, "")
          .toLowerCase()
          .split(" ")
          .join("_");
        if (data.name === "participant_name") {
          setParticipantValidation(true);
          return false;
        }
      }
    }
    if (data.lable) {
      const val=data?.lable?.trim();
     
      data.name = val
        .replace(/[^a-zA-Z ]/g, "")
        .toLowerCase()
        .split(" ")
        .join("_");
    } else {
      data.name = "";
    }
    setParticipantValidation(false);
    if (data.type === "table") {
      data.columns = data.columns
        .slice(0, parseInt(data.numberOfColumns))
        .map((item) => {
          item.name = item.lable
            .replace(/[^a-zA-Z ]/g, "")
            .toLowerCase()
            .split(" ")
            .join("_");
          return item;
        });
      data.rows = [];
      data.rows.push(data.columns);
      data?.columns.map((item) => {
        if (item.type === "select") {
          item.options = item.options.slice(0, parseInt(item.totalOptions));
          return item;
        } else {
          return item;
        }
      });
    }
    setTableData([]);
    setViewOptions(false);
    setViewTableFields(false);
    reset();
    closeModalHandler();
    return data;
  };

  const editDataFormatHandler = () => {
    if (editData?.type === "select") {
      setTableData([]);
      setViewTableFields(false);
      setViewOptions(editData?.type === "select");
      editData.numberOfOptions = editData.options.length;
    }
    if (editData?.type === "table") {
      setViewOptions(false);
      setViewTableFields(true);
      const columns = editData?.columns.map((item) => {
        if (item?.type === "select") {
          item.viewOptions = true;
          return item;
        } else {
          item.viewOptions = false;
          item.totalOptions = "";
          item.options = [];
          return item;
        }
      });
      setTableData(columns);
    }
    reset(editData);
  };

  // const numberOfOptions = watch("numberOfOptions");

  const optionsChangeHandler = (e) => {
    const newVal = parseInt(e || 0);
    const oldVal = fields.length;
    if (newVal > oldVal) {
      for (let i = oldVal; i < newVal; i++) {
        append({ lable: "", type: "" });
      }
    } else {
      for (let i = oldVal; i > newVal; i--) {
        remove(i - 1);
      }
    }
  };
  useEffect(() => {
    editDataFormatHandler();
  }, [editData?.type, editData]);

  const onSubmit = (data) => {
    const payload = {
      ...data,
      legalRef: legalRef,
      content: content,
      hint: hint,
      name:
        data.type === "content" ? "content" : convertLabelIntoName(data.lable),
      is_dependent_field: parseBooleanVal(data?.is_dependent_field),
    };

    if (
      formCategoryId &&
      formCategoryId === PARENT_FORM_CATEGORY_SLUG.INJURY_MANAGEMENT.ID
    ) {
      Object.assign(payload, {
        is_reportable_incident: parseBooleanVal(data?.is_reportable_incident),
      });
    }
    const formattedData = dataSubmissionFormatHandler(payload);
    if (formattedData) {
      const obj = formattedData;
      if (obj.lockFiels) delete obj.lockFiels;
      // console.log("formatted Data", formattedData);
      dataSubmitHandler({ ...obj });
    }
  };

  const modalFooter = () => {
    return (
      <>
        <CustomButton
          variant='danger'
          label='Close'
          clickHandler={closeModal}
        />
        <CustomButton
          label='Save Changes'
          clickHandler={() => {
            const buttonRef = document.getElementById(
              `${title.replace(" ", "_")}Button`
            );
            if (buttonRef) {
              buttonRef.click();
            }
          }}
        />
      </>
    );
  };

  const isOnlyContentField = () => watchFormData?.type === "content";

  const sectionAndSubSectionFields = (val) => {
    if (template && template.length > 0 && !isNaN(sectionIndex)) {
      const data = template[sectionIndex];
      if (data && data?.input_fields?.length > 0) {
        const dataTypes = ["radio", "checkbox", "select"];
        let arrayData = data.input_fields
          .filter((y) => dataTypes.includes(y.type))
          .map((x) => ({
            lable: x.lable,
            name: x.name,
            options: x?.options ?? [
              { label: "yes", type: null },
              { label: "no", type: null },
            ],
          }));
        const subSectionFilterData = data.input_fields.filter(
          (y) => y.type === "sub_section"
        );
        let subSectionData = [];
        subSectionFilterData.forEach((u) =>
          subSectionData.push(...u.sub_fields)
        );
        subSectionData = subSectionData
          .filter((z) => dataTypes.includes(z.type))
          .map((x) => ({
            lable: x.lable,
            name: x.name,
            options: x?.options ?? [
              { label: "yes", type: null },
              { label: "no", type: null },
            ],
          }));
        const allSectionAndSubSectionData = [...arrayData, ...subSectionData];
        if (val === "name") {
          return allSectionAndSubSectionData.map((subField) => ({
            label: subField.lable,
            value: subField.name,
          }));
        } else if (val === "options" && watchFormData?.dependent_field_name) {
          const index = allSectionAndSubSectionData.findIndex(
            (item) => item.name === watchFormData?.dependent_field_name
          );
          if (index !== -1) {
            return allSectionAndSubSectionData[index].options.map((opt) => ({
              label: opt?.lable ?? opt?.label,
              value: opt?.lable ?? opt?.label,
            }));
          }
        }
        return allSectionAndSubSectionData;
      }
    }
    return [];
  };

  return (
    <ModalSkeleton
      isOpen={showModal}
      title={title}
      closeModal={closeModalHandler}
      modalFooter={modalFooter()}
      cssClass='w-[50%]'
    >
      <div className='flex flex-col'>
        <p className='text-sm text-gray-500 mb-3'>
          Please enter following details to {` ${title.toLowerCase()}`}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='space-y-4'>
            {!isOnlyContentField() && (
              <div className='flex flex-col did-floating-label-content'>
                <label htmlFor='' className='did-floating-label'>
                  Label
                </label>
                <input
                  {...register("lable", {
                    required: true,
                  })}
                  name='lable'
                  type='text'
                  className='did-floating-input'
                />
                {errors?.lable?.type === "required" && (
                  <span className='text-sm text-red-500'>
                    This field is required!
                  </span>
                )}
              </div>
            )}
            <div className='flex flex-col did-floating-label-content'>
              <div className='hidden'>
                <select
                  {...register("type", {
                    required: true,
                    onChange: (e) => {
                      if (
                        e.target.value === "select" ||
                        e.target.value === "radio"
                      ) {
                        setViewOptions(true);
                        setViewTableFields(false);
                      } else if (e.target.value === "table") {
                        setViewTableFields(true);
                        setViewOptions(false);
                      } else {
                        setViewOptions(false);
                        setViewTableFields(false);
                      }
                    },
                  })}
                  name='type'
                  id=''
                  className='did-floating-input'
                />
              </div>
              <label htmlFor='' className='did-floating-label'>
                Type
              </label>
              <SelectOption
                cssClass='h-1/2 z-10'
                isSearch
                selectedOption={fieldType}
                handleChange={setFieldType}
                data={SelectTypeOptions}
              />
              {errors?.type?.type === "required" && (
                <span className='text-sm text-red-500'>
                  This field is required!
                </span>
              )}
            </div>
            {!isOnlyContentField() ? (
              <>
                {(viewOptions ||
                  watchFormData.type === "radio" ||
                  watchFormData.type === "select") && (
                  <>
                    <div className='flex flex-col did-floating-label-content'>
                      <label htmlFor='' className='did-floating-label'>
                        Number of Options
                      </label>
                      <input
                        {...register("numberOfOptions", {
                          required: true,
                          min: 2,
                          // max: 6,
                          onChange: (e) => {
                            optionsChangeHandler(e.target.value);
                          },
                        })}
                        name='numberOfOptions'
                        type='number'
                        className='did-floating-input'
                      />
                      {errors?.numberOfOptions?.type === "required" && (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      )}
                      {errors?.numberOfOptions?.type === "min" && (
                        <span className='text-sm text-red-500'>
                          Options must be 2 or more
                        </span>
                      )}
                      {errors?.numberOfOptions?.type === "max" && (
                        <span className='text-sm text-red-500'>
                          Options must not be more than 6
                        </span>
                      )}
                    </div>
                    {fields.map((item, i) => {
                      return (
                        <>
                          <div
                            key={i}
                            className='flex flex-col did-floating-label-content'
                          >
                            <label htmlFor='' className='did-floating-label'>
                              Option {i + 1}
                            </label>
                            <input
                              {...register(`options.${i}.lable`, {
                                required: true,
                              })}
                              name={`options[${i}].lable`}
                              type='text'
                              placeholder={`Enter Option ${i + 1}`}
                              className='did-floating-input'
                            />
                            {errors?.options?.[i]?.lable?.type ===
                              "required" && (
                              <span className='text-sm text-red-500'>
                                This field is required!
                              </span>
                            )}
                          </div>
                        </>
                      );
                    })}
                  </>
                )}
                {viewTableFields && (
                  <>
                    <div className='flex flex-col did-floating-label-content'>
                      <label htmlFor='' className='did-floating-label'>
                        Number of Columns
                      </label>
                      <input
                        {...register("numberOfColumns", {
                          required: true,
                          min: 2,
                          onChange: (e) => {
                            columnsChangeHandler(e);
                          },
                        })}
                        name='numberOfColumns'
                        type='number'
                        className='did-floating-input'
                      />
                      {errors?.numberOfColumns?.type === "required" && (
                        <span className='text-sm text-red-500'>
                          This field is required!
                        </span>
                      )}
                      {errors?.numberOfColumns?.type === "min" && (
                        <span className='text-sm text-red-500'>
                          Columns must be 2 or more
                        </span>
                      )}
                      {/* {errors?.numberOfColumns?.type === "max" && (
                  <span className="text-sm text-red-500">
                    Columns must not be more than 6
                  </span>
                )} */}
                    </div>
                    {tableData.map((item, i) => {
                      return (
                        <>
                          <div
                            key={i}
                            className='flex flex-col did-floating-label-content'
                          >
                            <label htmlFor='' className='did-floating-label'>
                              Column {i + 1}
                            </label>
                            <input
                              {...register(`columns.${i}.lable`, {
                                required: true,
                              })}
                              name={`columns[${i}].lable`}
                              type='text'
                              placeholder={`Enter Column ${i + 1}`}
                              className='did-floating-input'
                            />
                            {errors?.columns?.[i]?.lable?.type ===
                              "required" && (
                              <span className='text-sm text-red-500'>
                                This field is required!
                              </span>
                            )}
                          </div>
                          <div className='flex flex-col did-floating-label-content'>
                            <label htmlFor='' className='did-floating-label'>
                              Type
                            </label>
                            <select
                              {...register(`columns[${i}].type`, {
                                required: true,
                                onChange: (e) => {
                                  columnsTypeChangeHandler(e, i);
                                },
                              })}
                              id=''
                              className='did-floating-input'
                            >
                              <option value=''>--Select Column Type--</option>
                              <option value='text'>Text</option>
                              <option value='number'>Number</option>
                              <option value='checkbox'>Checkbox</option>
                              <option value='select'>Select</option>
                            </select>
                            {errors?.columns?.[i]?.type?.type ===
                              "required" && (
                              <span className='text-sm text-red-500'>
                                This field is required!
                              </span>
                            )}
                          </div>
                          {tableData[i]?.viewOptions && (
                            <>
                              <div className='flex flex-col did-floating-label-content'>
                                <label
                                  htmlFor=''
                                  className='did-floating-label'
                                >
                                  Number of Options
                                </label>
                                <input
                                  {...register(`columns.${i}.totalOptions`, {
                                    required: true,
                                    min: 2,
                                    // max: 6,
                                    onChange: (e) => {
                                      columnOptionChangeHandler(e, i);
                                    },
                                  })}
                                  name={`columns[${i}].totalOptions`}
                                  type='number'
                                  className='did-floating-input'
                                />
                                {errors?.columns?.[i]?.totalOptions?.type ===
                                  "required" && (
                                  <span className='text-sm text-red-500'>
                                    This field is required!
                                  </span>
                                )}
                                {errors?.columns?.[i]?.totalOptions?.type ===
                                  "min" && (
                                  <span className='text-sm text-red-500'>
                                    Options must be 2 or more
                                  </span>
                                )}
                                {/* {errors?.columns?.[i]?.totalOptions?.type ===
                            "max" && (
                            <span className="text-sm text-red-500">
                              Options must not be more than 6
                            </span>
                          )} */}
                              </div>
                              {tableData[i].options.map((item, oi) => {
                                return (
                                  <>
                                    <div
                                      key={oi}
                                      className='flex flex-col did-floating-label-content'
                                    >
                                      <label
                                        htmlFor=''
                                        className='did-floating-label'
                                      >
                                        Option {oi + 1}
                                      </label>
                                      <input
                                        {...register(
                                          `columns.${i}.options.${oi}.lable`,
                                          {
                                            required: true,
                                          }
                                        )}
                                        name={`columns.[${i}].options[${oi}].lable`}
                                        type='text'
                                        className='did-floating-input'
                                      />
                                      {errors?.columns?.[i]?.options?.[oi]
                                        ?.lable?.type === "required" && (
                                        <span className='text-sm text-red-500'>
                                          This field is required!
                                        </span>
                                      )}
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          )}
                        </>
                      );
                    })}
                  </>
                )}
                {watchFormData.type === "date" && (
                  <div className='flex flex-col did-floating-label-content'>
                    <div className='hidden'>
                      <select
                        {...register("dateValidation", {
                          name: "dateValidation",
                          required: true,
                        })}
                        id=''
                        className='hidden did-floating-input'
                      />
                    </div>
                    <label htmlFor='' className='did-floating-label'>
                      Date Validation
                    </label>
                    <SelectOption
                      selectedOption={dateValidationValue}
                      handleChange={setDateValidationValue}
                      isSearch={false}
                      isMulti
                      data={DateValidaionOptions}
                      errorMessage={
                        errors?.dateValidation?.type === "required"
                          ? "This field is required!"
                          : null
                      }
                    />
                  </div>
                )}
                <div className='flex flex-col did-floating-label-content'>
                  <div className='hidden'>
                    <select
                      {...register("required", {
                        // required: editData ? editData.required : true,
                        value: editData ? editData.required : true,
                      })}
                      name='required'
                      id=''
                      className='did-floating-input'
                    />
                  </div>
                  <label htmlFor='' className='did-floating-label'>
                    Required
                  </label>
                  <SelectOption
                    isSearch={false}
                    selectedOption={required}
                    handleChange={setRequired}
                    data={portalOption}
                  />
                </div>
                <div className='flex flex-col did-floating-label-content'>
                  <div className='hidden'>
                    <select
                      {...register("isFullWidth", {
                        value: false,
                      })}
                      name='isFullWidth'
                      id=''
                      className='hidden did-floating-input'
                      defaultValue={false}
                    />
                  </div>
                  <label htmlFor='' className='did-floating-label'>
                    Full Width Column
                  </label>
                  <SelectOption
                    isSearch={false}
                    selectedOption={isFullWidth}
                    handleChange={setIsFullWidth}
                    data={portalOption}
                  />
                </div>
                <div className='flex flex-col did-floating-label-content'>
                  <div className='hidden'>
                    <select
                      {...register("isSupportPlan", {
                        value: false,
                      })}
                      name='isSupportPlan'
                      id=''
                      className='hidden did-floating-input'
                      defaultValue={false}
                    />
                  </div>
                  <label htmlFor='' className='did-floating-label'>
                    Added In Support Plan
                  </label>
                  <SelectOption
                    isSearch={false}
                    selectedOption={addedInSupportPlan}
                    handleChange={setAddedInSupportPlan}
                    data={portalOption}
                  />
                </div>
                {watchFormData.type === "text" && (
                  <div className='flex flex-col did-floating-label-content'>
                    <div className='hidden'>
                      <select
                        {...register("isBodyModal", {
                          name: "isBodyModal",
                          value: false,
                        })}
                        id=''
                        className='did-floating-input'
                        defaultValue='false'
                      />
                    </div>
                    <label htmlFor='' className='did-floating-label'>
                      Body Modal
                    </label>
                    <SelectOption
                      isSearch={false}
                      selectedOption={bodyModal}
                      handleChange={setBodyModal}
                      data={portalOption}
                    />
                  </div>
                )}

                {/* //Reportable Incident Start*/}
                {formCategoryId &&
                  formCategoryId ===
                    PARENT_FORM_CATEGORY_SLUG.INJURY_MANAGEMENT.ID && (
                    <div
                      className={`border-t border-b pt-2 pb-4 flex flex-col did-floating-label-content`}
                    >
                      <label htmlFor='' className='did-floating-label'>
                        Reportable Incident
                      </label>
                      <select
                        {...register("is_reportable_incident", {
                          required: true,
                        })}
                        name='is_reportable_incident'
                        id=''
                        className='did-floating-input'
                        defaultValue={false}
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  )}
                {/* //Reportable Incident End */}

                {/* Risk Management Start*/}
                {riskManagement && (
                  <div
                    className={`border-t pt-2 flex flex-col did-floating-label-content`}
                  >
                    <label htmlFor='' className='did-floating-label'>
                      Risk Management
                    </label>
                    <select
                      {...register("isRisk", {
                        required: true,
                      })}
                      name='isRisk'
                      id=''
                      className='did-floating-input'
                      defaultValue='false'
                    >
                      <option value='true'>Yes</option>
                      <option value='false'>No</option>
                    </select>
                  </div>
                )}
                {watchFormData.isRisk === "true" && riskManagement && (
                  <div
                    className='flex flex-col did-floating-label-content'
                    style={{ margin: "0px !important" }}
                  >
                    <label htmlFor='' className='did-floating-label'>
                      Risk positive
                    </label>
                    <select
                      {...register("riskPositive", {
                        required: true,
                      })}
                      name='riskPositive'
                      id=''
                      className='did-floating-input'
                      defaultValue=''
                    >
                      {/* <option>-- Select Risk Option --</option> */}
                      {watchFormData.options &&
                        watchFormData.options.length > 0 &&
                        watchFormData.options.map((o, index) => (
                          <option value={index}>{o.lable}</option>
                        ))}
                    </select>
                  </div>
                )}
                {/* Risk Management end*/}

                {/* Risk Notification Start*/}
                {riskNotification && (
                  <div
                    className={`border-t pt-2 flex flex-col did-floating-label-content ${
                      !(
                        watchFormData.isRiskNotification === "true" &&
                        riskNotification
                      ) && "border-b pb-4"
                    }`}
                  >
                    <label htmlFor='' className='did-floating-label'>
                      Risk Notification
                    </label>
                    <select
                      {...register("isRiskNotification", {
                        required: true,
                      })}
                      name='isRiskNotification'
                      id=''
                      className='did-floating-input'
                      defaultValue='false'
                    >
                      <option value='true'>Yes</option>
                      <option value='false'>No</option>
                    </select>
                  </div>
                )}
                {watchFormData.isRiskNotification === "true" &&
                  riskNotification && (
                    <div
                      className='border-b flex flex-col did-floating-label-content pb-4'
                      style={{ margin: "0px !important" }}
                    >
                      <label htmlFor='' className='did-floating-label'>
                        Risk Notification Option
                      </label>
                      <select
                        {...register("riskNotificationEnable", {
                          required: true,
                        })}
                        name='riskNotificationEnable'
                        id=''
                        className='did-floating-input'
                        defaultValue=''
                      >
                        {/* <option>-- Select Risk Option --</option> */}
                        {watchFormData.options &&
                          watchFormData.options.length > 0 &&
                          watchFormData.options.map((o, index) => (
                            <option value={index}>{o.lable}</option>
                          ))}
                      </select>
                    </div>
                  )}
                {/* Risk Notification End  */}

                <div className='pb-2 flex flex-col did-floating-label-content'>
                  <label htmlFor='' className='did-floating-label'>
                    Is Dependent On Other Field?
                  </label>
                  <SelectOption
                    isRequired={false}
                    register={register}
                    setValue={setValue}
                    clearErrors={clearErrors}
                    name='is_dependent_field'
                    cssClass='z-30'
                    data={portalOption}
                    selectedOption={
                      watchFormData?.is_dependent_field
                        ? portalOption.find(
                            (x) => x.value === watchFormData?.is_dependent_field
                          )
                        : portalOption[1]
                    }
                  />
                </div>

                {watchFormData?.is_dependent_field &&
                  parseBooleanVal(watchFormData.is_dependent_field) ===
                    true && (
                    <div
                      className='grid grid-cols-2 gap-x-2'
                      style={{ marginTop: "5px" }}
                    >
                      <div className='col-span-1 flex flex-col did-floating-label-content'>
                        <label htmlFor='' className='did-floating-label'>
                          Dependent Fields
                        </label>
                        <SelectOption
                          isRequired={true}
                          register={register}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          name='dependent_field_name'
                          cssClass='z-30'
                          data={sectionAndSubSectionFields("name")}
                          selectedOption={
                            watchFormData?.dependent_field_name
                              ? {
                                  label: watchFormData?.dependent_field_name,
                                  value: watchFormData?.dependent_field_name,
                                }
                              : null
                          }
                          errorMessage={
                            errors?.dependent_field_name?.type === "required"
                              ? "This field is required!"
                              : null
                          }
                        />
                      </div>
                      <div className='col-span-1 flex flex-col did-floating-label-content'>
                        <label htmlFor='' className='did-floating-label'>
                          Dependent Field Value
                        </label>
                        <SelectOption
                          isRequired={true}
                          isDisabled={
                            watchFormData?.dependent_field_name ? false : true
                          }
                          register={register}
                          setValue={setValue}
                          clearErrors={clearErrors}
                          name='dependent_field_value'
                          cssClass='z-30'
                          data={sectionAndSubSectionFields("options")}
                          selectedOption={
                            watchFormData?.dependent_field_value
                              ? {
                                  label: watchFormData?.dependent_field_value,
                                  value: watchFormData?.dependent_field_value,
                                }
                              : null
                          }
                          errorMessage={
                            errors?.dependent_field_value?.type === "required"
                              ? "This field is required!"
                              : null
                          }
                        />
                      </div>
                    </div>
                  )}

                <div className='pt-4 border-t flex flex-col did-floating-label-content'>
                  <label htmlFor='' className='did-floating-label'>
                    Hint
                  </label>
                  <TextEditor text={hint} setText={setHint} />
                </div>
                <div className='flex flex-col did-floating-label-content'>
                  <label htmlFor='' className='did-floating-label'>
                    Legal Reference
                  </label>
                  <TextEditor text={legalRef} setText={setLegalRef} />
                </div>
              </>
            ) : (
              <div className='flex flex-col did-floating-label-content'>
                <label htmlFor='' className='did-floating-label'>
                  Content
                </label>
                <TextEditor text={content} setText={setContent} />
              </div>
            )}
          </div>

          {participantVlaidation && (
            <span className='text-sm text-red-500'>
              Each Form must not contain multiple Participant Name fields
            </span>
          )}

          <button
            className='hidden sr-only'
            type='submit'
            id={`${title.replace(" ", "_")}Button`}
          />
        </form>
      </div>
    </ModalSkeleton>
  );
}

export default InputCreationForm;
