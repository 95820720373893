import React, { useContext, useEffect, useState } from "react";
import { LoginContext } from "helpers/LoginContext";
import CustomButton from "components/common/Button/CustomButton";
import CircularLoader from "components/common/CircularLoader/CircularLoader";
import SideBarNew from "components/common/SideBar/SideBarNew";
import Header from "components/common/Header/Header";
import Breadcrumb from "components/common/Breadcrumb";
import {
  SETTINGS_BREADCRUMB_PAGES,
  SP_SETTING_BREADCRUMB,
} from "Constants/BreadcrumbConstant";
import { Controller, useForm } from "react-hook-form";

import { Tooltip } from "react-tooltip";
import SelectOption from "components/common/Select/Select";
import {toast} from "react-toastify";
import { apiPostRequest } from "helpers/Requests";
import {URL_SP_INCIDENT_REPORTING_SETTING} from "../../../../store/services/URL";
const IncidentReporting = ({ fetchFormTemplate, spFormTemplatesList }) => {
  const { sideBarPadding } = useContext(LoginContext);
  const [data, setData] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);

  const [selectedFormId, setSelectedFormId] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (spFormTemplatesList) {
      const preparedData = spFormTemplatesList?.form_templates?.data?.map(
          (item) => ({ label: item?.title, value: item?.id })
      );
      setData(preparedData);
    }

  }, [spFormTemplatesList]);

  useEffect(() => {
    if (selectedFormId && data.length > 0) {
      const matchedItem = data.findLastIndex((item) => item.value === spFormTemplatesList?.incident_reporting_settings?.incident_form_id);
      if (matchedItem !== -1) {
        setSelectedForm(data[matchedItem]);
      }
    }
  }, [selectedFormId, data]);

  useEffect(() => {
    if (!spFormTemplatesList) return;
    const formIdFromPayload = spFormTemplatesList?.incident_reporting_settings?.incident_form_id;
    if (formIdFromPayload) {
      setSelectedFormId(formIdFromPayload);
    }
  }, [spFormTemplatesList]);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
    control,
    clearErrors,
  } = useForm();

  const watchFormData = watch();
  useEffect(() => {
    fetchFormTemplate({
      bodyFilter: { form_category: [7] },
      filters: {
        page: 1,
        per_page: 300,
        search: null,
        status: "publish",
      },
      role: "crm",
    });
  }, []);

  const sendSPForm = async (selected) => {
    const endpoint = URL_SP_INCIDENT_REPORTING_SETTING;

    const payload = {
      incident_form_id: selected.value,
      form_name: selected.label,
    };
    try {
      const response = await apiPostRequest(endpoint, payload);
      return response;
    } catch (error) {
      toast.error(error ? error.message : "something went wrong!");
    }
  };
  const onSubmit = async () => {
    if (!selectedForm) {
      toast.error("Please select a form before saving.");
      return;
    }
    setLoading(true);
    try {
      await sendSPForm(selectedForm);
      toast.success("Settings updated successfully!");
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      <div className='min-h-screen bg-gray-100'>
        <SideBarNew />
        <div
          className={`${
            sideBarPadding
              ? "lg:pl-[4rem] duration-300"
              : "lg:pl-64 duration-300"
          }  flex flex-col`}
        >
          <Header
            title='Incident Reporting Settings'
            breadcrumb={
              <Breadcrumb
                pages={SP_SETTING_BREADCRUMB(
                  SETTINGS_BREADCRUMB_PAGES.INCIDENT_REPORTING_SETTING
                )}
              />
            }
          />
          <section className='container mx-auto sm:px-6 lg:px-8 mt-3'>
            <div className='mt-5 md:mt-0 md:col-span-2'>
              <div className='max-w-7xl mx-auto'>
                <div className='shadow sm:rounded-md sm:overflow-hidden p-8 mt-4 bg-white shadow-md'>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className=''>
                      <h5 className='px-2 pb-2 font-bold'>
                        Incident Reporting Settings
                      </h5>
                      <div className='grid grid-cols-1 gap-x-2 gap-y-6 xs:grid-cols-2 mt-4'>
                        <div className='col-span-1 px-2'>
                          <div className='flex justify-between gap-x-3'>
                            <label className='text-gray-700 lg:text-sm font-medium mb-1 sm:text-xs'>
                            Incident report form available in the Mobile App
                            </label>

                            <SelectOption
                              data={data}
                              cssClass='w-1/2'
                              selectedOption={selectedForm}
                              handleChange={setSelectedForm}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      id='incidentReportingUpdate'
                      type='submit'
                      className='hidden sr-only'
                    />
                  </form>
                  <div className='mt-6 flex justify-end'>
                    <CustomButton
                      showLoading={loading}
                      isDisabled={loading}
                      variant='primary'
                      clickHandler={() => {
                        const buttonRef = document.getElementById(
                          "incidentReportingUpdate"
                        );
                        if (buttonRef) {
                          buttonRef.click();
                        }
                      }}
                      label='Save Settings'
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default IncidentReporting;
