import React from "react";

import CheckBox from "components/common/checkBox/checkbox";
import moment from "moment";
import AvatarGroup from "components/common/Avatar/AvatarGroup";
import { BsPlusLg } from "react-icons/bs";
import { BiMinus } from "react-icons/bi";
import TableHeader from "components/common/Table/TableHeader";
import { capitalizeWords, formatAmount } from "Utils/utils";

const RosterInvoiceTable = (props) => {
  const { item, checkboxes, handleCheckboxChange } = props;
  const [expandedRows, setExpandedRows] = React.useState([]);

  const handleRowClick = (index) => {
    const newExpandedRows = [...expandedRows];
    if (expandedRows.includes(index)) {

      const indexToRemove = newExpandedRows.indexOf(index);
      newExpandedRows.splice(indexToRemove, 1);
    } else {
 
      newExpandedRows.push(index);
    }

    setExpandedRows(newExpandedRows);
  };

  return (
    <>
      <tr className='text-center even:bg-white odd:bg-gray-50'>
        <td
          className='flex justify-center items-center h-[50px]  cursor-pointer'
          onClick={() => handleRowClick(item.uuid)}
        >
          <div className='flex align-center cursor-pointer '>
            {!expandedRows.includes(item.uuid) ? (
              <BiMinus className='mt-1 text-gray-600' />
            ) : (
              <BsPlusLg className='h-3 w-3 mt-1 text-gray-600' />
            )}
          </div>
        </td>
        <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500'>
          <div className='text-gray-500'>
            <CheckBox
              handleChange={handleCheckboxChange}
              checked={checkboxes.indexOf(item.uuid) !== -1}
            />
          </div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-start'>
          <div className='text-gray-500'>
            {item?.participants &&
            item?.participants?.length > 0 &&
            item?.participants?.length === 1 ? (
              `${capitalizeWords(item?.participants[0]?.first_name)} ${capitalizeWords(item?.participants[0]?.last_name)}`
            ) : (
              <AvatarGroup
                arrayData={item?.participants}
                cssClass='mt-0'
                maxLength={10}
              />
            )}
          </div>
        </td>
        <td className=' px-3 py-4 text-sm text-gray-500 text-start capitalize'>
          <div className='text-gray-500 capitalize'>
            {item?.employee_profile?.first_name}{" "}
            {item?.employee_profile?.last_name}
          </div>
        </td>

      
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
          <div className='text-gray-500'>
            {moment(item?.time_sheet_date).format("DD-MM-yyyy")}
          </div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
          <div className='text-gray-500'>{item?.invoice_service_type}</div>
        </td>

        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
          <div className='text-gray-500'>Ready For Extraction</div>
        </td>
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
          <div className='text-gray-500'>{item?.timesheet_length}</div>
        </td>
        {/* <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
          <div className='text-gray-500'>${item?.invoice_price}</div>
        </td> */}
        <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center'>
          <div className='text-gray-500'>
          {item?.total_amount ? `$${formatAmount(parseFloat(item?.total_amount).toFixed(2))}` : "N/A"}
          </div>
        </td>
      </tr>

      {!expandedRows.includes(item.uuid) && (
        <tr className='text-center '>
          <td colSpan={10} className='px-3 py-4'>
            <table className='min-w-full divide-y divide-gray-300 border'>
              <thead>
                <tr>
                  <TableHeader
                    isSort={false}
                    label={"Start Date"}
                    cssClass='text-center text-xs'
                  />
                  <TableHeader
                    isSort={false}
                    label={"End Date"}
                    cssClass='text-center text-xs'
                  />
                  <TableHeader
                    isSort={false}
                    label={"NDIS Service Item"}
                    cssClass='text-center text-xs'
                  />
                  {/* <TableHeader isSort={false} label={"Service Item Number"} cssClass="text-center text-xs" /> */}
                  <TableHeader
                    isSort={false}
                    label={"Hours"}
                    cssClass='text-center text-xs'
                  />
                  <TableHeader
                    isSort={false}
                    label={"Hourly Rate"}
                    cssClass='text-center text-xs'
                  />
                  <TableHeader
                    isSort={false}
                    label={"Total Value"}
                    cssClass='text-center text-xs'
                  />
                </tr>
              </thead>
              <tbody className='divide-y divide-gray-200 bg-whit'>
                {item?.invoice_timesheets &&
                item?.invoice_timesheets?.length > 0 ? (
                  item?.invoice_timesheets?.map((el, index) => (
                    <tr key={index}>
                      <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                          {el?.start_time
                            ? moment(el?.start_time).format("DD-MM-YYYY HH:mm")
                            : "N/A"}
                        </div>
                      </td>
                      <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                          {el?.end_time
                            ? moment(el?.end_time).format("DD-MM-YYYY HH:mm")
                            : "N/A"}
                        </div>
                      </td>

                      <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                          {el?.ndis_support_item_number} -{" "}
                          {el?.ndis_support_item_name}
                        </div>
                      </td>
                      <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                          {el?.duration ? el?.duration : "N/A"}
                        </div>
                      </td>
                      <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                          {el?.invoice_price ? `$${el?.invoice_price}` : "N/A"}
                        </div>
                      </td>
                      <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500 text-xs'>
                        <div className='text-gray-500'>
                          {el?.total_amount ? `$${formatAmount(parseFloat(el?.total_amount).toFixed(2))}` : "N/A"}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={10}
                      className='py-6 text-sm text-center text-red-600'
                    >
                      No Shift Breakdown for this timesheet
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {item?.travel_item && (
                <table className='min-w-full divide-y divide-gray-300 border'>
                      <thead>
                      <tr>
                        <TableHeader
                            isSort={false}
                            label={"Travel"}
                            cssClass='text-center text-xs'
                        />
                        <TableHeader
                            isSort={false}
                            label={"Travel Date"}
                            cssClass='text-center text-xs'
                        />
                        <TableHeader
                            isSort={false}
                            label={"NDIS Service Item"}
                            cssClass='text-center text-xs'
                        />
                        {/* <TableHeader isSort={false} label={"Service Item Number"} cssClass="text-center text-xs" /> */}
                        <TableHeader
                            isSort={false}
                            label={"Travel KM"}
                            cssClass='text-center text-xs'
                        />
                        <TableHeader
                            isSort={false}
                            label={"Travel Rate"}
                            cssClass='text-center text-xs'
                        />
                        <TableHeader
                            isSort={false}
                            label={"Total Value"}
                            cssClass='text-center text-xs'
                        />
                      </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200 bg-whit'>
                      <tr>
                        <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500 text-xs'>
                          <div className='text-gray-500'>

                          </div>
                        </td>
                        <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500 text-xs'>
                          <div className='text-gray-500'>
                            {item?.start_time
                                ? moment(item?.start_time	).format("DD-MM-YYYY HH:mm")
                                : "N/A"}
                          </div>
                        </td>

                        <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500 text-xs'>
                          <div className='text-gray-500'>
                            {item?.travel_item.support_item_number} -{" "}
                            {item?.travel_item.support_item_name}
                          </div>
                        </td>
                        <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500 text-xs'>
                          <div className='text-gray-500'>
                            {item?.km ? item?.km : "N/A"}
                          </div>
                        </td>
                        <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500 text-xs'>
                          <div className='text-gray-500'>
                            {item?.travel_item.price ? `$${item?.travel_item.price}` : "N/A"}
                          </div>
                        </td>
                        <td className='whitespace-nowrap pl-3 py-4 text-sm text-gray-500 text-xs'>
                          <div className='text-gray-500'>
                            ${formatAmount(parseFloat(item?.km * item?.travel_item.price).toFixed(2))}
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
            )}

          </td>
        </tr>
      )}

    </>
  );
};

export default RosterInvoiceTable;
